import { LoginResponse } from "./loginrequest";

export class  SsoRequest {
    UserName?: string;
    SessionId?: string;
    Token: string;
    MySedgwickUrl: string;
    ClientType?: string;
    SourceUrl: string;

    constructor(values: SsoRequest) {
        Object.assign(this, values);
      }
}

export class SsoRedirect {
  Token: string;
  PostUrl: string;
  Status: string;

  constructor(values: SsoRedirect){
    Object.assign(this, values);
  }
}

export class AuthenticateSamlRequest {
  SessionId: string;
  InContract1?: number;
  InContract2?: number;
  InPseudonym?: string;
  DeepLinkToken?: string;
  DigestValue?: string;
  TimeFail?: number;
  SamlResponse?: any;
  UserSource?: string;
  Username: string;
  RedirectedSsso?: boolean;

  constructor(values: AuthenticateSamlRequest){
    Object.assign(this, values);
  }

}

export class AuthenticateSamlTfaRequest {
  Username: string;
  SessionId: string;

  constructor(values: AuthenticateSamlTfaRequest){
    Object.assign(this, values);
  }
}

export class  SsoRegistration {
  registration?: any;
  loginResponse?: LoginResponse;

  constructor(values: SsoRegistration) {
      Object.assign(this, values);
    }
}

export class SsoRegistrationResult{
  sessionId?: string;
  username?: string;
  clientCode?: string;
  forceTfa?: string;
  userEmail?: string;
  textPhone?: string;
  isSuccessful?: boolean;
  ssoRegistration?: boolean;
  mySedgwickUrl?: string;
  token?: string;

  constructor(values: SsoRegistrationResult) {
    Object.assign(this, values);
  }
}
