export interface ValidatedUser {
  userName: string;
  userStatus: UserStatus;
  secretKey: string;
  securityUpdated: string;
  userEmail: string;
  textPhone: string;
}

export class ValidateUserRequest {
  captchaId: string;
  userEnteredCaptchaCode: string;
  userName: string;
  email: string;
  constructor(values: ValidateUserRequest) {
    Object.assign(this, values);
  }
}

export class ValidateEmailRequest {
  captchaId: string;
  userEnteredCaptchaCode: string;
  useremail: string;
  sendEmail: string;
  constructor(values: ValidateEmailRequest) {
    Object.assign(this, values);
  }
}

export class VerificationResponse {
  answerStatus: SecurtyAnswerStatus;
  inValidAnswer: number;
}

export enum UserStatus {

  Valid = "Valid",
  NotFound = "NotFound",
  Locked = "Locked",
  Disabled = "Disabled",
  DeadClient = "DeadClient",
  ClientNotSetupYet = "ClientNotSetupYet",
  BadCaptCha = "BadCaptCha"
}

export enum SecurtyAnswerStatus {
  Failed = "Failed",
  Disabled = "Disabled",
  Success = "Success",
  DisabledFailure = "DisabledFailure"
}
