<div class="unlockAccountContent">
  <div fxLayout="column" fxFlexAlign="center">
    <h1  role="heading" aria-label="Unlock Account">Unlock Account</h1>
  </div>
  <div class="unlockAccount-container">
      <toast
        [message]="unlockToastMessage"
        [type]="toastEnumOptions.Error"
      ></toast>
      <div fxLayout="row" fxLayoutAlign="end center" class="top10" >
        <strong>*Required</strong>
      </div>

    <form role="none" fxLayout="column" [formGroup]="unlockAccountForm" (ngSubmit)="onSubmit()">
      <app-security-questions [username]="username" [resetQuestions]="resetQuestions" formControlName="securityQuestions"></app-security-questions>

      <ul role="none" class="flex-outer">
        <li role="none">
          <p></p>
          <botdetect-captcha captchaStyleName="MeltingHeat"></botdetect-captcha>
        </li>
        <li role="none">
          <label for="captchaId">Enter Displayed Text *</label>
          <mat-form-field [@.disabled]="animationsDisabled">
            <input
              id="captchaId"
              name="captchaId"
              matInput
              required
              type="text"
              formControlName="captcha"
              aria-required="true"
              aria-label="Enter Displayed Text"
            />
            <mat-error class="error-message"
              *ngIf="(unlockAccountForm.get('captcha').errors?.required || unlockAccountForm.get('captcha').dirty) && !unlockAccountForm.get('captcha').valid && showErrorField('captcha')">
              <span style="background:white;padding:5px">{{captchaErrorMessage}}</span>
            </mat-error>
          </mat-form-field>
        </li>
        <!-- <li role="none" *ngIf="showErrorField('captcha')">
          <p></p>
          <span class="error-message"> {{captchaErrorMessage}} </span>
        </li> -->
        <div dir="ltr" class="float-right" fxLayoutGap="20px">
          <button  mat-raised-button type="button" class="cancelButton" (click)="onCancel()" aria-label="Cancel">
            <span class="mat-button-wrapper">
              <mat-icon aria-hidden="true">block</mat-icon> 
              <strong>CANCEL</strong>
            </span>
          </button>
          <button
            mat-raised-button
            color="primary"
            type="submit"
            style="width: 90px"
            aria-label="Save">
            <mat-icon aria-hidden="true">done</mat-icon> 
             SAVE
          </button>
        </div>
      </ul>
    </form>
    <p *ngIf="showErrorToast">
      <toast
        [message]="errorToastMessage"
        [type]="toastEnumOptions.UserError"
      ></toast>
    </p>
    <span tabindex="0" aria-label="If you are unable to see the CAPTCHA image above, please contact Sedgwick Support Services at 8666477610.">
      If you are unable to see the CAPTCHA image(s) above, please contact Sedgwick Support Services at (866) 647-7610.
    </span>
  </div>
</div>
