import { Component, OnInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

declare function doLiveChat(params): any;
declare function closeLiveChat(): any;
declare var $: any;
@Component({
  selector: 'app-support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: ['./support-dialog.component.scss']
})

export class SupportDialogComponent implements OnInit {
  @ViewChild('displayLocation') myDiv: ElementRef;
  doCollapse: boolean;
  termsIcon: string;
  nextAction: string;
  window: any;
  lhnHocButton: any;
  displayLocation: any
  elementByClassForLiveChat: any;
  passVar: any;
  isRepositionSet: boolean;
  isVisible: boolean;
  title: string = "Contact Support";
  constructor(private renderer: Renderer2,
    private elRef: ElementRef, private dialogRef: MatDialog) { }

  ngOnInit(): void {
    this.isVisible = true;
    this.doCollapse = true;
    this.termsIcon = 'arrow_right';

    this.lhnHocButton = (document.getElementById('lhnHocButton') as HTMLFormElement);
    if (this.lhnHocButton !== null) {
      this.renderer.setStyle(this.lhnHocButton, 'display', 'none');
    }

    this.displayLocation = (document.getElementById('displayLocation') as HTMLFormElement).getBoundingClientRect();
    this.passVar = {
      Flag: 'cs',
      top: this.displayLocation.top + this.displayLocation.height,
      left: '${parseFloat(this.displayLocation.left) + 30}em'
    };

    //doLiveChat(this.passVar);//Initialize Live Chat from index.html

    this.isRepositionSet = document.querySelector('#lhnHocButton')?.classList.contains('repositionChat') ?? false;


    if (this.lhnHocButton !== null && this.passVar && this.passVar.Flag == 'cs') {
      this.lhnHocButton.className = 'lhnright lhnslide repostionChat';
      this.renderer.setStyle(this.lhnHocButton, 'top', this.lhnHocButton.top - this.lhnHocButton.height + 150);
      this.renderer.setStyle(this.lhnHocButton, 'left', this.passVar.left);
      this.renderer.setStyle(this.lhnHocButton, 'display', 'block');
    }
    else {
      this.renderer.setStyle(this.lhnHocButton, 'display', 'none');
    }

  }



  ngOnDestroy(): void {
    this.doClose();
  }

  ngAfterViewInit() {
    this.displayLocation = (document.getElementById('displayLocation') as HTMLFormElement);
    this.lhnHocButton = (document.getElementById('lhnHocButton') as HTMLFormElement);
    if (this.lhnHocButton !== null) {
      this.setRepostionChat();

    }
  }

  setRepostionChat(): void {
    setTimeout(() => {
      let repostionChatHtml = document.getElementsByClassName('repostionChat')[0] as HTMLFormElement;
      this.displayLocation = (document.getElementById('displayLocation') as HTMLFormElement).getBoundingClientRect();
      this.lhnHocButton = (document.getElementById('lhnHocButton') as HTMLFormElement);
      if (repostionChatHtml != undefined) {
        if (window.innerWidth < 768) {
          repostionChatHtml.style.left = (this.displayLocation.left - 14) + 'px'.toString();
          repostionChatHtml.style.top = this.displayLocation.top + 'px'.toString();
        }
        else {
          repostionChatHtml.style.left = this.displayLocation.left;
          if (this.nextAction == 'expand') {
            this.lhnHocButton.style.top = (this.displayLocation.top + this.displayLocation.height - 20) + 'px'.toString();
          }
          else {
            this.lhnHocButton.style.top = (this.displayLocation.top + 'px').toString();
          }
        }
      }
    }, 50);
  }

  doClose(): void {

    this.isVisible = false;
    this.lhnHocButton = (document.getElementById('lhnHocButton') as HTMLFormElement);
    this.renderer.setStyle(this.lhnHocButton, 'display', 'none');
    this.dialogRef.closeAll();

  }
  collapseExpand(action: string): void {
    if (action == 'collapse') {
      this.termsIcon = 'arrow_right';
      this.doCollapse = true;
      this.nextAction = 'expand';
    }

    else {
      this.termsIcon = 'arrow_drop_down';
      this.doCollapse = false;
      this.nextAction = 'collapse';
    }
    this.setRepostionChat();
  }
  SprtPopupTabOut(event) {

    var data = $("#lhnHocButton");
    var getmore = $("#spnmoresupportoption");
    if (data != undefined && data != null && data.length > 0 && data[0] != undefined && data[0] != null) {
      if (data[0].getAttribute("style") != 'display: none;') {
        data[0].focus();

      }
      else {
        if (getmore != undefined && getmore != null && getmore.length > 0 && getmore[0] != undefined && getmore[0] != null) {
          getmore[0].focus();
        }
      }
    }
    else if (getmore != undefined && getmore != null && getmore.length > 0 && getmore[0] != undefined && getmore[0] != null) {
      getmore[0].focus();
    }
  }
}
