<br>
<br>
<div fxLayout="row">
    <div fxFlex="60" class="alert alert-danger technical-diff">
        We are currently experiencing technical difficulties.
        <br><br>
        We apologize for any inconvenience.
        <br><br>
        You can go back to the <a tabindex='0' href='/' class="spanLink">login page</a> and try again.
        <br><br>
        Please feel free to call us at the following numbers and times:
        <br><br>
        Live Chat help is available from 8:00 a.m. until 5:00 p.m. central time, Monday through Friday.
        <br><br>
        Sedgwick Technical Support is available from 8:00 a.m. to 7:00 p.m., central time, Monday through Friday.
        <br><br>
        Toll-free Number: (866) 647-7610
        <br><br>
        Toll Number: (901) 415-7800

        <div *ngIf="hasSessionId">
            <span>
            <br>
            Please have the following information available.
            <br><br>
                <span>
                    Session ID: <span>{{sessionId}} </span>
                <br>
                    <span>
                        {{currentDate}}
                    </span>
                </span>
            </span>
        </div>
    </div>
</div>
