export class ChangePasswordRequest {
  username: string;
  oldPassword: string;
  newPassword: string;
  salt: string;
  securityQuestion: string;
  securityAnswer: string;
  securityQuestion2: string;
  securityAnswer2: string;
  securityQuestion3: string;
  securityAnswer3: string;
  securityQuestion4: string;
  securityAnswer4: string;
  securityQuestion5: string;
  securityAnswer5: string;
  securityAnswerSalt: string;
  linkId: string;
  token: string;
}

export class ValidateChangePasswordRequest {
  encryptedData: string;

  constructor(values: ValidateChangePasswordRequest) {
    Object.assign(this, values);
  }
}

export class ValidateChangePasswordLinkResponse {
  userName: string;
  userId: string;
  linkId: string;
  isValidLink: string;
  isLinkExpired: string;
  token: string;

  constructor(values: ValidateChangePasswordLinkResponse) {
    Object.assign(this, values);
  }
}
