import { Injectable } from "@angular/core";

export interface Registration {
  contractNumber: number;
  hrMasterUid: string;
  claimUid: string;
  employeeId: string;
  empUnum: string;
  ssn: string;
  zipCode: string;
  dateOfBirth: string;
  claimNumber: string;
  firstName: string;
  lastName: string;
  middleInitial: string;
  email: string;
  username: string;
  password: string;
  passwordSalt: string;
  securityQuestion: string;
  securityAnswer: string;
  securityQuestion2: string;
  securityAnswer2: string;
  securityQuestion3: string;
  securityAnswer3: string;
  securityQuestion4: string;
  securityAnswer4: string;
  securityQuestion5: string;
  securityAnswer5: string;
  securityAnswerSalt: string;
  salt: string;
  clientCode: string;
  vanityPath: string;
  claimCity: string;
  claimState: string;
  registrationOptions: RegistrationOptions;
  method: RegistrationMethod;
  methodAsString: string;
  status: RegistrationStatus;
  statusAsString: string;
  type: AuthenticationType;
  typeAsString: string;
  twoFactorClient: boolean;
  pRClaimClient: boolean;
  gLClaimClient: boolean;
  aUClaimClient: boolean;
  suppressPrefVerification: boolean;
  twoFactorPhone: string;
  message: string;
  sourceSystem: string;
  subSystem: string;
  registrationSource: RegistrationSource;
  sessionId: string;
  userId: number;
  labelAliases: any;
  validClaimFound: boolean;
  SourceURL: string;
  Screen: string;
  loggedinImage: string
}
//@Injectable()
export class RegistrationExport implements Registration {
  contractNumber = 0;
  hrMasterUid = "";
  claimUid = "";
  employeeId = "";
  empUnum = "";
  ssn = "";
  zipCode = "";
  dateOfBirth = "";
  claimNumber = "";
  firstName = "";
  lastName = "";
  middleInitial = "";
  email = "";
  username = "";
  password = "";
  passwordSalt = "";
  securityQuestion = "";
  securityAnswer = "";
  securityQuestion2 = "";
  securityAnswer2 = "";
  securityQuestion3 = "";
  securityAnswer3 = "";
  securityQuestion4 = "";
  securityAnswer4 = "";
  securityQuestion5 = "";
  securityAnswer5 = "";
  securityAnswerSalt = "";
  salt = "";
  clientCode = "";
  vanityPath = "";
  claimCity = "";
  claimState = "";
  registrationOptions = RegistrationOptions.None;
  //method: registration ? registration.method : "";
  method: RegistrationMethod.None;
  methodAsString = "";
  //status : registration ? registration.status : "";
  status = RegistrationStatus.NoRegistration;
  statusAsString = "";
  type = AuthenticationType.Unknown;
  typeAsString = "";
  twoFactorClient = false;
  pRClaimClient = false;
  gLClaimClient = false;
  aUClaimClient = false;
  suppressPrefVerification = false;
  twoFactorPhone = "";
  message = "";
  sourceSystem = "";
  subSystem = "";
  registrationSource = RegistrationSource.Default;
  sessionId = "";
  userId = 0;
  labelAliases;
  validClaimFound = false;
  SourceURL = window.location.origin;
  Screen = "";
  loggedinImage = "";
}

export class  SsoRegistrationRequest {
  DoSsoRegistration: boolean;
  SessionId?: string;
  Username?: string;
  ForceTfa: boolean;
  UserEmail?: string;
  TextPhone?: string;
  ClientCode?: string;

  constructor(values: SsoRegistrationRequest) {
      Object.assign(this, values);
    }
}

export enum RegistrationMethod {
  Hr,
  Claim,
  None
}

export enum RegistrationOptions {
  None = -1,
  Default,
  Ssn4Zip,
  EmpIdZip,
  EmpIdZip6,
  SsnHomeZip,
  Ssn4EmpIdZip,
  PRClaim,
  GLClaim,
  AUClaim,
  LiabilityClaim,
  LiabilityCityState
}

export enum RegistrationStatus {
  Valid,
  UserAlreadyExists,
  TerminatedEmployee,
  MultipleContract,
  NotFound,
  Success,
  FailedToRegister,
  Denied,
  PasswordInvalid,
  HrClientNoMatch,
  DeadClient,
  ClientNotSetupYet,
  ClientNotLive,
  EmailInValid,
  Ineligible,
  //BlockRegistration,
  NoRegistration,
  ProvideEmployeeId,
  ProvideClaimNumber,
  OfficeRestricted,
  //PRClaimType,
  //GLClaimType,
  //WCClaimType,
  MultipleClaims,//WCHRClaimType
  //DSClaimType,
  //LVClaimType,
  ClaimClosed,
  PhoneInValid,
  NotIndividual,
  LOBNotSupportedByClient,
  ContactInValid,
  //AUClaimType,
  LiabilityClaimType,
  ClaimNotFound,
  EmailOrPhoneInValid,
  HrClientNoMatchTerminated
}

export enum AuthenticationType {
  Unknown,
  Claim,
  TripAbsence,
  HrRecord,
  ClientCode
}


export enum RegistrationSource {
  Default,
  ViaoneView
}


