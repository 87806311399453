import { Injectable } from "@angular/core";
import { AbstractService } from "./abstract.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { ChangePasswordRequest } from '../../shared/models/ChangePasswordRequest';
import { EncryptService } from "../../shared/services/encrypt.service";

@Injectable({
  providedIn: 'root'
})
export class PreferenceService extends AbstractService {
  constructor(protected http: HttpClient,
    private encrytor: EncryptService) {
      super(http);
  }

  /**
   * Change Password
   * @param ChangePasswordRequest
   */
  ChangePassword(changePasswordRequest: ChangePasswordRequest): Observable<boolean> {
    changePasswordRequest.newPassword = this.encrytor.encryptUsingAES256(changePasswordRequest.newPassword);
    return super.postData<boolean>("/User/ChangePassword",changePasswordRequest, changePasswordRequest.token);
  }
}
