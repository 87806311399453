import { Component, forwardRef, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, NG_VALIDATORS, AbstractControl  } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ErrorMessageLogicService } from 'src/app/shared/services/error-message-logic.service';
import { FormValidatorsService } from 'src/app/shared/services/form-validators.service';

export interface PasswordFormValues {
  password: string;
  confirmPassword: string;
}

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PasswordComponent),
      multi: true
    }
  ]
})
export class PasswordComponent implements  ControlValueAccessor, OnDestroy {
  @Input() parentForm: UntypedFormGroup;
  @Input() parentFormGroupName: string;
  @Input() showPassword: boolean;
  @Input() newPasswordText: string = "Password:";
  @Input() retypePasswordText: string = "Retype Password:";
  @Input() showEyeIcon: boolean = false;

  newPasswordAriaLabel: string = this.newPasswordText.replace(':', '');
  retypePasswordAriaLabel: string = this.retypePasswordText.replace(':', '');

  subscriptions: Subscription[] = [];
  passwordGroup: UntypedFormGroup;
  animationsDisabled: true;
  hidePwd: true;

  get value(): PasswordFormValues {
    return this.passwordGroup.value;
  }

  set value(value: PasswordFormValues) {
    if (this.passwordGroup.value != value) {
      this.passwordGroup.setValue(value);
    }
    this.onChange(value);
    this.onTouched();
  }

  get newPassword() {
    return this.passwordGroup.get('newPassword');
  }

  get confirmPassword() {
    return this.passwordGroup.get('confirmPassword');
  }

  constructor(private fb: UntypedFormBuilder,
              private formValidator: FormValidatorsService,
              private errorService: ErrorMessageLogicService,) {
                this.passwordGroup = this.buildPasswordGroup();
                this.subscriptions.push(
                  this.passwordGroup.valueChanges
                  .pipe(distinctUntilChanged((a, b ) => JSON.stringify(a) === JSON.stringify(b)))
                  .subscribe(value => {
                    this.onChange(value);
                    this.onTouched();
                    this.passwordGroup.updateValueAndValidity();
                    this.parentForm.get(this.parentFormGroupName).setValue(this.passwordGroup);
                    this.parentForm.updateValueAndValidity();
                  })
                );
              }



  buildPasswordGroup(): UntypedFormGroup {
    return this.fb.group(
      {
        newPassword: [
          '',
          {
            validators: [
              Validators.minLength(8),
              Validators.maxLength(16),
              Validators.required,
              Validators.pattern('(?=.*[A-Z])(?=.*[0-9])(?=.*[\\^+=@$#!%*?&])[A-Za-z\d\\^+=@$#!%*?&]*.{8,}')
            ],
            updateOn: "blur",
          },
        ],
        confirmPassword: [
          '',
          {
            validators: [
              Validators.minLength(8),
              Validators.maxLength(16),
              Validators.required,
              //Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^+=@$!%*?&])[A-Za-z\d\^+=@$!%*?&].{8,}'),
              this.formValidator.matchingFields('newPassword', 'confirmPassword')
            ],
            //updateOn: "blur",
          },
          
        ],
        updateOn : "submit" 
      },
      {
        validators: this.formValidator.matchingFields(
          'newPassword',
          'confirmPassword'
        ),
      }
    );
  }

  showPasswordIcon(): void {
    this.showPassword = !this.showPassword;
  }

  showErrorField(formControl: string): boolean {
    return this.errorService.showErrorMessage(this.passwordGroup, formControl);
  }

  ngOnDestroy(){
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null){
      this.passwordGroup.reset;
    }
  }

  // communicate the inner form validation to the parent form
  validate(_: UntypedFormControl) {
    return this.passwordGroup.valid ? null : { passwords: { valid: false } };
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
}
