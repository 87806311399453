import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SupportDialogComponent } from '../../core/components/support-dialog/support-dialog.component';

export enum ToastPopUpComponents
    {
        openSupportDialog="openSupportDialog",
        unlockAccountPage="unlockAccountPage",
        forgotPassword = "forgotPassword",
        openHelp = "openHelp"
    }

@Injectable({
  providedIn: 'root'
})
export class ToastPopupService {

  helpFile: string;
  constructor(private dialog: MatDialog,
              private router: Router) { }

  handleToastPopup(dataFunction: string): void {
    switch(dataFunction){
      case ToastPopUpComponents.openSupportDialog:
        this.openSupportDialog();
        break;
      case ToastPopUpComponents.unlockAccountPage:
        this.openUnlockAccountPage();
        break;
      case ToastPopUpComponents.forgotPassword:
        this.openForgotPassword();
        break;
      case ToastPopUpComponents.openHelp:
        this.openHelp();
        break;
      default:
        console.log(`Error trying to open ${dataFunction}. Add open component logic to toast-popup-service.`)
    }
  }

  openSupportDialog(): void {
    const dialogRef = this.dialog.open(SupportDialogComponent);
  }

  openUnlockAccountPage(): void {
    this.router.navigate(['unlockAccount']);
  }

  openForgotPassword(): void {
    this.router.navigate(['forgotPassword']);
  }

  openHelp(): void {
    window.open(environment.helpFile, '_blank');
  }
}
