import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-popup-header',
  templateUrl: './popup-header.component.html',
  styleUrls: ['./popup-header.component.scss']
})
export class PopupHeaderComponent implements OnInit {
  @Input() headerTitle: string;
  @Output() CloseTabOut:EventEmitter<any> = new EventEmitter();
  title: string;
  constructor() { }

  ngOnInit(): void {
    this.title = this.headerTitle;
  }

  PopupCloseTabOut(event) : boolean {
    
    if(event.keyCode == 9)
    {
      this.CloseTabOut.emit(true);
      return false;
    }

    return true;
  }

}
