import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClientService } from './api/client.service';

/**
 *
 */
@Injectable({
  providedIn: 'root',
})
export class ContentService {
  buildInfo: string;

  constructor(private clientService: ClientService, private router: Router) { }

/**
 * Get client details using vanity
 * @param vanityPath
 */
  GetVanityDetails(vanityPath: string): Observable<any> {

     return this.clientService.getVanityDetails(vanityPath);

  }

  GetAppVersion(): Observable<any>{
    return this.clientService.getAppVersion();
  }
}
