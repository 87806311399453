import { Component, OnInit, Input, ViewEncapsulation, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastPopupService } from 'src/app/shared/services/toast-popup.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { ToastOptions } from '../../../shared/models/toast-options.enum';

/*
  For the toastClick function to popup a built in component we need to make sure the message
  that is passed in is formatted with the proper data-function attribute to ensure we can parse
  the funciton that we are trying to call. If we are calling a component we havent called before we need
  to add a reference to that component in the ToastPopupService and make that service call the component.

  For the first example of this in action checkout the login-page.component and look for the
  reference to toastPopupOptions.openSupportDialog.

*/

@Component({
  selector: 'toast',
  templateUrl: 'toast.component.html',
  styleUrls: ['./toast.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToastComponent implements OnInit {
  @Input() message: string;
  @Input() type: string;
  @Input() isToastFocus: string = "false";

  public ToastEnum = ToastOptions;
  toastType: string;
  htmlData: any;
  htmlDataSronly: any;
  dataFunc: string;

  constructor(
    private sanitizer: DomSanitizer,
    private toastPopupService: ToastPopupService,
    private utilityService: UtilityService,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.toastType = this.determineToastType();
    this.htmlData = this.sanitizer.bypassSecurityTrustHtml(this.message);
    this.htmlDataSronly = this.sanitizer.bypassSecurityTrustHtml(this.message.replace('-', ' ').replace("tabindex='0'", ''));

    setTimeout(() => {
      if(this.isToastFocus == "true"){
        const invalidControl = this.el.nativeElement.querySelector('.' + this.toastType);
        if (invalidControl) {
          invalidControl.focus();
        }
      }
    }, 200);
  }

  toastClick($event: any): void {
    this.dataFunc = $event.target.dataset.function;
    if (!this.utilityService.isNullOrEmpty(this.dataFunc))
      this.toastPopupService.handleToastPopup(this.dataFunc);
  }

  determineToastType(): string {
    let toastType;
    switch (this.type) {
      case this.ToastEnum.Success:
        toastType = 'alert-success';
        break;
      case this.ToastEnum.Error:
        toastType = 'alert-danger';
        break;
      case this.ToastEnum.Info:
        toastType = 'alert-info';
        break;
      case this.ToastEnum.UserError:
        toastType = 'alert-userError';
        break;
      default:
        toastType = 'alert-success';
        break;
    }

    return toastType;
  }
}
