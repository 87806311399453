import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageLogicService {

  constructor() { }

  showErrorMessage(form: UntypedFormGroup, formControl: string) {
    return (form.get(formControl).touched ||
            form.get(formControl).dirty) &&
            form.get(formControl).invalid;
  }
}
