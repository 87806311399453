import { Injectable } from "@angular/core";
import { AbstractService } from "./abstract.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { LoginResponse, LoginRequest } from "../../shared/models/loginrequest";
import { ValidatedUser, ValidateUserRequest, ValidateEmailRequest } from "../../shared/models/ValidatedUser";
import { UnlockUserResult } from "../../shared/models/UnlockUserResult";
import { EmailLinkRequest, VerifySecurityAnswersRequest, GetSecurityQuestionRequest, ForgotPasswordQuestionRequest, UnlockUserRequest } from "../../shared/models/request";
import { AuthenticateSamlRequest, AuthenticateSamlTfaRequest, SsoRegistration, SsoRegistrationResult, SsoRequest } from "../../shared/models/ssorequest";
import { ChangePasswordRequest, ValidateChangePasswordLinkResponse, ValidateChangePasswordRequest } from "src/app/shared/models/ChangePasswordRequest";

/**
 * Service with HTTP methods
 */
@Injectable({ providedIn: "root" })
export class LoginService extends AbstractService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  // Some of these functions should probably be moved to a separate service when time allows, like a user service.

  login(request: LoginRequest): Observable<LoginResponse>
  {
    return super.postData<LoginResponse>("/Login/Login", request);
  }

  ssoToMySedgwick(request: SsoRequest): Observable<any>
  {
    return super.postData<any>("/Login/redirectToMySedgwick",request);
  }

  validateUser(request: ValidateUserRequest): Observable<ValidatedUser>
  {

    const body =JSON.stringify(request);
    return super.postCaptchaData<ValidatedUser>("/Login/ValidateUserExists",body);
  }

  validateEmail(request: ValidateEmailRequest): Observable<ValidatedUser>
  {

    const body =JSON.stringify(request);
    return super.postCaptchaData<ValidatedUser>("/Login/ValidateEmailExists",body);
  }

  validateChangePasswordLink(request: ValidateChangePasswordRequest): Observable<ValidateChangePasswordLinkResponse>{
    return super.postData<ValidateChangePasswordLinkResponse>("/User/ValidateChangePasswordLink", request);
  }

  sendEmailLink(request: EmailLinkRequest): Observable<any>
  {
    return super.postData<any>("/Login/SendEmailLink",request);
  }

  getSecurityQuestions(request: GetSecurityQuestionRequest): Observable<any>
  {
    return super.postData<any>("/Login/GetSecurityQuestions",request);
  }

  verifySecurityAnswers(request: VerifySecurityAnswersRequest): Observable<any>
  {
    return super.postData<any>("/User/VerifySecurityAnswers",request);
  }

  securityAnswersPasswordChange(request: ForgotPasswordQuestionRequest): Observable<any>
  {
    return super.postData<any>("/User/SecurityAnswersPasswordChange",request);
  }

  unlockUserAccount(request: UnlockUserRequest): Observable<UnlockUserResult>
  {
    const body = JSON.stringify(request);
    return super.postCaptchaData<any>("/Login/UnlockAccount",body);
  }

  ssoRegistration(): Observable<SsoRegistrationResult>
  {
    return super.getData<any>("/Registration/SsoRegistration");
  }

  authenticateSsoUserForTfa(request: AuthenticateSamlTfaRequest): Observable<LoginResponse>
  {
    return super.postData<LoginResponse>("/Login/AuthenticateSsoUserForTfa", request);
  }

  createSsoTwoFactorRequest(request: SsoRegistration): Observable<boolean>
  {
    return super.postData<boolean>("/User/CreateSsoTwoFactorRequest", request);
  }
  getSSORedirectCookieInformation(): Observable<any>
  {
    return super.getData<any>("/Sso/GetSSORedirectCookieInformation");
  }

  changePasswordQuestions(request: ChangePasswordRequest): Observable<boolean>
  {
    return super.postData<boolean>("/User/ChangePasswordQuestions", request);
  }

  getHashInfo(): Observable<any>
  {
    return super.getData<any>("/Login/HashInfo")
  }
}
