import { Component, Injectable, OnInit, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Spinner } from 'spin.js';
import { BlankComponent } from '../blank/blank.component';

declare var $: any;
@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
@Injectable({
  providedIn: 'root'
})
//Used like a wrapper component for our spinner
export class SpinnerComponent implements OnInit, AfterViewInit {
  isBusy: boolean;
  spinner: any;

  private dialogConfig = {
    disableClose: true,
    panelClass: 'hideBackground'
  };

  private defaults: any = {
    lines: 12,            // The number of lines to draw
    length: 7,            // The length of each line
    width: 5,             // The line thickness
    radius: 10,           // The radius of the inner circle
    rotate: 0,            // Rotation offset
    corners: 1,           // Roundness (0..1)
    color: 'rgb(51, 51, 51)',        // #rgb or #rrggbb
    direction: 1,         // 1: clockwise, -1: counterclockwise
    speed: 1,             // Rounds per second
    trail: 100,           // Afterglow percentage
    opacity: 1 / 4,         // Opacity of the lines
    fps: 20,              // Frames per second when using setTimeout()
    zIndex: 2e9,          // Use a high z-index by default
    className: 'spinner', // CSS class to assign to the element
    top: '50%',           // center vertically
    left: '50%',          // center horizontally
    position: 'absolute'  // element position
  };

  constructor(private dialog: MatDialog) {
    this.spinner = new Spinner(this.defaults);
  }

  ngAfterViewInit(): void {
    $(document).ready(function(){
        setInterval(() => {
          $("#SpinnerDisplay>div").attr("role","presentation")
        }, 1000);
    });
   }

  ngOnInit(): void {

  }

  openComponent(spin: boolean) {
    if (spin) {
      
      this.dialog.open(BlankComponent, this.dialogConfig);
    }
    else {
      this.dialog.closeAll();
    }
  }

  spinnerAction = (spin: boolean, elementId?: string) => {
   
    $("#SpinnerDisplay>div").attr("role","presentation")
    this.openComponent(spin);
    let element = document.getElementById(elementId ?? 'SpinnerDisplay');
    if (spin) {

      this.isBusy = true;
      return this.spinner.spin(element);
    }

    else {
      this.isBusy = false;
      this.spinner.stop();
    }

  }
}
