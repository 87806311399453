<div id="forgotpassword" class="forgotpasswordContent">
  <div fxLayout="column" fxFlexAlign="center">
    <h1  role="heading">{{pageTitle}}</h1>
  </div>
  <div class="forgotpassword-container">
    <div id="forgotPasswordUservalidation" [hidden]="!showforgotPasswordForm">
      <form fxLayout="column" novalidate (ngSubmit)="onForgotPasswordSubmit()" [formGroup]="forgotPasswordForm" role="none">
        <ul class="flex-outer" role="none">
          <li role="none">
            <label for="usernameId">{{usernameQuestionText}}: *</label>
            <mat-form-field>
              <input matInput autocomplete="off" required id="usernameId" type="text" formControlName="username"
                [attr.aria-label]="usernameQuestionText"
                [ngClass]="{'is-invalid': forgotPasswordForm.get('username').dirty &&
                          !forgotPasswordForm.get('username').valid }" />
              <mat-error role="none" class="error-message"
                *ngIf="(forgotPasswordForm.get('username').errors?.required || forgotPasswordForm.get('username').dirty) && !forgotPasswordForm.get('username').valid">

                  <span style="background:white;padding:5px">Please enter your username.</span>

              </mat-error>
            </mat-form-field>
          </li>
          <!-- <li role="none" *ngIf="forgotPasswordForm.get('username').dirty &&
                    !forgotPasswordForm.get('username').valid">
            <p></p>
            <span class="error-message errormessagemargintop-20" *ngIf="forgotPasswordForm.get('username').errors?.required">
              Please enter your username.
            </span>
          </li> -->
          <li role="none">
            <p></p>
            <label>
              <a  (click)="onForgotUsernameClick()">Forgot Username?</a>
            </label>
          </li>
          <li role="none">
            <p></p>
            <botdetect-captcha captchaStyleName="MeltingHeat"></botdetect-captcha>
          </li>
          <li role="none">
            <label for="captchaId">Enter Displayed Text: *</label>
            <mat-form-field [@.disabled]="animationsDisabled">
              <input class="form-control" matInput id="captchaId" name="captchaId" required type="text"
                formControlName="captcha" aria-label="Enter Displayed Text" autocomplete="off">
              <mat-error role="none" class="error-message"
                *ngIf="(forgotPasswordForm.get('captcha').errors?.required || forgotPasswordForm.get('captcha').dirty) && !forgotPasswordForm.get('captcha').valid">
                <span style="background:white;padding:5px">{{captchaError}}</span>
              </mat-error>
            </mat-form-field>
          </li>
          <!-- <li role="none" *ngIf="forgotPasswordForm.get('captcha').dirty &&
                    !forgotPasswordForm.get('captcha').valid">
            <p></p>
            <span class="error-message errormessagemargintop-20">
              {{captchaError}}
            </span>
          </li> -->
        </ul>

        <div dir="ltr" fxLayoutGap="20px" fxFlexAlign="end">
          <button type="button" mat-raised-button color="whiteButton" (click)="onCancel()" aria-label="Cancel">
            <mat-icon aria-hidden="true">block</mat-icon>
            Cancel
          </button>

          <!--<span role="button" class="sr-only"
            tabindex="forgotPasswordForm.valid ? '-1' : '0'"
            aria-label="Next"
            aria-hidden="true"
            [attr.title]="forgotPasswordForm.valid ? '' : 'disabled until mandatory fields are missing'">
          </span>-->
          <button mat-raised-button color="primary" type="submit"
            [title]="forgotPasswordForm.valid ? '' : 'Disabled until the form data is valid'"
            [disabled]="!forgotPasswordForm.valid"
            aria-label="Next">

            <mat-icon aria-hidden="false">forward</mat-icon>
            Next
          </button>
        </div>
      </form>
    </div>
    <!-- User validation form end -->
    <div id="selectResetMethod" *ngIf="showResetmethodForm">
      <form role="none" fxLayout="column" novalidate [formGroup]="selectResetmethodForm" (ngSubmit)="onSelectResetMethodSubmit()">
        <ul class="flex-outer" role="none">
          <li role="none">
            <label for="resetMethod">Please select how would you like to reset your password?</label>
            <mat-form-field>
              <mat-select autocomplete="off" required id="resetMethod" formControlName="resetMethod"
                placeholder="Select One"
                aria-label="Please select how would you like to reset your password" (selectionChange)="selectedUpdatedMethodChanged()">
                <mat-option value="SECURITY_QUESTION">Answer Security Question</mat-option>
                <mat-option value="EMAIL_LINK">Email Link</mat-option>
              </mat-select>
              <mat-error role="none" *ngIf="(selectResetmethodForm.get('resetMethod').touched ||
                selectResetmethodForm.get('resetMethod').dirty) && selectResetmethodForm.get('resetMethod').errors?.required &&
                !selectResetmethodForm.get('resetMethod').valid">
                  <span style="background:white;padding:5px">How would you like to reset your password is required.</span>
              </mat-error>
            </mat-form-field>
          </li>
          <!-- <li role="none" *ngIf="(selectResetmethodForm.get('resetMethod').touched ||
                    selectResetmethodForm.get('resetMethod').dirty) &&
                    !selectResetmethodForm.get('resetMethod').valid">
            <p></p>
            <span class="error-message errormessagemargintop-20" *ngIf="selectResetmethodForm.get('resetMethod').errors?.required">
              Response is required.
            </span>
          </li> -->

          <li role="none"  *ngIf="resetMethod == 'EMAIL_LINK'">
            <label for="emailOnAccount">Please enter the email address associated with your account: *</label>
            <mat-form-field [@.disabled]="animationsDisabled">
              <input matInput class="input" type="email" formControlName="emailOnAccount" name="emailOnAccount" required
                id="emailOnAccount" autocomplete="off" aria-label="Personal email" />

                <mat-error  role="none" class="error-message"
                *ngIf="(selectResetmethodForm.get('emailOnAccount').invalid &&
                selectResetmethodForm.get('emailOnAccount').errors &&
                (selectResetmethodForm.get('emailOnAccount').dirty || selectResetmethodForm.get('emailOnAccount').touched)) && selectResetmethodForm.get('emailOnAccount').hasError('required')">
                <span style="background:white;padding:5px">
                Email on account is required.</span>
              </mat-error>
              <mat-error  role="none" class="error-message"
                *ngIf="(selectResetmethodForm.get('emailOnAccount').invalid &&
                selectResetmethodForm.get('emailOnAccount').errors &&
                (selectResetmethodForm.get('emailOnAccount').dirty || selectResetmethodForm.get('emailOnAccount').touched)) && selectResetmethodForm.get('emailOnAccount').hasError('pattern')">
                <span style="background:white;padding:5px">
                Please enter a valid Email.</span>
              </mat-error>

            </mat-form-field>
          </li>

        </ul>

        <div dir="ltr" fxLayoutGap="20px" fxFlexAlign="end">
          <button type="button" mat-raised-button color="whiteButton" (click)="onCancel()" aria-label="Cancel">
            <mat-icon aria-hidden="true">block</mat-icon>
            Cancel
          </button>

          <!--<span role="button" class="sr-only"
            aria-label="Next"
            aria-hidden="true"
            tabindex="selectResetmethodForm.valid ? '-1' : '0'"
            [attr.title]="selectResetmethodForm.valid ? '' : 'disabled until mandatory fields are missing'">
          </span>-->
          <button mat-raised-button color="primary" type="submit"
            aria-label="Next"
            aria-hidden="true"
            [title]="selectResetmethodForm.valid ? '' : 'Disabled until mandatory fields are missing'"
            [disabled]="!selectResetmethodForm.valid">
            <mat-icon aria-hidden="false">forward</mat-icon>
            Next
          </button>
        </div>
      </form>
    </div>
    <!-- Show reset form end -->
    <div id="securityQuestions" *ngIf="showSecurityQuestionForm">
      <form fxLayout="column" novalidate [formGroup]="securityQuestionsForm" role="none"
        (ngSubmit)="onSecurityQuestionsFormSubmit()">
        <app-security-questions [username]=forgotPasswordRequest.userName formControlName="securityQuestions">
        </app-security-questions>

        <div dir="ltr" fxLayoutGap="20px" fxFlexAlign="end">
          <button type="button" mat-raised-button color="whiteButton" (click)="onCancel()" aria-label="Cancel">
            <mat-icon aria-hidden="true">block</mat-icon>
            Cancel
          </button>

          <!--<span role="button" class="sr-only"
            aria-label="Next"
            aria-hidden="true"
            tabindex="securityQuestionsForm.valid ? '-1' : '0'"
            [attr.title]="securityQuestionsForm.valid ? '' : 'disabled until mandatory fields are missing'">
          </span>-->
          <button mat-raised-button color="primary" type="submit"
            [title]="securityQuestionsForm.valid ? '' : 'Disabled until the form data is valid'"
            [disabled]="!securityQuestionsForm.valid"
            aria-label="Next"
            aria-hidden="true">
            <mat-icon aria-hidden="false">forward</mat-icon>
            Next
          </button>

        </div>

      </form>
    </div>
    <br aria-hidden="true">
  <div *ngIf="showToastMessage" (click)="showSupportModal ? openSupportDialog() : ''">
    <toast #toastFocus [message]="toastMessage" [type]="toastEnumOptions" isToastFocus="true"></toast>
  </div>
</div>
