import { Component, OnInit } from '@angular/core';
import { ContentService } from 'src/app/service/content.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-buildinfo',
  templateUrl: './buildinfo.component.html',
  styleUrls: ['./buildinfo.component.scss']
})
export class BuildinfoComponent implements OnInit {
  appCompany: string;
  isDemoMode: boolean;
  version: string;
  buildInfo: string;
  constructor(private contentService: ContentService) { }

  ngOnInit(): void {
    this.version = environment.appVersion;
    this.buildInfo = this.contentService.buildInfo;
    this.appCompany = environment.AppCompany.replace("#YEAR",new Date().getFullYear().toString());
    this.isDemoMode = environment.QAMode;
    //console.log(`Version: ${this.version}, buildInfo: ${this.buildInfo} company ${this.appCompany} demoMode: ${this.isDemoMode}`);
  }

}
