import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, ValidatorFn, FormArray } from '@angular/forms';
import { CaptchaComponent } from 'angular-captcha';
import { Title } from '@angular/platform-browser';
import { GlobalService } from '../../../shared/settings/global.service';
import { ValidateUserRequest, UserStatus, SecurtyAnswerStatus } from '../../../shared/models/ValidatedUser';
import { UserService } from 'src/app/service/user.service';
import { ToastOptions } from '../../../shared/models/toast-options.enum';
import { EmailLinkRequest, ForgotPasswordQuestionRequest, VerifySecurityAnswersRequest } from '../../../shared/models/request';
import { SupportDialogComponent } from 'src/app/core/components/support-dialog/support-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormValidatorsService } from 'src/app/shared/services/form-validators.service';
import { ToastPopUpComponents } from 'src/app/shared/services/toast-popup.service';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  moduleId: module.id,
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent implements AfterViewInit {


  @ViewChild(CaptchaComponent, { static: true }) captchaComponent: CaptchaComponent;

  pageTitle: string;
  showforgotPasswordForm: boolean = true;
  showSupportModal: boolean = false;
  showNewpasswordForm: boolean = false;
  showResetmethodForm: boolean;
  showSecurityQuestionForm: boolean;
  forgotPasswordForm: UntypedFormGroup;
  securityQuestionsForm: UntypedFormGroup;
  selectResetmethodForm: UntypedFormGroup;
  forgotPasswordRequest: ValidateUserRequest;
  emailLinkRequest: EmailLinkRequest;
  usernameQuestionText: string;
  showToastMessage: boolean;
  toastMessage: string;
  toastEnumOptions: any;
  captchaError: string;
  answerList: any;
  toastPopupOptions = ToastPopUpComponents;
  animationsDisabled = true;
  resetMethod : string;

  constructor(private router: Router,
    private fb: UntypedFormBuilder,
    private formBuilder: UntypedFormBuilder,
    private titleService: Title,
    private userService: UserService,
    private utility: UtilityService,
    public dialog: MatDialog) {
    this.showforgotPasswordForm = true;
    this.showNewpasswordForm = false;
    this.showSecurityQuestionForm = false;
    this.showResetmethodForm = false;
  }


  ngOnInit(): void {
    this.captchaError = "Captcha Required";
    this.showSupportModal = false;
    this.showforgotPasswordForm = true;
    this.showNewpasswordForm = false;
    this.showSecurityQuestionForm = false;
    this.showResetmethodForm = false;
    this.captchaComponent.captchaEndpoint = this.utility.determineCaptchaEndpoint();
    this.titleService.setTitle('mySedgwick - Forgot Password');
    this.pageTitle = "Forgot Password";
    this.usernameQuestionText = "Please provide your username";
    this.forgotPasswordForm = this.fb.group({
      username: ['', [Validators.required]],
      captcha: ['', [Validators.required]]
    });
    this.selectResetmethodForm = this.fb.group({
      resetMethod: ['', [Validators.required]],
      emailOnAccount: ['',
      [Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]]
    });
    this.securityQuestionsForm = this.fb.group({
      securityQuestions: ['', [Validators.required]]
    });

    //this.setValidators();
  }

  ngAfterViewInit():void{

     setTimeout(function(){

     if(document.getElementById("MeltingHeat_ReloadLink") != undefined && document.getElementById("MeltingHeat_ReloadLink") != null)
     {
       document.getElementById("MeltingHeat_ReloadLink").setAttribute("role","button");
     }

     if(document.getElementById("MeltingHeat_SoundLink") != undefined && document.getElementById("MeltingHeat_SoundLink") != null)
     {
      document.getElementById("MeltingHeat_SoundLink").setAttribute("role","button");
     }
     },1000);

   }

  setValidators()
  {
      if (this.resetMethod != 'EMAIL_LINK') { //This if condition need to be changed once we remove duplicate Employee ID in html.
        this.selectResetmethodForm.controls['emailOnAccount'].setValue('') ;
        this.selectResetmethodForm.get('emailOnAccount').clearValidators();
        this.selectResetmethodForm.get('emailOnAccount').updateValueAndValidity();
      } else {
        this.selectResetmethodForm.get('emailOnAccount').setValidators([Validators.required,Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]);
        this.selectResetmethodForm.get('emailOnAccount').updateValueAndValidity();
      }
  }

  onCancel(): void {
    this.router.navigate(['/']);
  }

  onForgotUsernameClick(): void {

    this.router.navigateByUrl('/forgotusername', { skipLocationChange: true });
  }
  openSupportDialog(): void {
    const dialogRef = this.dialog.open(SupportDialogComponent);
  }

  selectedUpdatedMethodChanged(): void{
    this.resetMethod=this.selectResetmethodForm.value.resetMethod;
    this.setValidators();
  }

  onSelectResetMethodSubmit(): void {

    if (this.selectResetmethodForm.invalid) return;

    this.resetMethod = this.selectResetmethodForm.value.resetMethod;

    this.emailLinkRequest = new EmailLinkRequest({
      userName: this.forgotPasswordRequest.userName,
      emailAddress: this.selectResetmethodForm.value.emailOnAccount,
      mobileNo: '',
      verifyType: '',
      firstName: '',
      lastName: '',
      delieverBy: '',
      userid: '',
      isChangePasswordRequest: true,
    });
    this.toastMessage = `Thank You.</br>If a username is found, a reset link will be sent to you by email.</br>Please check your Inbox, Junk and Spam folders for an email prior to contacting <span role='link' tabindex='0' aria-label='contact support popup' data-function=${this.toastPopupOptions.openSupportDialog} class='spanLink'>Sedgwick Support</span>.</br>You may close this browser window.`
    if (this.resetMethod == "EMAIL_LINK") {
      this.emailLinkRequest.delieverBy = "EmailLink";
      this.userService.sendEmailLink(this.emailLinkRequest).subscribe(
        (result) => {

          this.showToastMessage = true;
          this.toastEnumOptions = ToastOptions.Success;
          this.showforgotPasswordForm = false;
          this.showNewpasswordForm = false;
          this.showResetmethodForm = false;
          this.showSecurityQuestionForm = false;
          this.showSupportModal = false;
        },
        (e) => {


          this.showforgotPasswordForm = false;
          this.showNewpasswordForm = false;
          this.showResetmethodForm = false;
          this.showSecurityQuestionForm = false;
          this.showToastMessage = true;
          this.showSupportModal = false;
          this.toastEnumOptions = ToastOptions.Success;
        }
      );
    }
    else if (this.resetMethod == "SECURITY_QUESTION") {
      this.showforgotPasswordForm = false;
      this.showNewpasswordForm = false;
      this.showResetmethodForm = false;
      this.showSecurityQuestionForm = true;
    }
  }

  onForgotPasswordSubmit(): void {

    if (this.forgotPasswordForm.invalid) return;

    // get the user-entered captcha code value to be validated at the backend side
    let userEnteredCaptchaCode = this.captchaComponent.userEnteredCaptchaCode;

    // get the id of a captcha instance that the user tried to solve
    let captchaId = this.captchaComponent.captchaId;

    this.forgotPasswordRequest = new ValidateUserRequest({
      captchaId: captchaId,
      userEnteredCaptchaCode: userEnteredCaptchaCode,
      userName: this.forgotPasswordForm.value.username,
      email: ''
    });

    this.userService.validateUser(this.forgotPasswordRequest).subscribe(
      (result) => {
        this.showToastMessage = true;
        switch (result.userStatus) {
          case UserStatus.Valid:
          case UserStatus.Locked:
            this.showforgotPasswordForm = false;
            this.showNewpasswordForm = false;
            this.showResetmethodForm = true;
            this.showToastMessage = false;
            break;
          case UserStatus.NotFound:
            this.toastMessage = "The system could not validate your information.</br>Please confirm your entry and try again.";
            this.toastEnumOptions = ToastOptions.Info
            this.captchaComponent.reloadImage();
            break;
          case UserStatus.Disabled:
            this.toastMessage = "Your mySedgwick login has been disabled due to excessive reset attempts. If you have any questions please contact <span class='spanLink'>Sedgwick Support</span>.";
            this.toastEnumOptions = ToastOptions.Info
            this.captchaComponent.reloadImage();
            break;
          case UserStatus.DeadClient:
            this.toastMessage = "The system could not validate your information.</br>Please confirm your entry and try again.";
            this.toastEnumOptions = ToastOptions.Info
            this.captchaComponent.reloadImage();
            break;
          case UserStatus.ClientNotSetupYet:
            this.toastMessage = "Your company is not setup on mySedgwick. Please contact your HR department.";
            this.toastEnumOptions = ToastOptions.Info
            this.captchaComponent.reloadImage();
            break;
          case UserStatus.BadCaptCha:
            this.setInvalidCaptcha();
            break;
        }
      },
      (e) => {
        // Currently returning BadResponse from API if invalid captcha, do we need something
        // better to differentiate between server errors and invalid captchas?
        this.setInvalidCaptcha();
      }
    );
  }
  onSecurityQuestionsFormSubmit() {

    var securityQuestions = this.securityQuestionsForm.value.securityQuestions
    this.answerList = securityQuestions;

    let securityAnswerRequest = new VerifySecurityAnswersRequest({
      username: this.answerList.username,
      question: this.answerList.question,
      question2: this.answerList.question2,
      question3: this.answerList.question3,
      answer: this.answerList.answer,
      answer2: this.answerList.answer2,
      answer3: this.answerList.answer3,

    });
    this.userService.verifySecurityAnswers(securityAnswerRequest).subscribe(
      (result) => {
        let res = result;

        switch (result.answerStatus) {
          case SecurtyAnswerStatus.Success:
            this.userService.username = securityAnswerRequest.username;
            this.userService.forgotPasswordToken = res.token;
            this.router.navigate(['../changePassword']);
            break;

          case SecurtyAnswerStatus.Disabled:
          case SecurtyAnswerStatus.DisabledFailure:
            this.userService.accountDisabled = true;
            this.showToastMessage = true;
            this.toastMessage = 'The system could not validate your answers.';
            this.toastEnumOptions = ToastOptions.Info;
            this.captchaComponent.reloadImage();
            break;
          case SecurtyAnswerStatus.Failed:
            this.showToastMessage = true;
            this.toastMessage = 'The system could not validate your answers.';
            this.toastEnumOptions = ToastOptions.Info;
            this.captchaComponent.reloadImage();
            break;

        }
      },
      (e) => {
      }
    );
  }

  setInvalidCaptcha(){
    this.forgotPasswordForm.controls['captcha'].setErrors({ incorrect: true, message: 'Please enter valid value' });
    this.forgotPasswordForm.controls['captcha'].markAsDirty();
    this.captchaComponent.reloadImage();
    this.toastMessage = "";
    this.captchaError = "Invalid Captcha value provided";
  }

}

