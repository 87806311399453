<div  id="forgotUserName" class="forgotUserNameContent" >
    <div fxLayout="column" fxFlexAlign="center">
      <h1  role="heading">{{pageTitle}}</h1>
    </div>
    <div class="forgotUserName-container" [hidden]="!showSubmitUsername">
      <form fxLayout="column" novalidate (ngSubmit)="onforgotUserNameSubmit()" [formGroup]="forgotUserNameForm" role="none">
        <ul class="flex-outer" role="none">
          <li role="none">
            <label for="emailId">{{emailAddressQuestionText}}: *</label>
            <mat-form-field >
              <input matInput autocomplete="off" required id="emailId" email type="email" formControlName="email"
                    [attr.aria-label]="emailAddressQuestionText" [ngClass]="{'is-invalid': forgotUserNameForm.get('email').dirty &&
                                      !forgotUserNameForm.get('email').valid }" />
              <mat-error class="error-message" role="none"
                *ngIf="(forgotUserNameForm.get('email').errors?.required || forgotUserNameForm.get('email').dirty) && !forgotUserNameForm.get('email').valid">
                <span style="background:white;padding:5px">Please enter your email address.</span>
              </mat-error>
            </mat-form-field>
          </li>
          <!-- <li role="none" *ngIf="forgotUserNameForm.get('email').dirty &&
                      !forgotUserNameForm.get('email').valid">
                      <p></p>
            <span class="error-message errormessagemargintop-20" *ngIf="forgotUserNameForm.get('email').errors?.required">
              Please enter your email address.
            </span>
          </li>           -->
          <li role="none">
            <p></p>
            <botdetect-captcha captchaStyleName="MeltingHeat"></botdetect-captcha>  
          </li>
          <li role="none">
            <label for="captchaId">Enter Displayed Text: *</label>
            <mat-form-field [@.disabled]="animationsDisabled">
              <input id="captchaId"
              name="captchaId"
              matInput
              required
              type="text"
              formControlName="captcha"
              aria-label="Enter Displayed Text">
              <mat-error class="error-message" role="none"
                *ngIf="(forgotUserNameForm.get('captcha').errors?.required || forgotUserNameForm.get('captcha').dirty) && !forgotUserNameForm.get('captcha').valid">
                <span style="background:white;padding:5px">{{captchaError}}</span>
              </mat-error>
            </mat-form-field>
          </li>
          
          <!-- <li role="none" *ngIf="forgotUserNameForm.get('captcha').dirty &&
                      !forgotUserNameForm.get('captcha').valid">
                      <p></p>
            <span class="error-message errormessagemargintop-20" >
              {{captchaError}}
            </span>
          </li> -->
        </ul>
  
        <div dir="ltr" fxLayoutGap="20px" fxFlexAlign="end">
          <button type="button" mat-raised-button color="primary" (click)="onCancel()" aria-label="Cancel">
            <mat-icon aria-hidden="true">block</mat-icon>
            Cancel
          </button>
          
          <!--<span role="button" class=""
            tabindex="forgotUserNameForm.valid ? '-1' : '0'"
            aria-label="Next"
            aria-hidden="true"
            [attr.title]="forgotUserNameForm.valid ? '' : 'disabled until mandatory fields are missing'">
          </span>-->
          <button mat-raised-button color="primary" type="submit"
            [title]="forgotUserNameForm.valid ? '' : 'Disabled until the form data is valid'"
            [disabled]="!forgotUserNameForm.valid"
            aria-label="Next"
            aria-hidden="true">
            <mat-icon aria-hidden="false">forward</mat-icon>
            Next
          </button> 
        </div>
      </form>
    </div>
  </div>
  <br aria-hidden="true" />
  <div *ngIf="submittedRequest" (click)="showSupportModal ? openSupportDialog() : ''">
    <toast [message]="confirmationMessage" [type]="toastEnumOptions" isToastFocus="true"></toast>  
  </div>