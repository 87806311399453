import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { throwError } from "rxjs/internal/observable/throwError";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { LoggingService } from "./logging.service";
import { LoggingRequest } from "src/app/shared/models/Log";
//import { stringToKeyValue } from "@angular/flex-layout/extended/typings/style/style-transforms";   
import { UtilityService } from "src/app/shared/services/utility.service";

export class AbstractService {

  logRequest: LoggingRequest;
  private loggingService: LoggingService;
  private utility: UtilityService;
  constructor(protected http: HttpClient) {
    this.loggingService = new LoggingService(http);
    this.utility = new UtilityService();
  }


  protected handleError = (error: HttpErrorResponse) => {
    this.logRequest = new LoggingRequest({
      message: this.getErrorMessage(error),
      severity: "Error"
    });
    if (error == undefined || error.error == undefined)
      return throwError("Cannot Get Accuracy");
      //this.loggingService.log(this.logRequest);
    this.loggingService.log(this.logRequest).subscribe((result)=>{
      return throwError(error.error.message);
    });

  };

  protected postData<T>(url:string,data:any, token:any = null):Observable<T>{
    if (token == null) {
      return this.http
      .post<T>(`api${url}`,data)
    .pipe(catchError(this.handleError));
    }
    else {
      return this.http.post<T>(`api${url}`, data,
      {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        //responseType: 'text'
      }).pipe(catchError(this.handleError));
    }
  }

  protected getData<T>(url: string):Observable<T>{
    return this.http
      .get<T>(`api${url}`)
    .pipe(catchError(this.handleError));
  }

  protected postCaptchaData<T>(url:string,body:any):Observable<T>{

    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    let formData = new FormData();
    formData.append('Method', 'POST');
    formData.append('Host', 'WebApi');
    formData.append('Url', url);
    formData.append('Data', body);
    return this.http
      .post<T>(`captcha`,formData)
    .pipe(catchError(this.handleError));
  }

  protected getErrorMessage(error: HttpErrorResponse): string{
    let message = '';
    message = error.message;
    if(!this.utility.isNullOrEmpty(error.error)){
       message += ' - ' + error.error.title;
       if(!this.utility.isNullOrEmpty(error.error.errors)){

        message += ':: ' + JSON.stringify(error.error.errors);
      }
    }


    return message;
  }
}


