import { Injectable } from '@angular/core';
import { AbstractService } from "./abstract.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { ValidateVerificationCode, ValidateVerificationCodeRequest, VerificationCodeRequest } from 'src/app/shared/models/request';
import { MessageModel } from 'src/app/shared/models/MessageModel';

@Injectable({
  providedIn: 'root'
})
export class TwoFactorService extends AbstractService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  sendVerificationCode(request: VerificationCodeRequest): Observable<boolean>
  {
    return super.postData<boolean>("/User/SendVerificationCode", request);
  }

  validateVerificationCode(request: ValidateVerificationCodeRequest): Observable<ValidateVerificationCode>
  {
    return super.postData<ValidateVerificationCode>("/User/ValidateVerificationCode", request);
  }

  sendTwoFactorMessage(request: VerificationCodeRequest): Observable<MessageModel>{
    return super.postData<MessageModel>("/User/SendTwoFactorMessage", request);
  }

}
