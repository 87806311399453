import { Component, OnInit, Input, SimpleChanges, forwardRef, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, NG_VALIDATORS, AbstractControl  } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { ErrorMessageLogicService } from '../../../shared/services/error-message-logic.service';
import { FormValidatorsService } from '../../../shared/services/form-validators.service';
import { UtilityService } from '../../../shared/services/utility.service';

//describes what the return value of the form control will look like
export interface PhoneComponentValue {
  areaCode: number;
  threeDigits: number;
  fourDigits: number;
  phoneNumber: string;
}

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneComponent),
      multi: true
    }
  ]
})
  //NOTE Example implementations of this is with the MultiFactorAuthentication Component
  // and the Registration component. Both use this component, but are setup in different ways
export class PhoneComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() hidePhoneNumber?: boolean;
  @Input() required: boolean;
  @Input() prefilledNumber: string;
  @Input() attemptedSubmission: boolean;
  @Input() parentId: string;
  @Input() parentForm: UntypedFormGroup;
  @Input() parentFormGroupName: string;
  @Input() phoneLabel:string ;  
  phoneGroup: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  areaCode: number;
  threeDigits: number;
  fourDigits: number;
  tempPhoneNumber: string;
  initialFunctionRan: boolean;
  prefilledDisplayLabel: string;
  areaCodestr : string;
  threeDigitstr : string;
  foureDigitstr : string;
  get value(): PhoneComponentValue {
    return this.phoneGroup.value;
  }

  set value(value: PhoneComponentValue) {
    if (this.phoneGroup.value != value) {
      this.phoneGroup.setValue(value);
    }
    this.onChange(value);
    this.onTouched();
  }

  get areaCodeControl() {
    return this.phoneGroup.controls.areaCode;
  }

  get threeDigitsControl(){
    return this.phoneGroup.controls.threeDigits;
  }

  get fourDigitsControl() {
    return this.phoneGroup.controls.fourDigits;
  }

  get phoneNumberControl(){
    return this.phoneGroup.controls.phoneNumber;
  }

  constructor(private fb: UntypedFormBuilder,
              private formValidatorService: FormValidatorsService,
              private util: UtilityService,
              private errorService: ErrorMessageLogicService) {
  }

  ngOnInit(): void {

    if(this.phoneLabel!= undefined && this.phoneLabel.length > 0)
    {
     // this.areaCodestr = `Enter ${this.phoneLabel} 3 digit part max 3 characters`;
     // this.threeDigitstr = `Enter ${this.phoneLabel} 3 digit part max 3 characters`;
     // this.foureDigitstr = `Enter ${this.phoneLabel} 4 digit part max 4 characters`;
      this.areaCodestr = `Area code 3 digits`;
      this.threeDigitstr = `Telephone prefix 3 digits`;
      this.foureDigitstr = `Line number 4 digits`;
    }

    if (this.prefilledNumber) {
      this.filloutPrefilledValue(this.prefilledNumber);
    }

    this.phoneGroup = this.buildPhoneFormGroup();

    if (!this.util.isNullOrEmpty(this.prefilledNumber))
      this.prefilledNumberSubscription();

    else {
      this.subscriptions.push(
        // any time the inner form changes update the parent of any change
        this.phoneGroup.valueChanges
          .pipe(distinctUntilChanged((a, b ) => JSON.stringify(a) === JSON.stringify(b)))
          .subscribe(value => {
          this.onChange(value);
          this.onTouched();
          if (!this.util.isNullOrEmpty(value.threeDigits) &&
              !this.util.isNullOrEmpty(value.areaCode) &&
              !this.util.isNullOrEmpty(value.fourDigits)){
                this.tempPhoneNumber = this.buildPhoneNumber(value.areaCode, value.threeDigits, value.fourDigits);
                if (this.phoneNumberControl.value != this.tempPhoneNumber){
                  this.phoneNumberControl.setValue(this.buildPhoneNumber(this.areaCodeControl.value, this.threeDigitsControl.value, this.fourDigitsControl.value));
                }
          }

          if (!this.required){
            if (!this.util.isNullOrEmpty(value.threeDigits) ||
                  !this.util.isNullOrEmpty(value.areaCode)||
                  !this.util.isNullOrEmpty(value.fourDigits)) {
                    this.phoneGroup.get('areaCode').setValidators(this.buildValidators(3, true));
                    this.phoneGroup.get('threeDigits').setValidators(this.buildValidators(3, true));
                    this.phoneGroup.get('fourDigits').setValidators(this.buildValidators(4, true));
                    this.markControlsAsTouched();

                    this.phoneGroup.updateValueAndValidity();
                    this.parentForm.get(this.parentFormGroupName).updateValueAndValidity();
            }

            else if (this.util.isNullOrEmpty(value.threeDigits) &&
                  this.util.isNullOrEmpty(value.areaCode) &&
                  this.util.isNullOrEmpty(value.fourDigits)) {
                    this.allFieldsEmpty();
                    this.phoneNumberControl.setValue(null);
                    this.phoneNumberControl.updateValueAndValidity();
                    this.clearValidators(this.phoneGroup);
                    this.parentForm.get(this.parentFormGroupName).updateValueAndValidity();
            }
          }

        })
      );
    }
    this.initialFunctionRan = true;
  }

  ngAfterContentInit() {
    this.phoneGroup.updateValueAndValidity();
  }
  ngOnDestroy(){
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      if (this.util.isNullOrEmpty(this.prefilledNumber)){
        this.phoneGroup.reset();
      }
      else {
        this.value = this.phoneGroup.value;
      }
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.phoneGroup.valid ? null : { profile: { valid: false } };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.initialFunctionRan) {
      if (!this.util.isNullOrEmpty(changes.required) && this.util.isNullOrEmpty(this.prefilledNumber)) {
        this.required = changes.required.currentValue;
      }
      if (!this.util.isNullOrEmpty(changes.attemptedSubmission)) {
        this.attemptedSubmission = changes.attemptedSubmission?.currentValue;
      }

      if (!this.prefilledNumber){
        this.phoneGroup?.get('areaCode').setValidators(this.buildValidators(3));
        this.phoneGroup?.get('threeDigits').setValidators(this.buildValidators(3));
        this.phoneGroup?.get('fourDigits').setValidators(this.buildValidators(4));
        if (this.attemptedSubmission) {
          if (this.areaCodeControl.touched || this.threeDigitsControl.touched ||
            this.fourDigitsControl.touched) {
            this.markControlsAsTouched()
          }
          this.setErrorsForFields();
        }
      }
    }
  }

  prefilledNumberSubscription(): void {
    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.phoneGroup.valueChanges
        .subscribe(value => {
        this.onChange(value);
        this.onTouched();
        if (!this.util.isNullOrEmpty(value.threeDigits) &&
            !this.util.isNullOrEmpty(value.areaCode) &&
            !this.util.isNullOrEmpty(value.fourDigits)){
              this.tempPhoneNumber = this.buildPhoneNumber(value.areaCode, value.threeDigits, value.fourDigits);
              if (this.phoneNumberControl.value != this.tempPhoneNumber){
                this.phoneNumberControl.setValue(this.buildPhoneNumber(this.areaCodeControl.value, this.threeDigitsControl.value, this.fourDigitsControl.value));
              }
        }
      })
    );
  }

  buildPhoneFormGroup(): UntypedFormGroup {
    let group = this.fb.group({
      areaCode: this.buildPhoneControlTemplate(3, this.areaCode),
      threeDigits: this.buildPhoneControlTemplate(3, this.threeDigits),
      fourDigits: this.buildPhoneControlTemplate(4, this.fourDigits),
      phoneNumber: [this.prefilledNumber],
    });

    if (this.util.isNullOrEmpty(this.prefilledNumber) && this.required) {
      group.setValidators(this.formValidatorService.numberFieldsValidation('areaCode', 'threeDigits', 'fourDigits'))
    }
    else {
      this.clearValidators(group);
    }
    return group;
  }

  clearValidators(group: UntypedFormGroup): void{
    for (const key in group.controls){
      group.get(key).clearValidators();
      group.get(key).updateValueAndValidity();
    }
    group.clearValidators();
    group.updateValueAndValidity();
  }

  buildPhoneControlTemplate(minLength: number, value: number) {
    return this.util.isNullOrEmpty(this.prefilledNumber)
           ? [value, {validators: this.buildValidators(minLength), updateOn: "blur"}]
           : [value, {validators: this.buildValidators(minLength)}]
  }

  buildValidators(minLength: number, required?: boolean) {
    let req = this.util.isNullOrEmpty(required) ? this.required : required;
    return this.required
      ? [Validators.required, this.formValidatorService.numberFieldValidator(), Validators.minLength(minLength)]
      : [this.formValidatorService.numberFieldValidator(), Validators.minLength(minLength)];
  }

  controlHighlightHelper(controlName: string): boolean{
    //Maybe try to use this in showErrorMessageHelper funciton
    //to help with issue when the all the inputs are empty after they were filled.
    return this.errorService.showErrorMessage(this.phoneGroup, controlName) &&
      this.phoneGroup.get(controlName).value
  }

  showErrorMessageHelper(): boolean{
    return (this.errorService.showErrorMessage(this.phoneGroup, 'areaCode') ||
      this.errorService.showErrorMessage(this.phoneGroup, 'threeDigits') ||
      this.errorService.showErrorMessage(this.phoneGroup, 'fourDigits'))
  }

  allFieldsEmpty() : boolean {
    let emptyFields = this.util.isNullOrEmpty(this.areaCodeControl.value) &&
    this.util.isNullOrEmpty(this.threeDigitsControl.value) &&
    this.util.isNullOrEmpty(this.fourDigitsControl.value);

    if (emptyFields){
      this.areaCodeControl.reset();
      this.threeDigitsControl.reset();
      this.fourDigitsControl.reset();
    }
    return emptyFields
  }

  requiredMessageHelper(): boolean {
    return this.areaCodeControl.errors?.required ||
           this.threeDigitsControl.errors?.required ||
           this.fourDigitsControl.errors?.required
  }

  markFieldError(control: AbstractControl): void {
    if (!control?.valid){
      if (!this.util.isNullOrEmpty(control?.value)) {
        control.setErrors(['invalid']);
      }
      if (this.util.isNullOrEmpty(control.value)) {
        control.setErrors(['required']);
      }
    }
  }

  setErrorsForFields(): void {
    this.markFieldError(this.areaCodeControl);
    this.markFieldError(this.threeDigitsControl);
    this.markFieldError(this.fourDigitsControl);
  }

  filloutPrefilledValue(phoneNumber: string) {
    let strippedNumber = phoneNumber.replace(/-/g, "");
    this.areaCode = Number(strippedNumber.substring(0, 3));
    this.threeDigits = Number(strippedNumber.substring(3, 6));
    this.fourDigits = Number(strippedNumber.substring(6));

    this.prefilledDisplayLabel = this.setDisplayLabel(strippedNumber);
  }

  setDisplayLabel(strippedNumber: string): string {
    return this.hidePhoneNumber
      ? this.hidePhoneDigits()
      : this.util.addHypensToPhoneNumber(strippedNumber);
  }

  hidePhoneDigits(): string {
    return `*** - *** - ${this.fourDigits}`;
  }

  markControlsAsTouched(){

    this.areaCodeControl.markAllAsTouched();
    this.areaCodeControl.updateValueAndValidity();
    this.threeDigitsControl.markAllAsTouched();
    this.threeDigitsControl.updateValueAndValidity();
    this.fourDigitsControl.markAllAsTouched();
    this.fourDigitsControl.updateValueAndValidity();
  }

  buildPhoneNumber(areaCode: number, threeDigits: number, fourDigits: number): string {
    return `${areaCode}${threeDigits}${fourDigits}`; //areaCode + threeDigits + fourDigits;
  }

  errorField(fieldName: string): boolean {
    return this.errorService.showErrorMessage(this.phoneGroup, fieldName);
  }

  onInputEntry(event, id, nextInput) {
    //If we need to implement this function for autotabbing we add it to the html forms in the
    //following pattern:
    //(input)="onInputEntry($event, ((parentId)), 'nextFieldId')"
    //Ex: (input)="onInputEntry($event, ((parentId)), 'fourDigitsId')"
    let input = event.target;
    let length = input.value.length;
    let maxLength = input.attributes.maxlength.value;
    let newId = id + nextInput;

    if(length >= maxLength){
      document.getElementById(newId).focus();
    }
  }
}
