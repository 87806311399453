import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VoeService {

    constructor() { }

    getAliasedLabel(labelOverrideFor, aliasLabel): string {
        return aliasLabel == undefined || aliasLabel.value == "employee id"
          ? "Employee ID"
          : aliasLabel.value;
      }
}