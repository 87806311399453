<ng-container [formGroup]="phoneGroup" *ngIf="!prefilledNumber">
  <div fxLayout="row">
    <mat-form-field class="form-three-digit" >
      <input
        matInput
        type="text"
        autocomplete="off"
        formControlName="areaCode"
        id="{{ parentId }}areaCodeId"
        minlength="3"
        maxlength="3"
        [attr.aria-label]="areaCodestr"
        [ngClass]="{
          'is-invalid': errorField('areaCode') && !allFieldsEmpty()
        }"
      />
    </mat-form-field>
    <div><div class="form-text-endashes">-</div></div>
    <mat-form-field class="form-three-digit" >
      <input
        matInput
        type="text"
        autocomplete="off"
        formControlName="threeDigits"
        id="{{ parentId }}threeDigitsId"
        minlength="3"
        maxlength="3"
        [attr.aria-label]="threeDigitstr"
        [ngClass]="{
          'is-invalid': errorField('threeDigits') && !allFieldsEmpty()
        }"
      />
    </mat-form-field>
    <div><div class="form-text-endashes">-</div></div>
    <mat-form-field class="form-four-digit" >
      <input
        matInput
        type="text"
        autocomplete="off"
        formControlName="fourDigits"
        id="{{ parentId }}fourDigitsId"
        minlength="4"
        maxlength="4"
        [attr.aria-label]="foureDigitstr"
        [ngClass]="{
          'is-invalid': errorField('fourDigits') && !allFieldsEmpty()
        }"
      />
    </mat-form-field>
  </div>
  <div *ngIf="showErrorMessageHelper() || requiredMessageHelper()">
    <span
      class="error-message"
      *ngIf="phoneGroup.errors?.number && !requiredMessageHelper()"
    >
      Please enter numbers only.
    </span>
    <span
      class="error-message"
      *ngIf="!phoneGroup.errors?.number && !requiredMessageHelper()"
    >
      Please enter a valid phone number.
    </span>
    <span class="error-message" *ngIf="requiredMessageHelper()">
      Response is required.
    </span>
  </div>
</ng-container>
<div fxLayout="row" [formGroup]="phoneGroup" *ngIf="prefilledNumber">
  <label>{{ prefilledDisplayLabel }}</label>
</div>
