import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../settings/global.service';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  isNull(value: any): boolean {
    return ( value === undefined || value === null)
  }

  isNullOrEmpty(value: any): boolean {
    return this.isNull(value) ? true : value.toString().length == 0;
  }

  addHypensToPhoneNumber(phone: string| number): string {
    if (this.isNullOrEmpty(phone))
      return phone?.toString();

    let originalPhone = phone.toString();
    return originalPhone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }

  determineCaptchaEndpoint(): string {
      return environment.UseLocalCaptcha
        ? 'http://localhost:5000/simple-captcha-endpoint.ashx'
        : `${GlobalService.API_BASE_URL}/simple-captcha-endpoint.ashx`
  }
}
