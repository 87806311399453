<div id="aboutModal" class="about-header">
<app-popup-header class="" [headerTitle]="title" (CloseTabOut)="abtPopupTabOut($event)"></app-popup-header>

<mat-dialog-content class="mySedwick-popupContent">
  <p class="mat-caption">
    To access mySedgwick, you will need a recent version of Google Chrome, Microsoft Edge, Mozilla Firefox, or Safari.
    JavaScript must be enabled in the browser for the site to function correctly. To enable your browser for JavaScript, please consult your browser documentation.
    <br aria-hidden="true" />
    <br aria-hidden="true" />
    All rights reserved. No part of this information may be reproduced in any form
    by any electronic or mechanical means including information storage and retrieval systems without the written permission of Sedgwick Claims Management Services, Inc.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end" class="mySedwick-popupFooter">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial mat-raised-button color="primary" id="abtOkbtn">
    <mat-icon aria-hidden="true" aria-label="Ok button">check</mat-icon>
    OK
  </button>
</mat-dialog-actions>
</div>
