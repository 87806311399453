
<div id="footer" class="voe-footer" role="contentinfo">
  <div class="footer-container">
    <div fxLayout="row wrap" fxlayoutAlign="center" style="justify-content: center;">
       <div>
        <img
          src="assets/Images/SDWK_CC_logo.png"
          alt="Sedgwick Logo Caring Counts"
        />
      </div>
      <div>
        <div class="footer-content">
          <app-footerlinks [isLogout]=false></app-footerlinks>
          <app-buildinfo ></app-buildinfo>
      </div>

    </div>
  </div>
</div>
</div>
