import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { stat } from 'fs';
import { CookieService } from 'ngx-cookie-service';
import { ClientService } from 'src/app/service/api/client.service';
import { LoginService } from 'src/app/service/api/login.service';
import { ContentService } from 'src/app/service/content.service';
import { SsoRedirect, SsoRequest } from 'src/app/shared/models/ssorequest';
import { ToastOptions } from 'src/app/shared/models/toast-options.enum';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss']
})
export class SsoComponent implements OnInit {

  ssoMessage: string;
  ssoRedirect: SsoRedirect;
  notLive: boolean;
  toastEnumOptions = ToastOptions;

  constructor(
    private loginService: LoginService,
    private redirectService: RedirectService,
    private activatedRoute: ActivatedRoute,
    public utility: UtilityService
  ) { }

  ngOnInit(): void {
    let status = this.getSsoStatus();
    if(status.toLowerCase() == 'success'){
      this.ssoMessage = '';//I dont think we want to show a message'We are redirecting you to mySedgwick.'
      this.loginService.getSSORedirectCookieInformation().subscribe(data => {
        this.redirectService.redirectToMySedgwick(new SsoRequest(
          {
            MySedgwickUrl: data.postUrl,
            Token: data.token,
            SourceUrl: data.sourceUrl,
          }));
      }, error => {
      })
    }
    else
      this.ssoMessage = this.setSsoError(status);
  }

  getSsoStatus(): string{
    let status = this.activatedRoute.snapshot.paramMap.get('status');
    return status;
  }

  setSsoError(status: string): string {
    //Currently the status is being passed in as actual string in Enum, not number,
    // So instead of 304 AssertionNotFound is being passed in.
    switch(status){
      case '301':
        return "We're sorry, we were unable to authenticate your SSO login credentials.";
      case '302':
        return 'Object reference not set to an instance of an object. Please validate the issuerID.';
      case '303':
        return 'The system cannot find the specified certificate for this issuer.';
      case '304':
        return 'Assertion not found';
      case '305':
        return "SAML response failed verification (digital signature doesn't match).";
      case '306':
        return 'SAML is not valid.';
      case '307':
        return "SAML assertion failed verification after unencryption (digital signature doesn't match).";
      case '308':
        return 'Pseudonym cannot be null.';
      case '310':
        this.notLive = true;
        return `Thank you for accessing mySedgwick.</b><br /><br />
            Your company has not yet been moved over to the new mySedgwick experience.
            Please contact your company's Human Resources department if you feel this is in error.<br /><br />
            Also, Sedgwick's Technical Support is available to answer questions at 1-866-647-7610,
            Monday through Friday 6:00am to 7:00pm Central Time.`;
      case '311':
        return 'SAML is not signed.';
      case '312':
        return 'SAML is no longer valid.';
      case '313':
        return 'Certificate not found';
      default:
        return 'An unknown error occurred during the single sign on process.';
    }
  }
}
