import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss'],
})
export class AboutDialogComponent implements OnInit {
  title: string = "About Us";
  constructor() { }

  ngOnInit(): void {

  }

  abtPopupTabOut(event){
    document.getElementById("abtOkbtn").focus();
  }
}
