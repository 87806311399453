import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CaptchaComponent } from 'angular-captcha';
import { UserService } from 'src/app/service/user.service';
import { UnlockUserRequest } from 'src/app/shared/models/request';
import { ToastOptions } from 'src/app/shared/models/toast-options.enum';
import { UnlockUserResult } from 'src/app/shared/models/UnlockUserResult';
import { SecurtyAnswerStatus, UserStatus, ValidateUserRequest } from 'src/app/shared/models/ValidatedUser';
import { ErrorMessageLogicService } from 'src/app/shared/services/error-message-logic.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { GlobalService } from 'src/app/shared/settings/global.service';

@Component({
  selector: 'app-unlock-account',
  templateUrl: './unlock-account.component.html',
  styleUrls: ['./unlock-account.component.scss']
})
export class UnlockAccountComponent implements OnInit {

  @ViewChild(CaptchaComponent, { static: true }) captchaComponent: CaptchaComponent;

  username: string;
  unlockToastMessage: string = 'Your account has been locked. Please answer your security questions and complete the CAPTCHA to unlock your account.'
  errorToastMessage: string = 'The system could not validate your answers.'
  showErrorToast: boolean;
  toastEnumOptions = ToastOptions;
  unlockAccountForm: UntypedFormGroup;
  validateUserRequest: ValidateUserRequest;
  unlockRequest: UnlockUserRequest;
  submitPressed: boolean;
  captchaErrorMessage: string;
  resetQuestions: boolean;
  animationsDisabled = true;

  constructor(private userService: UserService,
              private fb: UntypedFormBuilder,
              private errorService: ErrorMessageLogicService,
              private router: Router,
              private utility: UtilityService) { }

  get captcha() {
    return this.unlockAccountForm.get('captcha') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.captchaComponent.captchaEndpoint = this.utility.determineCaptchaEndpoint();
    this.username = this.userService.username;
    this.unlockAccountForm = this.buildForm();
  }

  buildForm(): UntypedFormGroup {
    return this.fb.group({
      securityQuestions: [''],
      captcha: ['']
    })
  }

  showErrorField(formControl: string): boolean {
    return this.errorService.showErrorMessage(this.unlockAccountForm, formControl);
  }

  buildSecurityQuestionRequest(): ValidateUserRequest {
    // get the user-entered captcha code value to be validated at the backend side
    let userEnteredCaptchaCode = this.captchaComponent.userEnteredCaptchaCode;

    // get the id of a captcha instance that the user tried to solve
    let captchaId = this.captchaComponent.captchaId;
    return  new ValidateUserRequest({
      captchaId: captchaId,
      userEnteredCaptchaCode: userEnteredCaptchaCode,
      userName: this.username,
      email: ''
    });
  }

  onCancel(): void {
    this.router.navigate(['/']);
  }

  buildUnlockUserRequest() : UnlockUserRequest {
    // get the user-entered captcha code value to be validated at the backend side
    let userEnteredCaptchaCode = this.captchaComponent.userEnteredCaptchaCode;
    // get the id of a captcha instance that the user tried to solve
    let captchaId = this.captchaComponent.captchaId;
    return new UnlockUserRequest ({
      captchaId:captchaId ,
      userEnteredCaptchaCode:userEnteredCaptchaCode,
      verifySecurityAnswersRequest: this.unlockAccountForm.get('securityQuestions').value
    });
  }

  handleResetQuestions(): void {
    this.resetQuestions = !this.resetQuestions;
  }

  handleSecurityResponse(result: UnlockUserResult): void {
    let answerResponse = result.verificationResponse.answerStatus;

    switch(answerResponse){
      case SecurtyAnswerStatus.Success:
        this.userService.accountUnlocked = true;
        this.onCancel();
        break;
      case SecurtyAnswerStatus.Failed:
        this.showErrorToast = true;
        this.handleResetQuestions();
        this.captchaComponent.reloadImage();
        break;
      case SecurtyAnswerStatus.DisabledFailure:
        this.userService.accountDisabled = true;
        this.onCancel();
        break;
    }

  }

  setInvalidCaptcha(): void{
      this.captcha.setErrors({ incorrect: true});
      this.captcha.markAsDirty();
      this.captchaComponent.reloadImage();
      this.captchaErrorMessage = 'Incorrect';
  }

  onSubmit(): void {
    this.submitPressed = true;

    if (this.utility.isNullOrEmpty(this.captcha.value)) {
          this.captcha.setErrors({ Required: true});
          this.captcha.markAsDirty();
          this.captchaComponent.reloadImage();
          this.captchaErrorMessage = 'You must enter the Captcha';

          return;
    }

    this.unlockRequest = this.buildUnlockUserRequest();

    this.userService.unlockUserAccount(this.unlockRequest).subscribe(
      (result) => {
        if (result.userStatus == UserStatus.BadCaptCha){
          this.setInvalidCaptcha();
        }
        else {
          this.handleSecurityResponse(result);
        }
      },
      (e) => {
        // Currently returning BadResponse from API if invalid captcha, do we need something
        // better to differentiate between server errors and invalid captchas?
        this.setInvalidCaptcha();
      })
  }
}
