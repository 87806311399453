<div *ngIf="pageReady" class="changePasswordContent">
  <h1  role="none">Change Password</h1>
  <div *ngIf="!showSuccessToast" class="changePassword-container">
    <div fxLayout="column">
      <p *ngIf="passwordExpired">
        <toast
          [message]="passwordExpiredToast"
          [type]="toastEnumOptions.Error"
        ></toast>
      </p>
      <app-pwinstructions></app-pwinstructions>
      <div fxLayout="row" fxLayoutAlign="end center" class="top10">
        <strong>*Required</strong>
      </div>
    </div>

    <form
      role="none"
      fxLayout="column"
      [formGroup]="changePasswordGroup"
      (ngSubmit)="submitPasswordChange()"
    >
       <app-password [showPassword] ="showPasswordCheck.value"
          [newPasswordText] = "passwordText"
          [retypePasswordText] ="confirmPasswordText"
          [parentForm]="changePasswordGroup"
          [parentFormGroupName]="'passwordGroup'"
          formGroupName="passwordGroup">
        </app-password>
      <ul role="none" class="flex-outer">
        <div dir="rtl">
          <mat-checkbox
            color="primary"
            labelPosition="before"
            formControlName="showPasswordCheck"
            aria-label="Show Passwords"
          ></mat-checkbox>
          <span> Show Passwords </span>
        </div>
        <!-- <app-registration-securityquestions (onChangeShowHide)="showHideAnswers($event)"
          [regSecurityQuesionsForm]="changePasswordGroup">
        </app-registration-securityquestions> -->
        <app-registration-securityquestions *ngIf="showSecurityQuestions"
          [regSecurityQuesionsForm]="changePasswordGroup"
          [hideAnswerLabel]="showHideSecurityQuestionLabel">
        </app-registration-securityquestions>
        <div dir="ltr" class="float-right" fxLayoutGap="20px">
          <button
            type="button"
            mat-raised-button
            color="whiteButton"
            (click)="onCancel()"
            aria-label="Cancel">
            <mat-icon aria-hidden="true">block</mat-icon>
            Cancel
          </button>

         <!--- <span role="button" class="sr-only"
            aria-label="Save"
            tabindex="changePasswordGroup.valid ? '-1' : '0'"
            [attr.title]="changePasswordGroup.valid ? '' : 'disabled until mandatory fields are missing'">
          </span>-->
          <button
            type="submit"
            mat-raised-button
            color="primary"
            class="width-90"
            aria-label="Save">
            <mat-icon aria-hidden="false">done</mat-icon>
            Save
          </button>
        </div>
      </ul>
    </form>
    <div fxLayout="column" *ngIf="apiError">
      <p>
        <toast
          [message]="passwordApiError"
          [type]="toastEnumOptions.UserError"
        ></toast>
      </p>
    </div>
  </div>
  <div *ngIf="showSuccessToast" class="changePassword-container">
    <toast [message]="successToast" [type]="toastEnumOptions.Success"> </toast>
  </div>
</div>
