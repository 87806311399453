import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../shared/settings/global.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AppConfigService {
  constructor(private http: HttpClient) {}

  load(): Promise<any> {
    return this.http.get<any>(`/api/Client/xsrf`)
    .toPromise()
    .then((data) => {
        Object.assign(this, data);
        return data;
      });
  }
}

