import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss']
})
export class BlankComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $("#SpinnerDisplay>div").attr("role","presentation")
  }

}
