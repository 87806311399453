import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, ControlContainer } from '@angular/forms';
import { ViewEncapsulation } from '@angular/core';
import { cloneDeep } from 'lodash';
import { UtilityService } from 'src/app/shared/services/utility.service';


@Component({
  selector: 'app-registration-securityquestions',
  templateUrl: './registration-securityquestions.component.html',
  styleUrls: ['./registration-securityquestions.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  animations :[]//if needed trigger conditions here
})
export class RegistrationSecurityquestionsComponent implements OnInit {
  //regSecurityQuesionsForm: FormGroup;
  animationsDisabled = true;
  hide: boolean = true;
  displayLabel: string = 'Show Password';

  @Input() regSecurityQuesionsForm : UntypedFormGroup;
  @Input() hideAnswerLabel: string;
  @Output() private onChangeShowHide = new EventEmitter<any>();

  securityQuestions: { Value: number, Text: string }[] = [
    {
      "Value": 0,
      "Text": "What is your favorite dessert?"
    },
    {
      "Value": 1,
      "Text": "What is your favorite sports team?"
    },
    {
      "Value": 2,
      "Text": "What is your favorite food to eat?"
    },
    {
      "Value": 3,
      "Text": "What is your dream job?"
    },
    {
      "Value": 4,
      "Text": "In what year was your mother born?"
    },
    {
      "Value": 5,
      "Text": "What is the first and last name of your first boyfriend or girlfriend?"
    },
    {
      "Value": 6,
      "Text": "Which phone number do you remember most from your childhood?"
    },
    {
      "Value": 7,
      "Text": "What was your favorite place to visit as a child?"
    },
    {
      "Value": 8,
      "Text": "Who is your favorite actor?"
    },
    {
      "Value": 9,
      "Text": "What is the name of your favorite pet?"
    },
    {
      "Value": 10,
      "Text": "In what city were you born?"
    },
    {
      "Value": 11,
      "Text": "What high school did you attend?"
    },
    {
      "Value": 12,
      "Text": "What elementary school did you attend?"
    },
    {
      "Value": 13,
      "Text": "What is your favorite movie?"
    },
    {
      "Value": 14,
      "Text": "What is your mother's maiden name?"
    },
    {
      "Value": 15,
      "Text": "What street did you grow up on?"
    },
    {
      "Value": 16,
      "Text": "What was the make and model of your first car?"
    },
    {
      "Value": 17,
      "Text": "When is your anniversary?"
    },
    {
      "Value": 18,
      "Text": "What is your favorite color?"
    },
    {
      "Value": 19,
      "Text": "What is your father's middle name?"
    },
    {
      "Value": 20,
      "Text": "What is the name of your first grade teacher?"
    },
    {
      "Value": 21,
      "Text": "What was your high school mascot?"
    },
    {
      "Value": 22,
      "Text": "Who was your favorite teacher?"
    },
    {
      "Value": 23,
      "Text": "In what city did you meet your spouse/significant other?"
    },
    {
      "Value": 24,
      "Text": "What is the name of your best friend from childhood?"
    },
    {
      "Value": 25,
      "Text": "What street did you live on in third grade?"
    },
    {
      "Value": 26,
      "Text": "What is your youngest sibling's birthday month and year?"
    },
    {
      "Value": 27,
      "Text": "What is the middle name of your youngest child?"
    },
    {
      "Value": 28,
      "Text": "What is your youngest sibling's middle name?"
    },
    {
      "Value": 29,
      "Text": "What school did you attend for sixth grade?"
    },
    {
      "Value": 30,
      "Text": "What is your oldest cousin's name?"
    },
    {
      "Value": 31,
      "Text": "What was the name of your first stuffed animal?"
    },
    {
      "Value": 32,
      "Text": "In what city or town did your mother and father meet?"
    },
    {
      "Value": 33,
      "Text": "Who is your favorite musician?"
    },
    {
      "Value": 34,
      "Text": "What was the last name of your first grade teacher?"
    },
    {
      "Value": 35,
      "Text": "In what city does your nearest sibling live?"
    },
    {
      "Value": 36,
      "Text": "What is your oldest sibling's birthday month and year?"
    },
    {
      "Value": 37,
      "Text": "What is your maternal grandmother's maiden name?"
    },
    {
      "Value": 38,
      "Text": "In what city or town was your first job?"
    },
    {
      "Value": 39,
      "Text": "Where was your wedding reception held?"
    },
    {
      "Value": 40,
      "Text": "What is the name of a college you applied to but didn't attend?"
    },
    {
      "Value": 41,
      "Text": "Where were you when you first heard about 9/11?"
    },
    {
      "Value": 42,
      "Text": "Who is your favorite super hero?"
    },
    {
      "Value": 43,
      "Text": "What was the first concert you attended?"
    },
    {
      "Value": 44,
      "Text": "What is the name of your favorite book?"
    }
  ];

  securityQuestions1 = cloneDeep(this.securityQuestions);
  securityQuestions2 = cloneDeep(this.securityQuestions);
  securityQuestions3 = cloneDeep(this.securityQuestions);
  securityQuestions4 = cloneDeep(this.securityQuestions);
  securityQuestions5 = cloneDeep(this.securityQuestions);

  constructor(
    private fb: UntypedFormBuilder,
    private utility: UtilityService) { }

  ngOnInit() {
    this.createSecurityQuesForm();
    if (!this.utility.isNullOrEmpty(this.hideAnswerLabel))
      this.displayLabel = this.hideAnswerLabel;
  }

  createSecurityQuesForm(){
    //This component should be its own form group, it should not be addeding all these individual controls to the
    //parent form group.
    this.regSecurityQuesionsForm.addControl ("securityQuestion1",new UntypedFormControl ('',
    [Validators.required]));
    this.regSecurityQuesionsForm.addControl ("securityQuestion2",new UntypedFormControl ('',
    [Validators.required]));
    this.regSecurityQuesionsForm.addControl ("securityQuestion3",new UntypedFormControl ('',
    [Validators.required]));
    this.regSecurityQuesionsForm.addControl ("securityQuestion4",new UntypedFormControl ('',
    [Validators.required]));
    this.regSecurityQuesionsForm.addControl ("securityQuestion5",new UntypedFormControl ('',
    [Validators.required]));

    this.regSecurityQuesionsForm.addControl ("securityAnswer1",new UntypedFormControl ('',
    [
      Validators.required,
      Validators.pattern("^[a-zA-Z0-9_ $@$#!%^+=&]*$"),
      Validators.minLength(4),
      this.checkDuplicateAnswer1.bind(this)
    ]));
    this.regSecurityQuesionsForm.addControl ("securityAnswer2",new UntypedFormControl ('',
    [
      Validators.required,
      Validators.pattern("^[a-zA-Z0-9_ $@$#!%^+=&]*$"),
      Validators.minLength(4),
      this.checkDuplicateAnswer2.bind(this)
    ]));
    this.regSecurityQuesionsForm.addControl ("securityAnswer3",new UntypedFormControl ('',
    [
      Validators.required,
      Validators.pattern("^[a-zA-Z0-9_ $@$#!%^+=&]*$"),
      Validators.minLength(4),
      this.checkDuplicateAnswer3.bind(this)
    ]));
    this.regSecurityQuesionsForm.addControl ("securityAnswer4",new UntypedFormControl ('',
    [
      Validators.required,
      Validators.pattern("^[a-zA-Z0-9_ $@$#!%^+=&]*$"),
      Validators.minLength(4),
      this.checkDuplicateAnswer4.bind(this)
    ]));
    this.regSecurityQuesionsForm.addControl ("securityAnswer5",new UntypedFormControl ('',
    [
      Validators.required,
      Validators.pattern("^[a-zA-Z0-9_ $@$#!%^+=&]*$"),
      Validators.minLength(4),
      this.checkDuplicateAnswer5.bind(this)
    ]));

  }

  updateAvailableQuestions(e) {
    this.securityQuestions1 = this.securityQuestions.filter(q =>
      q.Text !== e.source._parentFormGroup.form.get('securityQuestion2').value
      && q.Text !== e.source._parentFormGroup.form.get('securityQuestion3').value
      && q.Text !== e.source._parentFormGroup.form.get('securityQuestion4').value
      && q.Text !== e.source._parentFormGroup.form.get('securityQuestion5').value);
    this.securityQuestions2 = this.securityQuestions.filter(q =>
      q.Text !== e.source._parentFormGroup.form.get('securityQuestion1').value
      && q.Text !== e.source._parentFormGroup.form.get('securityQuestion3').value
      && q.Text !== e.source._parentFormGroup.form.get('securityQuestion4').value
      && q.Text !== e.source._parentFormGroup.form.get('securityQuestion5').value);
    this.securityQuestions3 = this.securityQuestions.filter(q =>
      q.Text !== e.source._parentFormGroup.form.get('securityQuestion1').value
      && q.Text !== e.source._parentFormGroup.form.get('securityQuestion2').value
      && q.Text !== e.source._parentFormGroup.form.get('securityQuestion4').value
      && q.Text !== e.source._parentFormGroup.form.get('securityQuestion5').value);
    this.securityQuestions4 = this.securityQuestions.filter(q =>
      q.Text !== e.source._parentFormGroup.form.get('securityQuestion1').value
      && q.Text !== e.source._parentFormGroup.form.get('securityQuestion3').value
      && q.Text !== e.source._parentFormGroup.form.get('securityQuestion2').value
      && q.Text !== e.source._parentFormGroup.form.get('securityQuestion5').value);
    this.securityQuestions5 = this.securityQuestions.filter(q =>
      q.Text !== e.source._parentFormGroup.form.get('securityQuestion1').value
      && q.Text !== e.source._parentFormGroup.form.get('securityQuestion3').value
      && q.Text !== e.source._parentFormGroup.form.get('securityQuestion4').value
      && q.Text !== e.source._parentFormGroup.form.get('securityQuestion2').value);
  }

  checkDuplicateAnswer1(fc: UntypedFormControl): Validators {
    if (fc.value && (fc.value === this.regSecurityQuesionsForm.get('securityAnswer2').value || fc.value === this.regSecurityQuesionsForm.get('securityAnswer3').value || fc.value === this.regSecurityQuesionsForm.get('securityAnswer4').value
      || fc.value === this.regSecurityQuesionsForm.get('securityAnswer5').value)) {
      return { duplicateAnswer: true }
    }
    else {
      this.checkAllOtherAnswers(1);
    }
  }

  checkDuplicateAnswer2(fc: UntypedFormControl): Validators {
    if (fc.value && (fc.value === this.regSecurityQuesionsForm.get('securityAnswer1').value || fc.value === this.regSecurityQuesionsForm.get('securityAnswer3').value || fc.value === this.regSecurityQuesionsForm.get('securityAnswer4').value
      || fc.value === this.regSecurityQuesionsForm.get('securityAnswer5').value)) {
      return { duplicateAnswer: true }
    }
    else {
      this.checkAllOtherAnswers(2);
    }
  }

  checkDuplicateAnswer3(fc: UntypedFormControl): Validators {
    if (fc.value && (fc.value === this.regSecurityQuesionsForm.get('securityAnswer2').value || fc.value === this.regSecurityQuesionsForm.get('securityAnswer1').value || fc.value === this.regSecurityQuesionsForm.get('securityAnswer4').value
      || fc.value === this.regSecurityQuesionsForm.get('securityAnswer5').value)) {
      return { duplicateAnswer: true }
    }
    else {
      this.checkAllOtherAnswers(3);
    }
  }

  checkDuplicateAnswer4(fc: UntypedFormControl): Validators {
    if (fc.value && (fc.value === this.regSecurityQuesionsForm.get('securityAnswer2').value || fc.value === this.regSecurityQuesionsForm.get('securityAnswer3').value || fc.value === this.regSecurityQuesionsForm.get('securityAnswer1').value
      || fc.value === this.regSecurityQuesionsForm.get('securityAnswer5').value)) {
      return { duplicateAnswer: true }
    }
    else {
      this.checkAllOtherAnswers(4);
    }
  }

  checkDuplicateAnswer5(fc: UntypedFormControl): Validators {
    if (fc.value && (fc.value === this.regSecurityQuesionsForm.get('securityAnswer2').value || fc.value === this.regSecurityQuesionsForm.get('securityAnswer3').value || fc.value === this.regSecurityQuesionsForm.get('securityAnswer4').value
      || fc.value === this.regSecurityQuesionsForm.get('securityAnswer1').value)) {
      return { duplicateAnswer: true }
    }
    else {
      this.checkAllOtherAnswers(5);
    }
  }

  showHideAnswers(): void {
    this.hide = this.hide ? false : true;
    this.onChangeShowHide.emit(this.hide);
  }

  getSecurityAnswer(answerNumber : number){
    return this.regSecurityQuesionsForm.get(`securityAnswer${answerNumber}`);
  }

  allAnswersSupplied(): boolean{
    return !(this.utility.isNullOrEmpty(this.getSecurityAnswer(1)?.value) || this.utility.isNullOrEmpty(this.getSecurityAnswer(2)?.value)
      || this.utility.isNullOrEmpty(this.getSecurityAnswer(3)?.value) || this.utility.isNullOrEmpty(this.getSecurityAnswer(4)?.value)
      || this.utility.isNullOrEmpty(this.getSecurityAnswer(5)?.value));
  }

  checkAllOtherAnswers(currentQuestion: number) : void {
    if (this.allAnswersSupplied()) {
      for (let i = 1; i < 6; i++){
        if (i === currentQuestion)
          continue;

        let secAnswer = this.getSecurityAnswer(i);
        if (secAnswer.hasError('duplicateAnswer'))
          secAnswer?.updateValueAndValidity();
      }
    }
  }

}
