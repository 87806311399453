<div id="regSecurityQuesionsForm">
    <form role="none" fxLayout="column" class="" [formGroup]="regSecurityQuesionsForm" autocomplete="off">
        <ul class="flex-outer" role="none">
            <div class="mt-20 mb-20">
                <label>For security purposes we are asking you to select a series of
                    challenge questions.</label><br aria-hidden="true" />
                <label>They will be used to provide you with access if you forget your
                    password.</label>
            </div>

            <li role="none">
                <label for="securityQuestion1">Security Question: *</label>
                <mat-form-field [@.disabled]="animationsDisabled" >
                    <mat-select formControlName="securityQuestion1" id="securityQuestion1"
                        aria-label="Security question 1 of 5" required
                        (selectionChange)="updateAvailableQuestions($event)">
                        <mat-option *ngFor="let question of securityQuestions1" [value]="question.Text">
                            {{question.Text}}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="regSecurityQuesionsForm.get('securityQuestion1').invalid &&
            regSecurityQuesionsForm.get('securityQuestion1').errors &&
        (regSecurityQuesionsForm.get('securityQuestion1').dirty || regSecurityQuesionsForm.get('securityQuestion1').touched)">
                        <mat-error class="error-message"
                            *ngIf="regSecurityQuesionsForm.get('securityQuestion1').hasError('required')">
                            <span style="background:white;padding:5px">
                            Security question 1 of 5 is required.</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </li>
            <li role="none">
                <label for="securityAnswer1">Enter your answer: *</label>
                <mat-form-field [@.disabled]="animationsDisabled">
                    <input matInput class="input" formControlName="securityAnswer1" name="securityAnswer1" required
                        id="securityAnswer1" autocomplete="new-password" aria-label="Security answer 1 of 5" [type]="hide ? 'password' : 'text'" />
                    <mat-error
                        *ngIf="regSecurityQuesionsForm.get('securityAnswer1').invalid &&
            regSecurityQuesionsForm.get('securityAnswer1').errors &&
          (regSecurityQuesionsForm.get('securityAnswer1').dirty || regSecurityQuesionsForm.get('securityAnswer1').touched)">
                        <mat-error class="error-message"
                            *ngIf="regSecurityQuesionsForm.get('securityAnswer1').hasError('required')">
                            <span style="background:white;padding:5px">
                            Four or more characters are required.</span>
                        </mat-error>
                        <mat-error class="error-message" *ngIf="regSecurityQuesionsForm.get('securityAnswer1').hasError('pattern')">
                            <span style="background:white;padding:5px">
                            Please enter a valid Answer.</span>
                        </mat-error>
                        <mat-error class="error-message"
                            *ngIf="regSecurityQuesionsForm.get('securityAnswer1').hasError('duplicateAnswer')">
                            <span style="background:white;padding:5px">
                            Please enter unique answers.</span>
                        </mat-error>
                        <mat-error class="error-message"
                            *ngIf="regSecurityQuesionsForm.get('securityAnswer1').hasError('minlength')">
                            <span style="background:white;padding:5px">
                            Four or more characters are required.</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </li>
            <mat-divider class="mat-divider"></mat-divider>
            <li role="none">
                <label for="securityQuestion2">Security Question: *</label>
                <mat-form-field [@.disabled]="animationsDisabled">
                    <mat-select formControlName="securityQuestion2" id="securityQuestion2"
                        aria-label="Security question 2 of 5" required
                        (selectionChange)="updateAvailableQuestions($event)">
                        <mat-option *ngFor="let question of securityQuestions2" [value]="question.Text">
                            {{question.Text}}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="regSecurityQuesionsForm.get('securityQuestion2').invalid &&
            regSecurityQuesionsForm.get('securityQuestion2').errors &&
      (regSecurityQuesionsForm.get('securityQuestion2').dirty || regSecurityQuesionsForm.get('securityQuestion2').touched)">
                        <mat-error class="error-message"
                            *ngIf="regSecurityQuesionsForm.get('securityQuestion2').hasError('required')">
                            <span style="background:white;padding:5px">
                            Security question 2 of 5 is required.</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </li>
            <li role="none">
                <label for="securityAnswer2">Enter your answer: *</label>
                <mat-form-field [@.disabled]="animationsDisabled">
                    <input matInput class="input" formControlName="securityAnswer2" name="securityAnswer2" required
                        id="securityAnswer2" autocomplete="new-password" aria-label="Security answer 2 of 5" [type]="hide ? 'password' : 'text'" />
                    <mat-error
                        *ngIf="regSecurityQuesionsForm.get('securityAnswer2').invalid &&
            regSecurityQuesionsForm.get('securityAnswer2').errors &&
        (regSecurityQuesionsForm.get('securityAnswer2').dirty || regSecurityQuesionsForm.get('securityAnswer2').touched)">
                        <mat-error class="error-message"
                            *ngIf="regSecurityQuesionsForm.get('securityAnswer2').hasError('required')">
                            <span style="background:white;padding:5px">
                            Four or more characters are required.</span>
                        </mat-error>
                        <mat-error class="error-message" *ngIf="regSecurityQuesionsForm.get('securityAnswer2').hasError('pattern')">
                            <span style="background:white;padding:5px">
                            Please enter a valid Answer.</span>
                        </mat-error>
                        <mat-error class="error-message"
                            *ngIf="regSecurityQuesionsForm.get('securityAnswer2').hasError('duplicateAnswer')">
                            <span style="background:white;padding:5px">
                            Please enter unique answers.</span>
                        </mat-error>
                        <mat-error class="error-message"
                            *ngIf="regSecurityQuesionsForm.get('securityAnswer2').hasError('minlength')">
                            <span style="background:white;padding:5px">
                            Four or more characters are required.</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </li>
            <mat-divider class="mat-divider"></mat-divider>
            <li role="none">
                <label for="securityQuestion3">Security Question: *</label>
                <mat-form-field>
                    <mat-select class="" formControlName="securityQuestion3" id="securityQuestion3"
                        aria-label="Security question 3 of 5" required
                        (selectionChange)="updateAvailableQuestions($event)">
                        <mat-option *ngFor="let question of securityQuestions3" [value]="question.Text">
                            {{question.Text}}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="regSecurityQuesionsForm.get('securityQuestion3').invalid &&
            regSecurityQuesionsForm.get('securityQuestion3').errors &&
      (regSecurityQuesionsForm.get('securityQuestion3').dirty || regSecurityQuesionsForm.get('securityQuestion3').touched)">
                        <mat-error class="error-message"
                            *ngIf="regSecurityQuesionsForm.get('securityQuestion3').hasError('required')">
                            <span style="background:white;padding:5px">
                            Security question 3 of 5 is required.</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </li>
            <li role="none">
                <label for="securityAnswer3">Enter your answer: *</label>
                <mat-form-field [@.disabled]="animationsDisabled">
                    <input matInput class="input" formControlName="securityAnswer3" name="securityAnswer3" required
                        id="securityAnswer3" autocomplete="new-password" aria-label="Security answer 3 of 5" [type]="hide ? 'password' : 'text'" />
                    <mat-error
                        *ngIf="regSecurityQuesionsForm.get('securityAnswer3').invalid &&
            regSecurityQuesionsForm.get('securityAnswer3').errors &&
        (regSecurityQuesionsForm.get('securityAnswer3').dirty || regSecurityQuesionsForm.get('securityAnswer3').touched)">
                        <mat-error class="error-message"
                            *ngIf="regSecurityQuesionsForm.get('securityAnswer3').hasError('required')">
                            <span style="background:white;padding:5px">
                            Four or more characters are required.</span>
                        </mat-error>
                        <mat-error class="error-message" *ngIf="regSecurityQuesionsForm.get('securityAnswer3').hasError('pattern')">
                            <span style="background:white;padding:5px">
                            Please enter a valid Answer.</span>
                        </mat-error>
                        <mat-error class="error-message"
                            *ngIf="regSecurityQuesionsForm.get('securityAnswer3').hasError('duplicateAnswer')">
                            <span style="background:white;padding:5px">
                            Please enter unique answers.</span>
                        </mat-error>
                        <mat-error class="error-message"
                            *ngIf="regSecurityQuesionsForm.get('securityAnswer3').hasError('minlength')">
                            <span style="background:white;padding:5px">
                            Four or more characters are required.</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </li>
            <mat-divider class="mat-divider"></mat-divider>
            <li role="none">
                <label for="securityQuestion4">Security Question: *</label>
                <mat-form-field [@.disabled]="animationsDisabled">
                    <mat-select class="" formControlName="securityQuestion4" id="securityQuestion4"
                        aria-label="Security question 4 of 5" required
                        (selectionChange)="updateAvailableQuestions($event)">
                        <mat-option *ngFor="let question of securityQuestions4" [value]="question.Text">
                            {{question.Text}}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="regSecurityQuesionsForm.get('securityQuestion4').invalid &&
            regSecurityQuesionsForm.get('securityQuestion4').errors &&
      (regSecurityQuesionsForm.get('securityQuestion4').dirty || regSecurityQuesionsForm.get('securityQuestion4').touched)">
                        <mat-error class="error-message"
                            *ngIf="regSecurityQuesionsForm.get('securityQuestion4').hasError('required')">
                            <span style="background:white;padding:5px">
                            Security question 4 of 5 is required.</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </li>
            <li role="none">
                <label for="securityAnswer4">Enter your answer: *</label>
                <mat-form-field [@.disabled]="animationsDisabled">
                    <input matInput class="input" formControlName="securityAnswer4" name="securityAnswer4" required
                    id="securityAnswer4" autocomplete="new-password" aria-label="Security answer 4 of 5" [type]="hide ? 'password' : 'text'" />
                    <mat-error
                        *ngIf="regSecurityQuesionsForm.get('securityAnswer4').invalid &&
            regSecurityQuesionsForm.get('securityAnswer4').errors &&
        (regSecurityQuesionsForm.get('securityAnswer4').dirty || regSecurityQuesionsForm.get('securityAnswer4').touched)">
                        <mat-error class="error-message"
                            *ngIf="regSecurityQuesionsForm.get('securityAnswer4').hasError('required')">
                            <span style="background:white;padding:5px">
                            Four or more characters are required.</span>
                        </mat-error>
                        <mat-error class="error-message" *ngIf="regSecurityQuesionsForm.get('securityAnswer4').hasError('pattern')">
                            <span style="background:white;padding:5px">
                            Please enter a valid Answer.</span>
                        </mat-error>
                        <mat-error class="error-message"
                            *ngIf="regSecurityQuesionsForm.get('securityAnswer4').hasError('duplicateAnswer')">
                            <span style="background:white;padding:5px">
                            Please enter unique answers.</span>
                        </mat-error>
                        <mat-error class="error-message"
                            *ngIf="regSecurityQuesionsForm.get('securityAnswer4').hasError('minlength')">
                            <span style="background:white;padding:5px">
                            Four or more characters are required.</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </li>
            <mat-divider class="mat-divider"></mat-divider>
            <li role="none">
                <label for="securityQuestion5">Security Question: *</label>
                <mat-form-field [@.disabled]="animationsDisabled">
                    <mat-select class="" formControlName="securityQuestion5" id="securityQuestion5"
                        aria-label="Security question 5 of 5" required
                        (selectionChange)="updateAvailableQuestions($event)">
                        <mat-option *ngFor="let question of securityQuestions5" [value]="question.Text">
                            {{question.Text}}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="regSecurityQuesionsForm.get('securityQuestion5').invalid &&
            regSecurityQuesionsForm.get('securityQuestion5').errors &&
      (regSecurityQuesionsForm.get('securityQuestion5').dirty || regSecurityQuesionsForm.get('securityQuestion5').touched)">
                        <mat-error class="error-message"
                            *ngIf="regSecurityQuesionsForm.get('securityQuestion5').hasError('required')">
                            <span style="background:white;padding:5px">
                            Security question 5 of 5 is required.</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </li>
            <li role="none">
                <label for="securityAnswer5">Enter your answer: *</label>
                <mat-form-field [@.disabled]="animationsDisabled">
                    <input matInput class="input" formControlName="securityAnswer5" name="securityAnswer5" required
                    id="securityAnswer5" autocomplete="new-password" aria-label="Security answer 5 of 5" [type]="hide ? 'password' : 'text'" />
                    <mat-error
                        *ngIf="regSecurityQuesionsForm.get('securityAnswer5').invalid &&
            regSecurityQuesionsForm.get('securityAnswer5').errors &&
        (regSecurityQuesionsForm.get('securityAnswer5').dirty || regSecurityQuesionsForm.get('securityAnswer5').touched)">
                        <mat-error class="error-message"
                            *ngIf="regSecurityQuesionsForm.get('securityAnswer5').hasError('required')">
                            <span style="background:white;padding:5px">
                            Four or more characters are required.</span>
                        </mat-error>
                        <mat-error class="error-message" *ngIf="regSecurityQuesionsForm.get('securityAnswer5').hasError('pattern')">
                            <span style="background:white;padding:5px">
                            Please enter a valid Answer.</span>
                        </mat-error>
                        <mat-error class="error-message"
                            *ngIf="regSecurityQuesionsForm.get('securityAnswer5').hasError('duplicateAnswer')">
                            <span style="background:white;padding:5px">
                            Please enter unique answers.</span>
                        </mat-error>
                        <mat-error class="error-message"
                            *ngIf="regSecurityQuesionsForm.get('securityAnswer5').hasError('minlength')">
                            <span style="background:white;padding:5px">
                            Four or more characters are required.</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </li>
        </ul>

        <ul role="none" class="secAnswers-checkbox" >
        <label id="chkShowPassword">{{displayLabel}}</label>    <mat-checkbox aria-labelledby="chkShowPassword"  labelPosition="before" color="primary" (change)="showHideAnswers()"></mat-checkbox>
        </ul>
    </form>
</div>
