import { LoginResponse } from "./loginrequest";
import { UserContext } from "./UserContext";
import { ValidateUserRequest } from "./ValidatedUser";

export class EmailLinkRequest {
    userName: string;
    emailAddress: string;
    mobileNo: string;
    verifyType: string;
    firstName: string;
    lastName: string;
    delieverBy: string;
    userid: string;
    isChangePasswordRequest: boolean;
    constructor(values: EmailLinkRequest) {
        Object.assign(this, values);
      }
}

export class VerifySecurityAnswersRequest {
  username: string;
  question: string;
  question2: string;
  question3: string;
  answer: string;
  answer2: string;
  answer3: string;
  constructor(values: VerifySecurityAnswersRequest) {
    Object.assign(this, values);
  }
}
export class ForgotPasswordQuestionRequest {
  username: string;
  question: string;
  question2: string;
  question3: string;
  answer: string;
  answer2: string;
  answer3: string;
  newPassword: string;
  constructor(values: ForgotPasswordQuestionRequest)  {
    Object.assign(this, values);
  }
}
export class GetSecurityQuestionRequest {
  userName: string;
  secretKey: string;
  constructor(values: GetSecurityQuestionRequest) {
    Object.assign(this, values);
  }
}

export class UnlockUserRequest {
  captchaId: string;
  userEnteredCaptchaCode: string;
  verifySecurityAnswersRequest: VerifySecurityAnswersRequest;
  constructor(values: UnlockUserRequest){
    Object.assign(this, values);
  }


}

export class VerificationCodeRequest {
  UserContext: UserContext;
  constructor(values: VerificationCodeRequest){
    Object.assign(this, values);
  }
}

export class ValidateVerificationCodeRequest {
  verificationCode: string;
  userName: string;
  setTFADevice: boolean;
  twoFactorDeviceId: string;
  verifyType: string;
  authenticationDays: number;
  UserContext: UserContext;
  constructor(values: ValidateVerificationCodeRequest){
    Object.assign(this, values);
  }
}

export class ValidateVerificationCode {
  status: string;
  loginResponse: LoginResponse;
  TwoFactorDeviceId: string;
  constructor(values: ValidateVerificationCode){
    Object.assign(this, values);
  }

}
