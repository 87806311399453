import { ErrorHandler, Injectable } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LoggingRequest } from 'src/app/shared/models/Log';
import { UserService } from '../user.service';
import { LoggingService } from 'src/app/service/api/logging.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

  //Not sure if we will have better way to read this or not yet
  severity: string = 'Error';
  logRequest: LoggingRequest;

  constructor(private router: Router,
              private userService: UserService,
              private loggingService: LoggingService) { }

  handleError(error: any): void {
    let sessionId = this.userService.sessionid;
    this.logRequest = new LoggingRequest({
      message: error.message,
      //sessionId: sessionId,
      severity: this.severity
    });
    // console.log("Hit TD");
    // this.router.navigate(['technicaldifficulties'], { queryParams: {}});

    this.loggingService.log(this.logRequest).subscribe(
      (result) => {
        console.log("Hit TD");
        this.router.navigate(['technicaldifficulties'], { queryParams: {}});
      }
    );

  }
}
