import { Injectable } from "@angular/core";
import { AbstractService } from "./abstract.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";

/**
 * Service with HTTP methods
 */
@Injectable({ providedIn: "root" })
export class ClientService extends AbstractService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  getVanityDetails(vanityPath: string): Observable<any>
  {
    //Should make this pass in a VanityRequest Object to match API.
    let data = {vanityUrl: vanityPath};
    return super.postData<any>("/Client/ClientContent",data);

  }

  getAppVersion(): Observable<any>
  {
    return super.getData<any>("/Client/AppVersion");
  }
}
