import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AboutDialogComponent } from 'src/app/core/components/about-dialog/about-dialog.component';
import { SupportDialogComponent } from 'src/app/core/components/support-dialog/support-dialog.component';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash-es';
@Component({
  selector: 'app-footerlinks',
  templateUrl: './footerlinks.component.html',
  styleUrls: ['./footerlinks.component.scss']
})
export class FooterlinksComponent implements OnInit {
  @Input() isLogout: boolean;
  AllowLoutURL: boolean;
  helpFile: string;
  dialogRef: any;
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.AllowLoutURL = this.isLogout;
    this.helpFile = environment.helpFile;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AboutDialogComponent, { disableClose: true });
    dialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
        this.dialog.closeAll();
      }
    })
  }

  openSupportDialog(): void {
    const dialogRef = this.dialog.open(SupportDialogComponent, { disableClose: true });
    dialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
        this.dialog.closeAll();
      }
    })
  }
}
