<div id="contactSupportModal">
  <app-popup-header [headerTitle]="title" (CloseTabOut)="SprtPopupTabOut($event)"></app-popup-header>

  <mat-dialog-content class="mySedwick-popupContent">
    <br aria-hidden="true" />
    <span class="right10">
      Live Chat help is available from 8:00 a.m. until 5:00 p.m. central time, Monday through Friday.
      <!--<span id=chatclick >Click the Live Chat link below</span> style='display: none'  -->
      <span id="displayLocation" fxLayout="" fxFlexAlign="left" style="line-height:25px;display:block;width: inherit;"></span>
    </span>
    <br aria-hidden="true" />
    <br aria-hidden="true" />
    <br aria-hidden="true" />
    <!-- <mat-expansion-panel [expanded]="panelOpenState">

      <mat-expansion-panel-header>
          <mat-panel-title>
              TITLE
          </mat-panel-title>
      </mat-expansion-panel-header>

      <p><label
          tabindex="0"
        >
          <br><br>
          <b>Sedgwick Technical Support is available from 8:00 a.m. to 7:00 p.m., central time, Monday through Friday.</b>
        </label>
        <br><br>
        <span> Toll-free Number: </span>  <span > (866) 647-7610 </span>
        <br>
        <span> Toll Number: </span> <span > (901) 415-7800 </span></p>
  </mat-expansion-panel> -->

    <div fxFlexAlign="space-around" class="margin-5" fxLayout="column" id="moreoptionparent" >
      <div tabindex="0"  fxLayout="row" role="button" class="expandCollapseHover" id="spnmoresupportoption"
            (keyup.enter)="collapseExpand(nextAction)"
            (click)="collapseExpand(nextAction)"
            [attr.aria-expanded]="!doCollapse">
        <mat-icon aria-hidden="false"
                  class="mat-primary">
          {{ termsIcon }}
        </mat-icon>
        More support options
      </div>
      <label id='expandSection' [ngClass]="{
      'expand-collapse': !doCollapse,
      'expand-collapse-hidden': doCollapse
                    }">
        <span class="right10">
          <br aria-hidden="true" /><br aria-hidden="true" />
          Sedgwick Technical Support is available from 8:00 a.m. to 7:00 p.m., central time, Monday through Friday.
        </span>
        <br aria-hidden="true"><br aria-hidden="true" />
        <span aria-label="Toll free Number 8666477610 ."> Toll-free Number : (866) 647-7610 </span>
        <br aria-hidden="true" />
        <span aria-label="Toll Number 9014157800"> Toll Number : (901) 415-7800 </span>
      </label>
    </div>

    <br aria-hidden="true" /><br aria-hidden="true" />
  </mat-dialog-content>

<mat-dialog-actions align="end"  class="mySedwick-popupFooter">
  <button mat-button [mat-dialog-close]="true" aria-label="ok"  mat-raised-button color="primary" (click)="doClose()">
    <mat-icon aria-hidden="true" aria-label="Check icon">check</mat-icon>
    OK</button>
</mat-dialog-actions>
</div>
