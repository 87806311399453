import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() useLoggedInImage: boolean;

  logoSrc: string;
  loginImage: string
  loggedInImage: string;
  logoalt:string;
  constructor(private router: Router) { }

  ngOnInit(): void {
    const clientData = JSON.parse(sessionStorage.getItem('clientData'));

    this.loginImage = this.useLoggedInImage ? clientData.loggedinImage : clientData.loginImage;

    if(clientData.vanityPath != null)
    {
      this.logoalt = clientData.vanityPath+' Logo';
    }
    else{
      this.logoalt = 'mySedgwick Logo';
    }

  }

  onLogoClick() : void{
    this.router.navigate(['/']);
  }
}
