import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormValidatorsService {

  constructor() { }


  matchingFields(initialField: string, confirmField: string): ValidatorFn {
    return (control: AbstractControl): {[key: string]: boolean} | null =>{
      const initialFieldControl = control.get(initialField);
      const confirmFieldControl = control.get(confirmField);

      if (initialFieldControl?.pristine || confirmFieldControl?.pristine){
        return null;
      }

      if (initialFieldControl?.value === confirmFieldControl?.value){
        return null;
      }

      return {match: true};
    }
  }

  //Note the ... notation converts x fields to a string array.
  numberFieldsValidation(...fields: string[]) :ValidatorFn {
    return (control: AbstractControl): {[key: string]: boolean} | null =>{
      let isNotNumber = false;
      for (let field of fields){
        isNotNumber = !/^\d+$/.test(control.get(field)?.value);
        if(isNotNumber) break;
      }

      return !isNotNumber
        ? null
        : {number: true};
    }
  }

  numberFieldValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: boolean} | null => {
      return !/^\d+$/.test(control?.value)
        ? {number: true}
        : null;
    }
  }
}
