import { Injectable } from '@angular/core';
import { LoginService } from './api/login.service';
import { TwoFactorService } from './api/twofactor.service';
import { PreferenceService } from './api/preference.service';
import { User } from '../shared/models/user';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { CONST } from '../shared/settings';
import { LoginRequest, LoginResponse, LoginStatus } from '../shared/models/loginrequest';
import { ValidateUserRequest, ValidatedUser, ValidateEmailRequest } from '../shared/models/ValidatedUser';
import { AuthenticateSamlRequest, AuthenticateSamlTfaRequest, SsoRegistration, SsoRegistrationResult, SsoRequest } from "../shared/models/ssorequest";
import { ChangePasswordRequest, ValidateChangePasswordLinkResponse, ValidateChangePasswordRequest } from '../shared/models/ChangePasswordRequest';
import { EmailLinkRequest, VerifySecurityAnswersRequest, GetSecurityQuestionRequest, ForgotPasswordQuestionRequest, VerificationCodeRequest, UnlockUserRequest, ValidateVerificationCodeRequest, ValidateVerificationCode} from '../shared/models/request';
import { UnlockUserResult } from '../shared/models/UnlockUserResult';
import { MessageModel } from '../shared/models/MessageModel';
import { UserContext } from '../shared/models/UserContext';
import { SsoRegistrationRequest } from '../shared/models/registration';
import { EncryptService } from '../shared/services/encrypt.service';

/**
 * This service is used for:
 * -login
 * -logout
 * - holds information about logged user.
 */
@Injectable({
  providedIn: 'root',
})
export class UserService {
  user = new BehaviorSubject<User>(null);
  snackBar: any;
  emailAddress: string;
  phoneNumber: string;
  username: string;
  passwordChanged: boolean;
  passwordExpired: boolean;
  accountUnlocked: boolean;
  accountDisabled: boolean;
  securityQuestionUpdated: boolean;
  sessionid: string;
  redirectToken: string;
  forgotPasswordToken: string;
  mySedgwickUrl: string;
  loginSubStatus: LoginStatus;
  UserContext: UserContext;
  ssoRegistration: boolean;
  sourceUrl: string;
  ssoRegistrationRequest: SsoRegistrationRequest;
  hashKey: string;
  hashIv: string;

  constructor(private loginService: LoginService,
              private router: Router,
              private preferenceService: PreferenceService,
              private twoFactorService: TwoFactorService,
              private encrytor: EncryptService) { }

  /**
   * Check that user was logged.
   * If jest, check that session exist.
   */
  init(): void {
    const user = this.getData();
    if (user) {
      this.user.next(user);
      this.goToApplication();
    }
  }

  //some of these functions could probably be moved to a different service

  /**
   * Method to login user by API.
   * @param request
   */
  login(request: LoginRequest): Observable<LoginResponse> {
    request.Password = this.encrytor.encryptUsingAES256(request.Password);
    return this.loginService.login(request);
  }
  sso(request: SsoRequest): Observable<any> {
    return this.loginService.ssoToMySedgwick(request);
  }
  validateUser(request: ValidateUserRequest): Observable<ValidatedUser> {
    return this.loginService.validateUser(request);
  }
  validateEmail(request: ValidateEmailRequest): Observable<ValidatedUser> {
    return this.loginService.validateEmail(request);
  }
  sendEmailLink(request: EmailLinkRequest): Observable<any> {
    return this.loginService.sendEmailLink(request);
  }
  getSecurityQuestions(request: GetSecurityQuestionRequest): Observable<any> {
    return this.loginService.getSecurityQuestions(request);
  }
  verifySecurityAnswers(request: VerifySecurityAnswersRequest): Observable<any> {
    return this.loginService.verifySecurityAnswers(request);
  }

  validateChangePasswordLink( request: ValidateChangePasswordRequest): Observable<ValidateChangePasswordLinkResponse>{
    return this.loginService.validateChangePasswordLink(request);
  }
  securityAnswersPasswordChange(request: ForgotPasswordQuestionRequest): Observable<any> {
    return this.loginService.securityAnswersPasswordChange(request);
  }

  changePassword(request: ChangePasswordRequest):  Observable<boolean> {
    return this.preferenceService.ChangePassword(request);
  }

  unlockUserAccount(request: UnlockUserRequest): Observable<UnlockUserResult> {
    return this.loginService.unlockUserAccount(request);
  }

  sendVerificationCode(request: VerificationCodeRequest): Observable<boolean>{
    return this.twoFactorService.sendVerificationCode(request);
  }

  validateVerificationCode(request: ValidateVerificationCodeRequest): Observable<ValidateVerificationCode>{
    return this.twoFactorService.validateVerificationCode(request);
  }

  sendTwoFactorMessage(request: VerificationCodeRequest): Observable<MessageModel>{
    return this.twoFactorService.sendTwoFactorMessage(request);
  }

  ssoRegister(): Observable<SsoRegistrationResult>{
    return this.loginService.ssoRegistration();
  }

  authenticateSsoUserForTfa(request: AuthenticateSamlTfaRequest): Observable<LoginResponse>{
    return this.loginService.authenticateSsoUserForTfa(request);
  }

  createSsoTwoFactorRequest(request: SsoRegistration): Observable<boolean> {
    return this.loginService.createSsoTwoFactorRequest(request);
  }

  changePasswordQuestions(request: ChangePasswordRequest): Observable<boolean>{
    request.newPassword = this.encrytor.encryptUsingAES256(request.newPassword);
    return this.loginService.changePasswordQuestions(request);
  }

  // getHashInfo(): Observable<any>{
  //   return this.loginService.getHashInfo();
  // }

  /**
   * Logout user and redirect to LoginPanel
   */
  logout(): void {
    sessionStorage.removeItem('user');
    this.goToLoginPanel();
  }

  /**
   * Return actually logged user
   */
  getUser(): Observable<User> {
    return this.user.asObservable();
  }

  /**
   * Get user from local storage
   */
  public getData(): User {
    const user = sessionStorage.getItem('user');
    return user === null ? null : JSON.parse(user);
  }

  private goToApplication(): void {
    this.router.navigate(['/app']);
  }
  private goToLoginPanel(): void {
    this.router.navigate(['/login']);
  }
}
