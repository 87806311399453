<ng-container *ngIf="sessionStorageSet">
  <div id="ApplicationTemplate" class="applicationcontainer">
    <div id="GlobalContainer">
      <div id="HeaderContainer" class="header-container" role="banner">
        <div id="header" class="bg-white">
          <div class="manager-dashboard-header-alignment">
            <div id="mainMenu" class="h-container">
              <app-header class="h-container"
                [useLoggedInImage]="true"
              ></app-header>
              <div>
                <hr>
              </div>
            </div>
          <!-- <mat-divider></mat-divider> -->
          </div>
        </div>
      </div>

      <div id="PageContainer" role="main">
        <div id="MainContainer" class="container main-container">
          <div class="dash-layout-landing">
            <div class="dash-layout-landing-body">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>

      <div class="manager-dashboard-header-alignment" fxLayout="row" fxFlexAlign="center">
        <app-footer class="h-container"></app-footer>
      </div>
    </div>

  </div>
</ng-container>
