import { Injectable } from '@angular/core';
import { SsoRequest } from '../models/ssorequest';
import { UntypedFormBuilder } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SpinnerComponent } from 'src/app/component/spinner/spinner.component';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  constructor(private formBuilder: UntypedFormBuilder, private httpClient: HttpClient, private spinnerComponent: SpinnerComponent) { }

  redirectToMySedgwick(request: SsoRequest) : void {
    let redirectString = request.ClientType == "5.0" ? `api/Login/redirectToMySedgwick5` : `api/Login/redirectToMySedgwick`
    this.httpClient.get(redirectString,
    {
      headers: new HttpHeaders().set("Authorization", `Bearer ${request.Token}`),
      responseType: 'text'
    }).toPromise().then(
       data => {
        this.spinnerComponent.spinnerAction(true);
        let form = document.createElement("form");
        form.action = request.MySedgwickUrl;
        form.innerHTML = data.toString();
        form.method = "post";
        form.style.display = "none";
        if ((navigator.userAgent.indexOf("Chrome") !== -1 || navigator.userAgent.indexOf("MSIE ") !== -1 || navigator.userAgent.indexOf("Edge") !== -1 || navigator.userAgent.indexOf("Trident") !== -1)) { }
        else {
            form.removeAttribute('target');
        }
        document.body.appendChild(form);
        form.submit();
       }
     );
  }

  // redirectToMySedgwick(request: SsoRequest) : void {
  //   this.httpClient.get(`api/Login/redirectToMySedgwick/${request.UserName}/${request.SessionId}`,
  //   {
  //     //headers: new HttpHeaders().set("Authorization", `Bearer ${request.Token}`),
  //     responseType: 'text'
  //   }).toPromise().then(
  //      data => {
  //       let form = document.createElement("form");
  //       form.action = request.MySedgwickUrl;
  //       form.innerHTML = data.toString();
  //       form.method = "post";
  //       form.style.display = "none";
  //       if ((navigator.userAgent.indexOf("Chrome") !== -1 || navigator.userAgent.indexOf("MSIE ") !== -1 || navigator.userAgent.indexOf("Edge") !== -1 || navigator.userAgent.indexOf("Trident") !== -1)) { }
  //       else {
  //           form.removeAttribute('target');
  //       }
  //       document.body.appendChild(form);
  //       form.submit();
  //      }
  //    );
  // }
}
