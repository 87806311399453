import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginLayoutComponent } from './pages/login-layout/login-layout.component';
import { MainLayoutComponent } from './pages/main-layout/main-layout.component';
import { AboutDialogComponent } from './components/about-dialog/about-dialog.component';
import { SupportDialogComponent } from './components/support-dialog/support-dialog.component';
import { MatToolbarModule} from '@angular/material/toolbar';
import { ToastComponent } from './components/toast/toast.component';
import { PhoneComponent } from './components/phone/phone.component';
import { PwinstructionsComponent } from './components/pwinstructions/pwinstructions.component';
import { PopupHeaderComponent } from './components/popup-header/popup-header.component';
import { DashLayoutComponent } from './pages/dash-layout/dash-layout.component';
import { PasswordComponent } from './components/password/password.component';
import {MatInputModule} from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';



@NgModule({
  declarations: [
    HeaderComponent,
    ToastComponent,
    PhoneComponent,
    PwinstructionsComponent,
    PasswordComponent,
    //PopupHeaderComponent,
    //FooterComponent, , LoginLayoutComponent, MainLayoutComponent, AboutDialogComponent, SupportDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [
    HeaderComponent,
    ToastComponent,
    PhoneComponent,
    PwinstructionsComponent,
    PasswordComponent
  ]
})
export class CoreModule { }
