<ng-container *ngIf="sessionStorageSet">
  <div class="content" fxLayout="column" >
   <div role="banner"> <img *ngIf="loginImage"
      src="data:image/JPEG;base64,{{loginImage}}"
      alt="{{logoalt}}"
    /></div>
    <div fxFlexOffset="20px" class="login-container" fxLayout="column" fxFlexAlign.sm="center" role="main">
      <form
        role="none"
        class="login-form"
        fxLayout="column"
        [formGroup]="loginForm"
        (ngSubmit)="onSubmit()"
      >
        <!-- <mat-label class="login-info" tabindex="0"
          >This id dummy text for the info that can appear regarding access to the
          site via screenreader, and other info.
        </mat-label> -->
        <ng-container *ngIf="!passwordChanged && !accountUnlocked">
          <p *ngIf="showInvalidLoginToast && !showDisabledLoginToast">
            <toast
              [message]="loginToastErrorMessage"
              [type]="toastEnumOptions.UserError"

              isToastFocus="true"
            ></toast>
          </p>
          <p
            *ngIf="!showInvalidLoginToast && showDisabledLoginToast"
          >
            <toast [message]="loginToastErrorMessage"
              [type]="toastEnumOptions.UserError"
            ></toast>
          </p>
        </ng-container>
        <p *ngIf="passwordChanged">
          <toast
            [message]="passwordChangeToast"
            [type]="toastEnumOptions.Error"
          ></toast>
        </p>
        <p *ngIf="accountUnlocked">
          <toast
            [message]="unlockAccountToast"
            [type]="toastEnumOptions.Success"
          ></toast>
        </p>
        <p *ngIf="isLogOut == 'Y' || isLogOut == 'S'">
          <toast
            [message]="logOutToast"
            [type]="toastEnumOptions.Success"
          ></toast>
        </p>
        <h1 style="font-size: 0px;line-height: 0px!important;" tabindex="-1">Sedgwick.com Login Home</h1>
        <ng-container *ngIf="registrationMethod != 'NONE' &&  isLogOut != 'S'" >
          <ul class="flex-outer" role="none">
            <li role="none">
              <label fxFlex="100%">USERNAME</label>
            </li>
            <li role="none">
              <mat-form-field fxFlex="100%" role="none" tabindex="-1">
                <input
                  required
                  role="textbox"
                  aria-required="true"
                  [attr.aria-label] = "userNameAriaLabel"
                  class="input"
                  matInput
                  #userNameFocus
                  type="text"
                  formControlName="userName"
                  name="userName"
                  autocomplete="off"
                  [maxlength]="maxLength"
                />
                <mat-error class="error-message"   role="none" *ngIf="(loginForm.get('userName').errors || ((loginForm.get('userName').dirty || loginForm.get('userName').touched))) && (loginForm.get('userName').hasError('required'))">
                   <span style="background:white;padding:5px"> User Name is required.</span>
                </mat-error>
              </mat-form-field>
            </li>

            <li role="none">
              <label fxFlex="100%">PASSWORD</label>
            </li>
            <li role="none">
              <mat-form-field fxFlex="100%" role="none" tabindex="-1">
                <input
                  required
                  role="textbox"
                  aria-required="true"
                  [attr.aria-label] = "passwordAriaLabel"
                  matInput
                  #passwordFocus
                  class="input"
                  type="password"
                  formControlName="password"
                  name="password"
                  autocomplete="new-password"
                  [maxlength]="maxLength"
                />
                <mat-error class="error-message"  role="none"  *ngIf="(loginForm.get('password').errors || ((loginForm.get('password').dirty || loginForm.get('password').touched))) && (loginForm.get('password').hasError('required'))">
                   <span style="background:white;padding:5px"> Password is required.</span>
                </mat-error>
              </mat-form-field>

            </li>

          </ul>

          <div fxFlexAlign="space-around" fxLayout="column" fxLayoutGap="15px">
            <div fxFlexAlign="space-around"  fxLayout="row">
              <mat-icon
                aria-hidden="false"
                class="login-icon"
                color="primary"
                aria-label="New User"
                >account_box</mat-icon
              >
              <a href="" routerLink="/register">New User</a>
            </div>
            <div fxFlexAlign="space-around" fxLayout="row">
              <mat-icon
                aria-hidden="false"
                class="login-icon"
                color="primary"
                aria-label="Lock icon"
                >lock</mat-icon
              >
              <a href="" routerLink="/forgotPassword"
                >Forgot User Name or Password</a
              >
            </div>
            <div *ngIf="showReportClaim" fxFlexAlign="space-around" fxLayout="row">
              <mat-icon
                aria-hidden="false"
                class="login-icon"
                color="primary"
                aria-label="Claim icon"
                >assignment_turned_in</mat-icon
              >
              <a href="{{redirectUrl}}"
                >Report a Claim</a
              >
            </div>
          </div>
        </ng-container>


          <!-- <div fxFlexAlign="space-around" fxLayout="row">
            <mat-icon
              aria-hidden="false"
              class="login-icon"
              color="primary"
              aria-label="Lock icon"
              >launch</mat-icon
            >
            <a href="" routerLink="/forgotPassword"
              >Click here to go to Idp site</a
            >
          </div> -->

        <p *ngIf="showLoginToast && !utility.isNullOrEmpty(loginMessage)" class="login-message-toast">
          <toast
            [message]="loginMessage"
            [type]="toastEnumOptions.Info"
          ></toast>
        </p>
        <!-- <div fxFlexAlign="space-around" fxLayout="column" fxLayoutGap="15px">
          <p *ngIf="showLoginToast && !utility.isNullOrEmpty(loginMessage)">
            <toast
              [message]="loginMessage"
              [type]="toastEnumOptions.Success"
            ></toast>
          </p>
        </div> -->
        <div fxFlexAlign="space-around" fxFlexOffset="30px" fxLayout="column">
          <span tabindex="0"
            fxLayout="row"
            class="expandCollapseHover"
            (keyup.enter)="collapseExpand(nextAction)"
            (click)="collapseExpand(nextAction)"
            aria-label="Expandable Terms of Service"
            role="button"
            [attr.aria-expanded]="!doCollapse"
          >
            <mat-icon aria-hidden="true"
              class="mat-primary"
            >{{ termsIcon }}
            </mat-icon>
            Terms of Service
          </span>
          <label
            [ngClass]="{
              'login-info': !doCollapse,
              'login-info-hidden': doCollapse
            }"
          >
            Access to this information resource is restricted to authorized users
            only. This information resource may be monitored for administrative
            and security reasons. By proceeding, you consent to this monitoring.
            In order to protect the information entrusted to Sedgwick,
            unauthorized access and/or unauthorized use of this information
            resource may be prosecuted to the fullest extent of the law.
          </label>
        </div>

        <div fxFlexAlign="end" class="top-margin-30">
          <button *ngIf="registrationMethod != 'NONE'  &&  isLogOut != 'S'"
            type="submit"
            aria-label="Login"
            mat-raised-button
            color="primary"
          >
            <mat-icon aria-hidden="false" aria-label="Lock icon">lock</mat-icon>
            LOGIN
          </button>
          <button *ngIf="registrationMethod =='NONE' && !utility.isNullOrEmpty(redirectUrl)"
            type="button"
            aria-label="Login"
            mat-raised-button
            color="primary"
            (click)="redirectToUrl()"
          >
          <mat-icon aria-hidden="false" aria-label="Lock icon">lock</mat-icon>
            LOGIN
          </button>
        </div>
      </form>
    </div>
  </div>
  <div fxLayout="row" fxFlexAlign="center" class="login-footer">
    <app-footer></app-footer>
  </div>
</ng-container>
