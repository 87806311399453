import { Component, OnInit, Renderer2 } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { UserService } from 'src/app/service/user.service';

declare function doLiveChat(params): any;

@Component({
  selector: 'app-technical-difficulties',
  templateUrl: './technical-difficulties.component.html',
  styleUrls: ['./technical-difficulties.component.scss'],
  providers: [DatePipe]
})
export class TechnicalDifficultiesComponent implements OnInit {
  hasSessionId: boolean;
  sessionId: string;
  currentDate: string = new Date().toString();
  passVar: any;
  lhnHocButton:any;
  displayLocation: any;
  constructor(private userService: UserService, private datePipe: DatePipe, private renderer: Renderer2) {
    this.currentDate = this.datePipe.transform(this.currentDate, 'M/d/yyyy, h:mm:ss a',);
  }

  async ngOnInit(): Promise<void> {
    this.sessionId = this.userService.sessionid;
    if(this.sessionId != undefined)
      this.hasSessionId = true;

      this.passVar = {
      Flag: '',
      top:  '',
      left: window.innerWidth
    };

    doLiveChat(this.passVar);
  }
}
