<div>
  <ul id="bottomMenu"
      class="footerLink roboto20">
    <!--           <span
              ><a
                href="/"
                class="footerLink"
                aria-label="Home Page"
                role="link"
                >Home</a
              ></span
            ><span class="sr-only" aria-hidden="true">Link Home</span> | -->
    <li>
      <a [href]="helpFile"
         class="footerLink"
         aria-label="Opens Help Page in a new tab"
         role="link"
         target="_blank">Help</a>
    </li>
    <!-- <li class="sr-only" aria-hidden="true">Link Help</li>  -->
    <span aria-hidden="true">|</span>
    <li>
      <a 
         aria-label="Opens About popup"
         role="button"
         (click)="openDialog()"
         class="footerLink">About</a>
    </li>
    <!-- <span class="sr-only" aria-hidden="true">Link About</span>  -->
    <span aria-hidden="true">|</span>
    <!-- <span>
    <a
      href="javascript:void(0)"
      class="footerLink"
      aria-label="Contact us popup"
      role="link"
      >Contact Us</a
    > </span
  ><span class="sr-only">Link Provide Feedback</span> | -->

    <li>
      <a 
         class="footerLink"
         aria-label="Opens Contact Support popup"
         (click)="openSupportDialog()"
         role="button">Contact Support</a>
    </li>

    <!-- <span class="sr-only" aria-hidden="true">Link Contact Support</span>  -->
    <span aria-hidden="true">|</span>
    <li>
      <a href="https://www.sedgwick.com/global-privacy-policy"
         target="_blank"
         class="footerLink"
         aria-label="Opens privacy notice page in a new tab"
         role="link">
        Privacy Notice
      </a>
    </li>
    <span aria-hidden="true">|</span>
    <li>
      <a href="https://www.sedgwick.com/terms-conditions"
         target="_blank"
         class="footerLink"
         aria-label="Opens terms & conditions page in a new tab"
         role="link">
        Terms & Conditions
      </a>
    </li>
    <!-- <span class="sr-only" aria-hidden="true">Link Privacy Notice</span> -->
    <span *ngIf="AllowLoutURL">
      <span aria-hidden="true">|</span>
    </span>

    <li *ngIf="AllowLoutURL"><a  class="footerLink">Logout</a></li>
    <!-- <span class="sr-only" aria-hidden="true">Link Logout</span> -->
  </ul>
       
      </div>
