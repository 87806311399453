import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { UserService } from 'src/app/service/user.service';
import { GetSecurityQuestionRequest, VerifySecurityAnswersRequest } from '../../shared/models/request';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators, NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor } from '@angular/forms';
import { Router } from '@angular/router';
import { SecurtyAnswerStatus } from 'src/app/shared/models/ValidatedUser';

@Component({
  selector: 'app-security-questions',
  templateUrl: './security-questions.component.html',
  styleUrls: ['./security-questions.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SecurityQuestionsComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: SecurityQuestionsComponent,
      multi: true
    }
  ]
})
export class SecurityQuestionsComponent implements OnInit, ControlValueAccessor {
  @Input() username: string;
  @Input() resetQuestions: boolean;


  @Output() public getQuestionData = new EventEmitter<any>();

  onChange: any = (v) => { };
  onTouched: any = () => { };

  hideAnswers : boolean = true;
  questionsList: any;
  securityQuestionForm: UntypedFormGroup;
  constructor(private router: Router, private userService: UserService, private fb: UntypedFormBuilder) { }
  writeValue(value): void {
  }
  validate({ value }: UntypedFormControl) {

    return !this.securityQuestionForm.valid && {
      invalid: true
    }

  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
  }
  ngOnInit(): void {
    this.getSecurityQuestins();
  }


  ngOnChanges(changes: any) {
    if (changes.resetQuestions)
      this.getSecurityQuestins();
  }

  private getSecurityQuestins() {
    this.securityQuestionForm = this.fb.group({
      question1: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9_]*$")]],
      question2: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9_]*$")]],
      question3: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9_]*$")]],
    });
    var getSecuurityQuestReq = new GetSecurityQuestionRequest({
      userName: this.username,
      secretKey: ''
    });
    this.userService.getSecurityQuestions(getSecuurityQuestReq).subscribe(
      (result) => {
        this.questionsList = result;

      },
      (e) => {
      }
    );
  }

  onCancel(): void {
    this.router.navigate(['/']);
  }

  setAnswerValue() {
    this.questionsList[0].answer = this.securityQuestionForm.value.question1;
    this.questionsList[1].answer = this.securityQuestionForm.value.question2;
    this.questionsList[2].answer = this.securityQuestionForm.value.question3;

    let securityAnswerRequest = new VerifySecurityAnswersRequest({
      username: this.username,
      question: this.questionsList[0].question,
      question2: this.questionsList[1].question,
      question3: this.questionsList[2].question,
      answer: this.questionsList[0].answer,
      answer2: this.questionsList[1].answer,
      answer3: this.questionsList[2].answer,

    });
    this.onChange(securityAnswerRequest);
    this.onTouched();
  }

  showHideSecAnswers(): void {
    this.hideAnswers = this.hideAnswers ? false : true;
  }

}
