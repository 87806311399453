import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { RegistrationService } from './api/registration.service';
import { ValidateClaimResult } from '../shared/models/ValidateClaimResult';
import { Registration, RegistrationExport } from '../shared/models/registration';
import { SsoRegistration } from '../shared/models/ssorequest';

@Injectable({
  providedIn: 'root',
})
export class UserRegistrationService {
  constructor(private registrationService: RegistrationService, private router: Router) { }


  /**
   * Method to login user by API.
   * @param login
   * @param password
   */
  ValidateClaimNumber(claimNumber: string): Observable<ValidateClaimResult> {
    return this.registrationService.ValidateClaimNumber(claimNumber);
  }

  ProcessRegistration(registrationRequest: RegistrationExport): Observable<RegistrationExport> {
    return this.registrationService.ProcessRegistration(registrationRequest);
  }

  Register(registrationRequest: RegistrationExport): Observable<RegistrationExport> {
    return this.registrationService.Register(registrationRequest);
  }

  SearchForRegistrationClaim(registrationRequest: RegistrationExport): Observable<RegistrationExport> {
    return this.registrationService.SearchForRegistrationClaim(registrationRequest);
  }

  RegisterSamlUser(request: RegistrationExport): Observable<SsoRegistration>{
    return this.registrationService.RegisterSamlUser(request);
  }
}
