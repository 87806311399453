
<div *ngIf="!utility.isNullOrEmpty(ssoMessage)">
  <p *ngIf="!notLive">{{ssoMessage}}</p>
  <p *ngIf="notLive">
    <toast
      [message]="ssoMessage"
      [type]="toastEnumOptions.Error"
    ></toast>
  </p>
</div>
