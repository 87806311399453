import {HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpHeaders, HttpXsrfTokenExtractor} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {User} from '../../shared/models/user';
import {UserService} from '../../service/user.service';
import {catchError, finalize} from 'rxjs/operators';
import {throwError} from 'rxjs/internal/observable/throwError';
import { SpinnerComponent } from 'src/app/component/spinner/spinner.component';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(private userService: UserService,
    private tokenExtractor: HttpXsrfTokenExtractor,
    private spinnerComponent: SpinnerComponent) {
  }

  /**
   * Add parameter to existing request
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headerName = 'X-XSRF-TOKEN';

    this.spinnerComponent.spinnerAction(true);
    let token = this.tokenExtractor.getToken() as string;
    if (token !== null && !req.headers.has(headerName)) {
      req = req.clone({ headers: req.headers.set(headerName, token)});
    }

    return next.handle(req).pipe(catchError(this.handleError),
      finalize(() => this.spinnerComponent.spinnerAction(false)));
  }

  /**
   * When 'session expired' user should be logout.
   * @param {HttpErrorResponse} error
   * @returns {Observable<never>}
   */
  handleError = (error: HttpErrorResponse) => {
    if (error.status === 401 && error.message === 'SESSION_EXPIRED') {
      this.userService.logout();
    }
    return throwError(error);
  };
}

export const API_INTERCEPTOR = {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true};


