import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Observable } from 'rxjs';
import { AbstractService } from 'src/app/service/api/abstract.service';
import { LoginService } from 'src/app/service/api/login.service';

@Injectable({
  providedIn: 'root'
})
export class EncryptService extends AbstractService {
    constructor(protected http: HttpClient,
      private loginService: LoginService) {
        super(http);
      }

    private key : string;
    private iv: string;
    getHashInfo(){
       this.loginService.getHashInfo().subscribe(data => {
        this.key = CryptoJS.enc.Utf8.parse(data.key);
        this.iv = CryptoJS.enc.Utf8.parse(data.iv);
       });
    }
    // Methods for the encrypt and decrypt Using AES
    encryptUsingAES256(text): any {
        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), this.key, {
            keySize: 128 / 8,
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    }
    decryptUsingAES256(decString) {
        var decrypted = CryptoJS.AES.decrypt(decString, this.key, {
            keySize: 128 / 8,
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }
}
