<div id="sequertyQuestionsForm" *ngIf="questionsList">
  <form role="none" fxLayout="column" novalidate [formGroup]="securityQuestionForm">
    <ul role="none" class="flex-outer" aria-label="Provide answers for below security questions.">
      <li role="none">
        <label for="question1">{{questionsList[0].question + " " + '*'}}</label>
        <mat-form-field >
          <input matInput autocomplete="off" (input)="setAnswerValue()" required id="question1" [type]="hideAnswers ? 'password' : 'text'" formControlName="question1"
            [attr.aria-label]="questionsList[0].question"
            [ngClass]="{'is-invalid': (securityQuestionForm.get('question1').touched ||
                                          securityQuestionForm.get('question1').dirty) &&
                                          !securityQuestionForm.get('question1').valid }" />
          <mat-error class="error-message"
            *ngIf="(securityQuestionForm.get('question1').errors?.required || securityQuestionForm.get('question1').dirty) && !securityQuestionForm.get('question1').valid">
            <span style="background:white;padding:5px"> Required.</span>
          </mat-error>
        </mat-form-field>
      </li>
      <!-- <li role="none" *ngIf="(securityQuestionForm.get('question1').touched ||
                  securityQuestionForm.get('question1').dirty) &&
                  !securityQuestionForm.get('question1').valid">
                  <p></p>
        <span class="error-message" *ngIf="securityQuestionForm.get('question1').errors?.required">
          {{questionsList[0].question.replace('?', '') }} is required.
        </span>
      </li> -->
      <li role="none">
        <label for="question2">{{questionsList[1].question + " " + '*'}}</label>
        <mat-form-field >
        <input matInput autocomplete="off" (input)="setAnswerValue()" required id="question2" [type]="hideAnswers ? 'password' : 'text'" formControlName="question2"
          [attr.aria-label]="questionsList[1].question"
          [ngClass]="{'is-invalid': (securityQuestionForm.get('question2').touched ||
                                          securityQuestionForm.get('question2').dirty) &&
                                          !securityQuestionForm.get('question2').valid }" />
          <mat-error *ngIf="(securityQuestionForm.get('question2').touched ||
            securityQuestionForm.get('question2').dirty) &&
            !securityQuestionForm.get('question2').valid">
              <mat-error class="error-message"
              *ngIf="securityQuestionForm.get('question2').errors?.required">
              <span style="background:white;padding:5px">Required.</span>
            </mat-error>
          </mat-error>
        </mat-form-field>
      </li>
      <!-- <li role="none" *ngIf="(securityQuestionForm.get('question2').touched ||
                  securityQuestionForm.get('question2').dirty) &&
                  !securityQuestionForm.get('question2').valid">
                  <p></p>
        <span class="error-message" *ngIf="securityQuestionForm.get('question2').errors?.required">
          {{questionsList[1].question.replace('?', '')}} is required.
        </span>
      </li> -->
      <li role="none">
        <label for="question3">{{questionsList[2].question + " " + '*'}}</label>
        <mat-form-field >
        <input matInput autocomplete="off" (input)="setAnswerValue()" required id="question3" [type]="hideAnswers ? 'password' : 'text'" formControlName="question3"
          [attr.aria-label]="questionsList[2].question"
          [ngClass]="{'is-invalid': (securityQuestionForm.get('question3').touched ||
                                          securityQuestionForm.get('question3').dirty) &&
                                          !securityQuestionForm.get('question3').valid }" />
        <mat-error *ngIf="(securityQuestionForm.get('question3').touched ||
          securityQuestionForm.get('question3').dirty) &&
          !securityQuestionForm.get('question3').valid">
            <mat-error class="error-message"
            *ngIf="securityQuestionForm.get('question3').errors?.required">
            <span style="background:white;padding:5px"> Required.</span>
          </mat-error>
        </mat-error>
        </mat-form-field>
      </li>
      <!-- <li role="none" *ngIf="(securityQuestionForm.get('question3').touched ||
                  securityQuestionForm.get('question3').dirty) &&
                  !securityQuestionForm.get('question3').valid">
                  <p></p>
        <span class="error-message" *ngIf="securityQuestionForm.get('question3').errors?.required">
          {{questionsList[2].question.replace('?', '')}} is required.
        </span>
      </li> -->
    </ul>
    <ul role="none" class="securityAnswers-checkbox" >
      <mat-checkbox labelPosition="before" color="primary" (change)="showHideSecAnswers()">Show Answers</mat-checkbox>
    </ul>
  </form>
