import { Component, OnInit, ElementRef , ViewChild} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import * as _ from 'lodash-es';
import { UserService } from 'src/app/service/user.service';
import { ContentService } from 'src/app/service/content.service';
import { LoginRequest, LoginResponse, LoginStatus } from '../../../shared/models/loginrequest';
import { ToastOptions } from '../../../shared/models/toast-options.enum';
import { ErrorMessageLogicService } from 'src/app/shared/services/error-message-logic.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { AuthenticateSamlRequest, AuthenticateSamlTfaRequest, SsoRegistration, SsoRegistrationResult, SsoRequest } from 'src/app/shared/models/ssorequest';
import { ToastPopUpComponents, ToastPopupService } from 'src/app/shared/services/toast-popup.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { SpinnerComponent } from 'src/app/component/spinner/spinner.component';
import { SsoRegistrationRequest } from 'src/app/shared/models/registration';
import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  isLogOut: string = this.activatedRoute.snapshot.queryParams["islogout"];
  vanitypath: string = this.utility.isNullOrEmpty(this.activatedRoute.snapshot.paramMap.get('vanitypath'))
    ? this.activatedRoute.snapshot.queryParams["clientid"] : this.activatedRoute.snapshot.paramMap.get('vanitypath');
  loginImage: string;
  clientData: any;
  logoalt:any;
  loginForm = new UntypedFormGroup({
    userName: new UntypedFormControl(''),
    password: new UntypedFormControl(''),
  },
    // {updateOn: 'submit'}
  );
  loginToastErrorMessage: string;
  loginRequest: LoginRequest;
  ssoRequest: SsoRequest;
  showInvalidLoginToast: boolean;
  showDisabledLoginToast: boolean;
  toastEnumOptions = ToastOptions;
  submitClicked: boolean;
  doCollapse: boolean;
  termsIcon: string;
  nextAction: string;
  passwordChanged: boolean;
  passwordChangeToast: string = 'Password successfully changed. Please login with your new password.';
  unlockAccountToast: string = 'Account unlocked.';
  logOutToast: string = 'You have successfully logged out of your session.';
  accountUnlocked: boolean;
  accountDisabled: boolean;
  sessionStorageSet: boolean;
  toastPopupOptions = ToastPopUpComponents;
  sourceURL : string;
  maxLength: number = 50;
  userNameAriaLabel: string = "Enter user name max length " + this.maxLength;
  passwordAriaLabel: string = "Enter password max length " + this.maxLength;
  loginMessage: string;
  showLoginToast: boolean;
  showReportClaim: boolean;
  redirectUrl: string;
  registrationMethod: string;
  @ViewChild('userNameFocus') userNameFocus : ElementRef;
  @ViewChild('passwordFocus') passwordFocus : ElementRef;

  constructor(
    private userService: UserService,
    private contentService: ContentService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private redirectService: RedirectService,
    public utility: UtilityService,
    private el: ElementRef,
    private spinnerComponent: SpinnerComponent,
    private announcer: LiveAnnouncer,
    private titleService : Title
  ) {

  }

  get userName() {
    return this.loginForm.get('userName');
  }

  get password() {
    return this.loginForm.get('password');
  }

  ngOnInit(): void {
    this.sourceURL = window.location.href;
    let url: string = !this.utility.isNullOrEmpty(this.sourceURL.substring(0, this.sourceURL.indexOf("&islogout")))
                      ? this.sourceURL.substring(0, this.sourceURL.indexOf("&islogout"))
                      : this.sourceURL;
    this.sourceURL = url;
    this.userService.mySedgwickUrl = this.sourceURL;

    this.handleVovSso();

    this.setInitialToast();
    if (this.accountDisabled)
      this.showDisabledToast();

    this.vanitypath = this.vanitypath == null ? '' : this.vanitypath;
    this.clientData = JSON.parse(sessionStorage.getItem('clientData'));
    this.titleService.setTitle("mySedgwick - Login");
    if (this.clientData == null ||
      (this.vanitypath == '' && this.clientData?.vanityPath == null)
      || (this.vanitypath != '' && this.clientData?.vanityPath != this.vanitypath)) {
      this.contentService.GetVanityDetails(this.vanitypath).subscribe(data => {
        sessionStorage.setItem('clientData', JSON.stringify(data));
        this.clientData = JSON.parse(sessionStorage.getItem('clientData'));
        this.loginImage = this.clientData.loginImage;
        this.sessionStorageSet = true;
        this.setCustomLoginPage(this.clientData);
        if(this.clientData != undefined && this.clientData != null &&  this.clientData.vanityPath != null && this.clientData.vanityPath != undefined   && this.clientData.vanityPath != null)
        {
           this.logoalt = this.clientData.vanityPath+' Logo';
        }
        else
        {
          this.logoalt = 'mySedgwick Logo';
        }

      }, error => {

      });
    }
    else {
      this.setCustomLoginPage(this.clientData);
      this.loginImage = this.clientData.loginImage;
      this.sessionStorageSet = true;
      if(this.clientData != undefined && this.clientData != null &&  this.clientData.vanityPath != null && this.clientData.vanityPath != undefined   && this.clientData.vanityPath != null)
        {
           this.logoalt = this.clientData.vanityPath+' Logo';
        }
        else
        {
          this.logoalt = 'mySedgwick Logo';
        }
    }

    this.doCollapse = true;
    this.termsIcon = 'arrow_right';

  }

  onSubmit() {
    this.addValidatorsOnSubmit();
    this.hideInitialToasts()
    this.submitClicked = true;

    this.announcer.announce("Login into " + this.loginForm.value.userName + " account","polite");

    if (this.loginForm.invalid) return;
    this.loginRequest = new LoginRequest({
      UserName: this.loginForm.value.userName,
      Password: this.loginForm.value.password,
      TwoFactorDeviceId: "",
      SetTFADevice: false,
      ClientID: "",
      InternalAuditorContract: 0,
      SourceURL: this.sourceURL,
      Resolution: `${window.screen.width}x${window.screen.height}`
    });
    let form = this.loginForm;

    this.userService.login(this.loginRequest).subscribe(
      (result) => {
        switch (result.status) {
          case LoginStatus.Invalid:
            this.loginToastErrorMessage = "The system could not validate your information. Make sure your Username and Password are correct and try again.";
            this.showInvalidLoginToast = true;
            this.showDisabledLoginToast = false;
            // //Remove once we resolve issue on loginscreen/figure out logging??
            // if(!this.utility.isNullOrEmpty(result.errorMessage))
            //   console.log(`Error Message ${result.errorMessage}`);
            break;
          case LoginStatus.Disabled:
            this.showDisabledToast();
            break;
          case LoginStatus.DeadClient:
            this.loginToastErrorMessage = "You do not have permission to access mySedgwick.";
            this.showInvalidLoginToast = true;
            this.showDisabledLoginToast = false;
            break;
          case LoginStatus.ClientNotSetupYet:
            this.loginToastErrorMessage = "You do not have permission to access mySedgwick.";
            this.showInvalidLoginToast = true;
            this.showDisabledLoginToast = false;
            break;
          case LoginStatus.NotActiveClient:
            this.loginToastErrorMessage = "You do not have permission to access mySedgwick.";
            this.showInvalidLoginToast = true;
            this.showDisabledLoginToast = false;
            break;
          case LoginStatus.InvalidContactInfo:
            this.loginToastErrorMessage = "We are unable to complete your login. Please contact your examiner to verify your information or to provide your email address and phone number.";
            this.showInvalidLoginToast = true;
            this.showDisabledLoginToast = false;
            break;
          case LoginStatus.AccountLocked:
            this.userService.username = result.username;
            this.router.navigate(['unlockAccount']);
            break;
          case LoginStatus.PasswordExpired:
            this.userService.username = result.username;
            this.userService.passwordExpired = true;
            this.userService.forgotPasswordToken = result.token;
            this.userService.UserContext = result.userContext;
            this.router.navigate(['changePassword']);
            break;
          case LoginStatus.TwoFactorPreferences:
          case LoginStatus.TfaRequired:
            this.showInvalidLoginToast = false;
            this.userService.emailAddress = result.emailAddress;
            this.userService.phoneNumber = result.phone;
            this.userService.username = result.username;
            this.userService.sessionid = result.sessionId;
            this.userService.redirectToken = result.token;
            this.userService.mySedgwickUrl = result.mySedgwickUrl;
            this.userService.sourceUrl = result.sourceUrl;
            this.userService.loginSubStatus = result.loginSubStatus;
            this.userService.UserContext = result.userContext
            this.router.navigate(['multiFactorAuthentication']);
            break;
          case LoginStatus.Success:
            this.userService.UserContext = result.userContext
            this.redirectService.redirectToMySedgwick(new SsoRequest({
              UserName: result.username,
              SessionId: result.sessionId,
              Token: result.token,
              MySedgwickUrl: result.mySedgwickUrl,
              ClientType: result.userContext.clientType,
              SourceUrl: result.sourceUrl
            }));
            break;

        }
        if(this.loginToastErrorMessage != null) {
          this.announcer.announce(this.loginToastErrorMessage,"polite");
          }
      },
      (e) => {
        this.loginToastErrorMessage = "The system could not validate your information. Make sure your Username and Password are correct and try again.";
        this.showInvalidLoginToast = true;
        this.showDisabledLoginToast = false;

        let response = JSON.parse(e.response);
        if (response.status === 400) {

          // time to loop through the errors
          let errors = response.errors[_.upperFirst('userName')];
          if (errors) {
            form.get('userName').setErrors({
              server: {
                message: errors[0]
              }
            })
          }
        }
        console.error(response);
      }
    );
    this.loginForm.controls['userName'].markAsTouched();
    this.loginForm.controls['password'].markAsTouched();

  }

  addValidatorsOnSubmit(): void {
    this.userName.setValidators([Validators.required]);
    if(this.userName.invalid){
      this.userNameFocus.nativeElement.focus();
      return;
    }
    this.userName.updateValueAndValidity();

    this.password.setValidators([Validators.required])
    if(this.password.invalid){
      this.passwordFocus.nativeElement.focus();
      return;
    }
    this.password.updateValueAndValidity();
  }

  collapseExpand(action: string): void {
    if (action == 'collapse') {
      this.termsIcon = 'arrow_right';
      this.doCollapse = true;
      this.nextAction = 'expand';
    }

    else {
      this.termsIcon = 'arrow_drop_down';
      this.doCollapse = false;
      this.nextAction = 'collapse';
    }
  }

  setInitialToast(): void {
    this.passwordChanged = this.userService.passwordChanged;
    this.accountUnlocked = this.userService.accountUnlocked;
    this.accountDisabled = this.userService.accountDisabled
  }

  hideInitialToasts(): void {
    this.passwordChanged = false;
    this.accountUnlocked = false;
  }

  showDisabledToast(): void {
    // Possible to do list item: create function in the toast component that
    // generates the string properly formatted in the event
    // that we need to pass in a function call to it like we do in this example.
    this.loginToastErrorMessage = `Your mySedgwick login has been disabled due to excessive reset attempts. If you have any questions please contact <span tabindex='0' class='spanLink' data-function=${this.toastPopupOptions.openSupportDialog}>Sedgwick Support</span>.`;
    this.showDisabledLoginToast = true;
    this.showInvalidLoginToast = false;
  }

  setCustomLoginPage(clientData: any): void{
    this.redirectUrl = clientData.redirectUrl ?? clientData.reportClaimRedirect;
    this.registrationMethod = clientData.registrationMethod;
    this.setClientSpecificRedirect(clientData.loginType, this.redirectUrl);
    this.setLoginMessage(clientData);
  }

  setClientSpecificRedirect(loginType: string, redirectUrl: string): void{
    if (loginType == "LOGIN_INTAKE" && !this.utility.isNullOrEmpty(redirectUrl)){
      this.showReportClaim = true;
    }

    if (loginType == "INTAKE" && !this.utility.isNullOrEmpty(redirectUrl)){
      this.redirectToUrl();
    }
  }

  setLoginMessage(clientData: any): void {
    //Might need to add more logic around removing html from toast and what not. If that isnt needed then this can be simplified
    if (clientData.loginType == "LOGIN" || clientData.loginType == "LOGIN_INTAKE" || this.registrationMethod == "NONE"){
      this.showLoginToast = true;
      this.loginMessage = clientData.loginMessage;
    }
  }

  redirectToUrl(): void{
    window.location.href = this.redirectUrl;
  }

  navigateToSsoTfa(results: LoginResponse): void{
    this.userService.emailAddress = results.emailAddress;
    this.userService.phoneNumber = results.phone;
    this.userService.username = results.username;
    this.userService.ssoRegistration = true;
    this.router.navigate(['multiFactorAuthentication'])
  }

  redirectTfaForVovSso(result: SsoRegistrationResult): void{
    //Once we are able to test see if we can avoid adding reference to spinner component here.
    this.spinnerComponent.spinnerAction(true);
    let authSamlRequest = new AuthenticateSamlTfaRequest({
      Username: result.username,
      SessionId: result.sessionId
    });

    this.userService.authenticateSsoUserForTfa(authSamlRequest).subscribe(
      (results) => {
        let ssoAuthentication = new SsoRegistration({
          loginResponse : results
        });

        //Confirm if this properly redirects to a 301 status error SSO page
        if (ssoAuthentication.loginResponse.status == LoginStatus.PasswordExpired)
          this.router.navigate(['sso'], { queryParams: { status: "301" }});

        else {
          this.userService.createSsoTwoFactorRequest(ssoAuthentication).subscribe(
          (data) =>{
            if (data) {
              this.navigateToSsoTfa(results);
            }
          });
        }
      }
    )
  }

  redirectToRegistrationForVovSso(data: SsoRegistrationResult): void{
    let registrationData = new SsoRegistrationRequest({
      DoSsoRegistration: data.ssoRegistration,
      ForceTfa: data.forceTfa == "Yes",
      ClientCode: data.clientCode,
      SessionId: data.sessionId,
      TextPhone: data.textPhone,
      UserEmail: data.userEmail,
      Username: data.username
    });

    this.userService.ssoRegistrationRequest = registrationData;

    this.router.navigate(['register']);
  }

  handleVovSso(): void{
    this.userService.ssoRegister().subscribe(
      (data) => {
        //Not sure if we should be checking for isSuccessful like this or not
        if (data != null && data.isSuccessful) {
          this.userService.redirectToken = data.token;
          this.userService.mySedgwickUrl = data.mySedgwickUrl;
          if (data.forceTfa == "Yes" && data.ssoRegistration)
            this.redirectTfaForVovSso(data);

          else
            this.redirectToRegistrationForVovSso(data);
        }
      });
  }
}
