
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { API_INTERCEPTOR } from "./shared/interceptor/api-interceptor";
import { FlexLayoutModule } from '@angular/flex-layout';
import { BotDetectCaptchaModule } from 'angular-captcha';
//Angular Material Components
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatDividerModule} from '@angular/material/divider';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

import {LoginModule} from './login/login.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoginPageComponent } from './login/pages/login-page/login-page.component';
import { AboutDialogComponent } from './core/components/about-dialog/about-dialog.component';
import { ForgotPasswordComponent } from './login/pages/forgot-password/forgot-password.component';
import { ForgotUsernameComponent } from './login/pages/forgot-username/forgot-username.component';
import { MainLayoutComponent } from './core/pages/main-layout/main-layout.component';
import { DashLayoutComponent } from './core/pages/dash-layout/dash-layout.component';
import { SupportDialogComponent } from './core/components/support-dialog/support-dialog.component'
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import {FooterComponent} from './core/components/footer/footer.component';
import {BuildinfoComponent } from './core/components/buildinfo/buildinfo.component';
import { FooterlinksComponent } from './core/components/footerlinks/footerlinks.component';
import { MultiFactorAuthenticationComponent } from './login/pages/multi-factor-authentication/multi-factor-authentication.component';
import { RegistrationComponent } from './component/registration/registration.component';
import { ChangePasswordComponent } from './component/change-password/change-password.component';
import { SecurityQuestionsComponent } from './component/security-questions/security-questions.component';
import { UnlockAccountComponent } from './component/unlock-account/unlock-account.component';
import { RegistrationSecurityquestionsComponent } from './component/registration-securityquestions/registration-securityquestions.component';
import { PopupHeaderComponent } from './core/components/popup-header/popup-header.component';
import { AppConfigService } from './service/api/app-config.service';
import { SsoComponent } from './login/pages/sso/sso.component';
import { CookieService } from 'ngx-cookie-service';
import { SpinnerComponent } from './component/spinner/spinner.component';
import { BlankComponent } from './component/blank/blank.component';
import { GlobalErrorHandlerService } from './service/api/global-error-handler.service';


const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0
  }
};

export function appInit(appConfigService: AppConfigService) {
  return () => appConfigService.load();
}
const APPINIT_PROVIDES = [
  AppConfigService,
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [AppConfigService],
    multi: true,
  },
];
@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    AboutDialogComponent,
    ForgotPasswordComponent,
    ForgotUsernameComponent,
    MainLayoutComponent,
    SupportDialogComponent,
    FooterComponent,
    BuildinfoComponent,
    FooterlinksComponent,
    MultiFactorAuthenticationComponent,
    RegistrationComponent,
    ChangePasswordComponent,
    SecurityQuestionsComponent,
    UnlockAccountComponent,
    RegistrationSecurityquestionsComponent,
    DashLayoutComponent,
    PopupHeaderComponent,
    SsoComponent,
    SpinnerComponent,
    BlankComponent
  ],
  imports: [
    BotDetectCaptchaModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    // HttpClientXsrfModule.withOptions({
    //   cookieName: 'XSRF-TOKEN',
    //   headerName: 'X-XSRF-TOKEN'
    // }),
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatDividerModule,
    MatCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    CommonModule,
    RouterModule,
    CoreModule,
    SharedModule,
    ReactiveFormsModule,
  ],
  exports: [],
  providers: [
    API_INTERCEPTOR,
    MatDatepickerModule,
    CookieService,
    {provide: ErrorHandler, useClass: GlobalErrorHandlerService},
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    //APPINIT_PROVIDES

    //this line needed for HttpClientXsrfModule to be invoked
    ],
  bootstrap: [AppComponent],
})
export class AppModule {}
