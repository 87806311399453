<div *ngIf="!redirectUserToSite && !accountLocked">
  <div
    fxLayout="column"
    fxFlexAlign="center"
    id="authenticationChoiceContent"
    *ngIf="!verificationCodeSent"
  >
    <h1  role="heading">{{ pageTitle }}</h1>
    <p>
      <toast [message]="initialMfaToast" [type]="toastEnumOptions.Info"></toast>
    </p>
    <form
      role="none"
      fxLayout="column"
      [formGroup]="verificationMethodForm"
      (ngSubmit)="verificationMethodNext()"
    >
    <mat-radio-group formControlName = "deliverPreference">
      <ul class="flex-outer" role="none">
        <li
          role="none"
          *ngIf="!utility.isNullOrEmpty(emailPreference)"
        >
          <div >
            <mat-radio-button
              color="primary"
              labelPosition="after"
              [value]="'Email'"
              aria-label="Email receive code via email"
            ></mat-radio-button>
            <span><strong> Email:</strong> Receive code via email</span>
          </div>
        </li>
        <li
          role="none"
          *ngIf="!utility.isNullOrEmpty(emailPreference)"
          fxLayout="row"
          fxLayout.sm="column"
        >
          <label fxFlex.lg="25%" fxFlex.sm="100%">Email Address</label>
          <label>{{ hiddenEmail }}</label>
        </li>
        <!-- The portion with this ng-container for email and the for phone preference was built before having requirements and before I knew we were getting rid of the
        verification screen where user can enter that info once they login. Keeping the code here because it could be useful to use
        in the future and we will probably be able to configure this condition to show this field later down the road -->
        <ng-container *ngIf="utility.isNullOrEmpty(emailPreference) && 1!= 1">
          <li
            role="none"
            fxLayout="row"
            fxLayout.sm="column"
            fxLayoutGap="0.05"
            formGroupName="emailGroup"
          >
            <label fxFlex.lg="25%" fxFlex.sm="100%" for="emailId"
              >Email Address</label
            >
            <mat-form-field fxFlex.lg="25%" fxFlex.sm="100%">
              <input
                matInput
                id="emailId"
                autocomplete="off"
                type="email"
                formControlName="email"
                [ngClass]="{
                  'is-invalid':
                  verificationMethodForm.get('deliverPreference').value == 'Email' &&
                    showErrorMessageForField(
                      verificationMethodForm,
                      'emailGroup.email'
                    )
                }"
              />
            </mat-form-field>
          </li>
          <li
            role="none"
            formGroupName="emailGroup"
            *ngIf="
            verificationMethodForm.get('deliverPreference').value == 'Email' &&
              showErrorMessageForField(
                verificationMethodForm,
                'emailGroup.email'
              )
            "
          >
            <span
              class="error-message"
              *ngIf="
                !verificationMethodForm.get('emailGroup.email').errors?.email
              "
            >
              Please enter an email address.
            </span>
            <span
              class="error-message"
              *ngIf="
                verificationMethodForm.get('emailGroup.email').errors?.email
              "
            >
              Please enter a proper email address.
            </span>
          </li>
          <li
            role="none"
            *ngIf="!emailPreference"
            formGroupName="emailGroup"
            fxLayout="row"
            fxLayout.sm="column"
            fxLayoutGap="0.05"
          >
            <label fxFlex.sm="100%" fxFlex.lg="25%" for="confirmEmailId"
              >Confirm Email Address</label
            >
            <mat-form-field fxFlex.sm="100%" fxFlex.lg="25%">
              <input
                matInput
                autocomplete="off"
                id="confirmEmailId"
                type="email"
                formControlName="confirmEmail"
                [ngClass]="{
                  'is-invalid':
                  verificationMethodForm.get('deliverPreference').value == 'Email' &&
                    (verificationMethodForm.get('emailGroup.confirmEmail')
                      .touched ||
                      verificationMethodForm.get('emailGroup.confirmEmail')
                        .dirty) &&
                    (verificationMethodForm.get('emailGroup.confirmEmail')
                      .invalid ||
                      verificationMethodForm.get('emailGroup').invalid)
                }"
              />
            </mat-form-field>
          </li>
          <li
            role="none"
            formGroupName="emailGroup"
            *ngIf="
              !emailPreference &&
              verificationMethodForm.get('deliverPreference').value == 'Email' &&
              (verificationMethodForm.get('emailGroup.confirmEmail').touched ||
                verificationMethodForm.get('emailGroup.confirmEmail').dirty)
            "
          >
            <span
              class="error-message"
              *ngIf="
                verificationMethodForm.get('emailGroup.confirmEmail').errors
                  ?.email
              "
            >
              Please enter a proper email address.
            </span>
            <span
              class="error-message"
              *ngIf="
                verificationMethodForm.get('emailGroup').errors?.match &&
                !verificationMethodForm.get('emailGroup.confirmEmail').errors
                  ?.email
              "
            >
              The email addresses do not match.
            </span>
          </li>
        </ng-container>
        <li role="none" *ngIf="!utility.isNullOrEmpty(phonePreference)">
          <div>
            <mat-radio-button
              color="primary"
              labelPosition="after"
              [value]="'Text'"
              aria-label="Text Message receive code on your phone, Standard text message rates apply"
            ></mat-radio-button>
            <span>
              <strong> Text Message:</strong> Receive code on your phone
              (Standard text message rates apply)
            </span>
          </div>
        </li>
        <li role="none" fxLayout="row" fxLayout.sm="column" *ngIf="!utility.isNullOrEmpty(phonePreference)">
          <label fxFlex.sm="100%" fxFlex.lg="25%" for="textNumberId"
            >Cell Phone Number</label
          >
          <app-phone
            [prefilledNumber]="phonePreference"
            [required]="verificationMethodForm.get('deliverPreference').value == 'Text'"
            [attemptedSubmission]="chooseVerificationSubmitClicked"
            [parentId]="componentId"
            [hidePhoneNumber]="true"
            formControlName="phoneGroup"
          ></app-phone>
        </li>
      </ul>
    </mat-radio-group>

      <div
        fxFlexAlign="center"
        id="authenticationChoiceError"
        *ngIf="
          chooseVerificationError &&
          !(verificationMethodForm.get('deliverPreference').value == 'Email' &&
          verificationMethodForm.get('deliverPreference').value == 'Text')
        "
      >
        <span class="error-message">{{ chooseVerificationErrorMessage }}</span>
      </div>

      <p *ngIf="showSsoErrorMessage">
        <toast [message]="ssoTfaErrorMessage" [type]="toastEnumOptions.Error"></toast>
      </p>

      <div dir="rtl" fxLayoutGap="20px">
        <button
          mat-raised-button
          color="primary"
          type="submit"
          style="width: 100px"
        >
          NEXT
          <mat-icon class="but-icon" aria-hidden="false">forward</mat-icon>
        </button>
        <button type="button" mat-raised-button class="cancelButton" (click)="onCancel()">
          CANCEL
          <mat-icon class="but-icon" aria-hidden="true">block</mat-icon>
        </button>
      </div>
    </form>
  </div>

  <div
    fxLayout="column"
    fxFlexAlign="center"
    id="enterAuthenticationCodeContent"
    *ngIf="verificationCodeSent"
  >
    <h1  role="heading">{{ pageTitle }}</h1>
    <p>
      <toast
        [message]="enterVerificationToast"
        [type]="toastEnumOptions.Success"
        *ngIf="!verificationCodeReSent"
      ></toast>
      <toast
      *ngIf="verificationCodeReSent"
        [message]="newCodeToast"
        [type]="toastEnumOptions.Success"
      ></toast>
    </p>
    <form
      role="none"
      fxLayout="column"
      [formGroup]="enterVerificationCodeForm"
    >
      <ul class="flex-outer" role="none">
        <li role="none" fxLayout="row" fxLayout.sm="column" fxLayoutGap="0.05%" dir="ltr">
          <label fxFlex.sm="100%" fxFlex.lg="50%" for="authCodeId"
            >Authentication code: *</label
          >
          <mat-form-field fxFlex.sm="100%" fxFlex.lg="25%">
            <input
              maxlength="6"
              autocomplete="off"
              #authCodefocus
              matInput
              required
              id="authCodeId"
              type="text"
              formControlName="authCode"
              [ngClass]="{
                'is-invalid': showErrorMessageForField(
                  enterVerificationCodeForm,
                  'authCode'
                )
              }"
              aria-label="Authentication code"
              [title]="authCodeTitle"
            />
            <mat-error *ngIf="showErrorMessageForField(enterVerificationCodeForm, 'authCode')">
              <mat-error class="error-message" *ngIf="enterVerificationCodeForm.get('authCode').errors?.required">
                <span style="background:white;padding:5px">Please enter your authentication code.</span>
              </mat-error>
            </mat-error>
          </mat-form-field>
        </li>
        <li role="none" fxLayout="row" fxLayout.sm="column" fxLayoutGap="0.05%" *ngIf="showRememberMeBox">
          <div>
            <span>Remember this device </span>
            <mat-checkbox
              color="primary"
              labelPosition="before"
              formControlName="rememberDeviceCheck"
              aria-label="Remember this device"
            ></mat-checkbox>
          </div>
        </li>
      </ul>
      <p>
        <toast
          [message]="oneTimeCodeToast"
          [type]="toastEnumOptions.Info"
          isToastFocus="false"
        ></toast>
      </p>
      <p *ngIf="codeStatus == 'EXPIRED'">
        <toast
          [message]="expiredMessage"
          [type]="toastEnumOptions.UserError"
        >
        </toast>
      </p>
      <p *ngIf="codeStatus == 'ATTEMPTS_EXCEEDED'">
        <toast
          [message]="lockoutMessage"
          [type]="toastEnumOptions.UserError"
        >
        </toast>
      </p>
      <p *ngIf="codeStatus == 'PENDING' || codeStatus == 'INIT'">
        <toast
          [message]="invalidMessage"
          [type]="toastEnumOptions.UserError"
        >
        </toast>
      </p>

    </form>
  </div>
  <div fxLayout="row"  dir="rtl" fxLayoutGap="10px" *ngIf="verificationCodeSent">
    <button
      *ngIf="showVerifyCodeButton()"
      mat-raised-button
      color="primary"
      type="submit"
      style="width: 100px"
      (click) ="enterVerificationCodeNext()"
    >
      SUBMIT
      <mat-icon>done</mat-icon>
    </button>
    <button
      *ngIf="showResendButton()"
      type="button"
      mat-raised-button
      color="primary"
      style="width: 150px"
      (click)="resendVerificationCode()"
    >
      RESEND CODE
      <mat-icon>done</mat-icon>
    </button>
    <button type="button" mat-raised-button class="cancelButton" (click)="onCancel()">
      CANCEL
      <mat-icon class="but-icon" aria-hidden="true">block</mat-icon>
    </button>
  </div>
</div>
<div *ngIf="accountLocked"
      fxLayout="column"
      fxFlexAlign="center"
      id="lockPageContent">
    <h1>{{ pageTitle }}</h1>
    <form
      role="none"
      fxLayout="column">
      <div>
        <p>
          <toast
              [message]="lockoutMessage"
              [type]="toastEnumOptions.UserError"
            ></toast>
        </p>
      </div>
      <div dir="rtl" fxLayoutGap="20px">
        <button type="button" mat-raised-button class="cancelButton" (click)="onCancel()">
          CANCEL
          <mat-icon class="but-icon" aria-hidden="true">block</mat-icon>
        </button>
      </div>
    </form>
</div>
