<!--Make it code driven later-->

<div>
    <span class="grey">{{appCompany}}</span>
    <span [hidden]="true">
        <br>
        {{buildInfo}}
    </span>
    <span *ngIf= "isDemoMode" class="red">Demo</span>
  </div>
            