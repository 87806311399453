<!-- Check Claim Number begin -->
<div *ngIf="onHaveClaimNumberStep" id="checkClaimNumber" class="registrationContent" role="none">
  <div fxLayout="column" fxFlexAlign="center" role="none">
    <h1  role="heading" aria-label="Registration">Registration</h1>
  </div>
  <!-- form begin -->
  <div class="registration-container">
    <form role="none" [formGroup]="regClaimNumberForm">
      <ul class="flex-outer" role="none">
        <li role="none">
          <label id="haveClaimNumber">Do you have a claim number? *</label>
          <mat-radio-group formControlName="haveClaimNumber" aria-label="press enter and use arrow keys to change selection" [(ngModel)]="haveClaimNumberModel" >
            <mat-radio-button labelPosition="before" tabindex="0" aria-label="Do you have a claim number yes" [attr.aria-checked]="rdYes" value="YES" (change)="handleChange('YES')">Yes </mat-radio-button>
            <mat-radio-button labelPosition="before" tabindex="0" aria-label="Do you have a claim number no" [attr.aria-checked]="rdNo" value="NO" (change)="handleChange('NO')">No</mat-radio-button>
          </mat-radio-group>

        </li>
        <li role="none" *ngIf="haveClaimNumberModel=='YES'">
          <label for="claimNumber">Claim Number *</label>
          <input class="input" type="text" formControlName="claimNumber"  name="claimNumber" id="claimNumber"
            autocomplete="off" maxlength="50" aria-label="Claim Number"/>
            
        </li>
        <div fxLayout="row" fxLayoutAlign="end center">
          <button type="button" class="whiteButton" mat-raised-button (click)="onCancel()">
            <mat-icon class="reg-icon" aria-hidden="true">block</mat-icon>CANCEL
          </button>

         <!-- <span role="button" class="sr-only"
            aria-label="Next"
            aria-hidden="true"
            tabindex="regClaimNumberForm.valid ? '-1' : '0'"
            [attr.title]="regClaimNumberForm.valid ? '' : 'disabled until mandatory fields are missing'">
          </span>-->
          <button type="submit" [disabled]="!regClaimNumberForm.valid && haveClaimNumberModel!='NO'"
            (click)="moveToRegistration()" class="button" mat-raised-button>
            <mat-icon class="reg-icon" aria-hidden="false">forward</mat-icon>NEXT
          </button>
        </div>

        <ng-container *ngIf="haveClaimNumberModel!='NO' && personalInfoErrorMessage != '' ">
          <p>
            <toast [message]="personalInfoErrorMessage" [type]="toastEnumOptions.Error"></toast>
          </p>
        </ng-container>

      </ul>
    </form>
  </div>
  <!-- form end -->
</div>
<!-- Check Claim Number END -->
<!-- Registration begin -->
<div *ngIf="onStartRegistration" id="startRegistration" class="registrationContent" role="none">
  <div fxLayout="column" fxFlexAlign="center">
    <h1  role="heading" aria-label="Registration">Registration</h1>
  </div>
  <!-- form begin -->
  <div class="registration-container">
    <form [formGroup]="registrationForm" role="none">
      <div *ngIf="registrationProcessErrorMessage != '' && registrationProcessErrorMessage != 'Success'">
        <toast
          [message]="registrationProcessErrorMessage" [type]="toastEnumOptions.Error"></toast>
          <div fxLayout="row" fxLayoutAlign="end center">
            <button type="button" class="button" mat-raised-button (click)="onCancel()" mat-raised-button>
              <mat-icon class="reg-icon" aria-hidden="trye">block</mat-icon>CANCEL
            </button>

          </div>
      </div>
      
      <ul role="none" class="flex-outer" *ngIf="registrationProcessErrorMessage == 'Success'" role="none">
        
        <li role="none" *ngIf="showLastName == true">
          <label for="lastName">Last Name: *</label>
          <mat-form-field [@.disabled]="animationsDisabled">
            <input matInput class="input" formControlName="lastName" name="lastName" required id="lastName"
              autocomplete="off" aria-label="Last Name" />
            <mat-error  role="none" class="error-message" *ngIf="(registrationForm.get('lastName').invalid && registrationForm.get('lastName').errors &&
              (registrationForm.get('lastName').dirty || registrationForm.get('lastName').touched)) && registrationForm.get('lastName').hasError('required')">
                <span style="background:white;padding:5px">
                Last Name is required.</span>
              </mat-error>
              <mat-error  role="none" *ngIf="(registrationForm.get('lastName').invalid && registrationForm.get('lastName').errors &&
              (registrationForm.get('lastName').dirty || registrationForm.get('lastName').touched)) && registrationForm.get('lastName').hasError('pattern')">
                <span style="background:white;padding:5px">
                Please enter a valid Last name.</span>
              </mat-error>
            
          </mat-form-field>
        </li>

        <li role="none"  *ngIf="showDOB == true">
          <label for="dateofBirth">Date of Birth *</label>
          <mat-form-field id="birthDate" [@.disabled]="animationsDisabled">
            <input matInput [matDatepicker]="dob" [max]="maxDate" required aria-label="Enter Date Of Birth or select from calendar popup" formControlName="dateofBirth" autocomplete="off" >
            <mat-datepicker-toggle  class="light-blue" matSuffix [for]="dob">
            </mat-datepicker-toggle>
            <mat-datepicker #dob [dateClass]="setClass()"
            (opened)="toggleDatepicker()"
            [startAt]=null>
            </mat-datepicker>
            <mat-error  role="none" class="error-message" *ngIf="registrationForm.get('dateofBirth').hasError('required')">
              <span style="background:white;padding:5px">Please enter a valid Date of Birth.</span>
            </mat-error>
            <mat-error  role="none" class="error-message mat-error-disable"
              *ngIf="registrationForm.get('dateofBirth').hasError('matDatepickerMax')">
              <span style="background:white;padding:5px">
              Date of Birth cannot be greater than today.</span>
            </mat-error>
          </mat-form-field>
        </li>

        <li role="none" *ngIf="showSSN==true">
          <label for="last4SSN">Last 4 digits of SSN *</label>
          <mat-form-field [@.disabled]="animationsDisabled">
            <input class="form-control" matInput [maxlength]="4" type="password" formControlName="last4SSN" name="last4SSN"
              id="last4SSN" autocomplete="off" aria-label="Enter Last 4 digits of SSN" />
           
              <mat-error  role="none" class="error-message" *ngIf="(registrationForm.get('last4SSN').invalid &&
              registrationForm.get('last4SSN').errors ||
              ((registrationForm.get('last4SSN').dirty || registrationForm.get('last4SSN').touched))) && registrationForm.get('last4SSN').hasError('required')">
                <span style="background:white;padding:5px">Last 4 digits of SSN is required.</span>
              </mat-error>
              <mat-error  role="none" class="error-message" *ngIf="(registrationForm.get('last4SSN').invalid &&
              registrationForm.get('last4SSN').errors ||
              ((registrationForm.get('last4SSN').dirty || registrationForm.get('last4SSN').touched))) && registrationForm.get('last4SSN').hasError('minlength')">
                <span style="background:white;padding:5px">
                Please enter at least
                {{registrationForm.get('last4SSN').errors.minlength.requiredLength}}
                characters.</span>
              </mat-error>
              <mat-error  role="none" class="error-message" *ngIf="(registrationForm.get('last4SSN').invalid &&
              registrationForm.get('last4SSN').errors ||
              ((registrationForm.get('last4SSN').dirty || registrationForm.get('last4SSN').touched))) && registrationForm.get('last4SSN').hasError('maxlength')">
                <span style="background:white;padding:5px">
                The maximum length for this field is
                {{registrationForm.get('last4SSN').errors.maxlength.requiredLength}}
                characters.</span>
              </mat-error>
              <mat-error  role="none" class="error-message" *ngIf="(registrationForm.get('last4SSN').invalid &&
              registrationForm.get('last4SSN').errors ||
              ((registrationForm.get('last4SSN').dirty || registrationForm.get('last4SSN').touched))) && registrationForm.get('last4SSN').hasError('pattern')">
                <span style="background:white;padding:5px">
                Please enter numbers for this field.</span>
              </mat-error>
           
          </mat-form-field>
        </li>

        <li role="none" *ngIf="isLiabilityClaim == true">
          <label for="claimCity">City *</label>
          <mat-form-field [@.disabled]="animationsDisabled">
            <input class="form-control" matInput formControlName="claimCity" name="claimCity" id="claimCity"
              aria-label="City" autocomplete="off" required />
            <mat-error  role="none" class="error-message" *ngIf="registrationForm.get('claimCity').hasError('required')">
              <span style="background:white;padding:5px">
              City is required.</span>
            </mat-error>
          
          </mat-form-field>
        </li>

        <li role="none" *ngIf="isLiabilityClaim == true">
          <label for="claimState">State *</label>
          <mat-form-field [@.disabled]="animationsDisabled">
            <input class="form-control" matInput formControlName="claimState" name="claimState" id="claimState"
              aria-label="State" autocomplete="off" required />
            <mat-error  role="none" class="error-message" *ngIf="registrationForm.get('claimState').hasError('required')">
              <span style="background:white;padding:5px">
              State is required.</span>
            </mat-error>
           
          </mat-form-field>
        </li>

        <li role="none" *ngIf="showZipCode==true">
          <label for="zipCode">{{labelZipCode}} *</label>
          <mat-form-field [@.disabled]="animationsDisabled">
            <input class="form-control" matInput formControlName="zipCode" [maxLength]="maxZipcodeLength" required
              name="zipCode" id="zipCode" autocomplete="off"
              aria-label="Home Postal Code"
              title="Please do not enter dashes or spaces for postal code."
            />
           
              <mat-error  role="none" class="error-message" *ngIf="(registrationForm.get('zipCode').invalid &&
              registrationForm.get('zipCode').errors ||
              ((registrationForm.get('zipCode').dirty || registrationForm.get('zipCode').touched))) && registrationForm.get('zipCode').hasError('required')">
                <span style="background:white;padding:5px">
                {{errorZipCode}}</span>
              </mat-error>
              <mat-error  role="none" class="error-message" *ngIf="(registrationForm.get('zipCode').invalid &&
              registrationForm.get('zipCode').errors ||
              ((registrationForm.get('zipCode').dirty || registrationForm.get('zipCode').touched))) && registrationForm.get('zipCode').hasError('minlength')">
                <span style="background:white;padding:5px">
                Please enter at least {{registrationForm.get('zipCode').errors.minlength.requiredLength}}
                characters.</span>
              </mat-error>
              <mat-error  role="none" class="error-message" *ngIf="(registrationForm.get('zipCode').invalid &&
              registrationForm.get('zipCode').errors ||
              ((registrationForm.get('zipCode').dirty || registrationForm.get('zipCode').touched))) && registrationForm.get('zipCode').hasError('maxlength')">
                <span style="background:white;padding:5px">
                The maximum length for this field is {{registrationForm.get('zipCode').errors.maxlength.requiredLength}}
                characters.</span>
              </mat-error>
              <!-- <mat-error class="error-message" *ngIf="registrationForm.get('zipCode').hasError('pattern')">
                Please enter a valid US zip code in ‘99999’ format.
              </mat-error> -->
              <mat-error  role="none" class="error-message" *ngIf="(registrationForm.get('zipCode').invalid &&
              registrationForm.get('zipCode').errors ||
              ((registrationForm.get('zipCode').dirty || registrationForm.get('zipCode').touched))) && registrationForm.get('zipCode').hasError('inValidZopcodeCanada')">
                <span style="background:white;padding:5px">
                Please enter a valid Canadian postal code in ‘A9A9A9’ format.</span>
              </mat-error>
              <mat-error  role="none" class="error-message" *ngIf="(registrationForm.get('zipCode').invalid &&
              registrationForm.get('zipCode').errors ||
              ((registrationForm.get('zipCode').dirty || registrationForm.get('zipCode').touched))) && registrationForm.get('zipCode').hasError('inValidZopcodeUS')">
                <span style="background:white;padding:5px">
                Please enter a valid US zip code in ‘99999’ format.</span>
              </mat-error>
            
          </mat-form-field>
        </li>

        <li role="none" *ngIf="showZipCode==true">
          <label><span class="hidden" aria-hidden="true"></span></label>
          <span class="textboxInstruction roboto14">
            Please do not enter dashes or spaces for postal code.
          </span>
        </li>

        <li role="none" *ngIf="showEmployeeId==true">
          <label for="employeeId">{{labelEmployeeID}} *</label>
          <mat-form-field [@.disabled]="animationsDisabled">
            <input class="form-control" required matInput formControlName="employeeId" name="employeeId" id="employeeId"
            [attr.aria-label]="labelEmployeeID" autocomplete="off" maxlength="256" />
            <mat-error  role="none" class="error-message" *ngIf="registrationForm.get('employeeId').hasError('required')">
              <span style="background:white;padding:5px">
              {{labelEmployeeID}} is required.</span>
            </mat-error>
            <mat-error  role="none" class="error-message" *ngIf="registrationForm.get('employeeId').hasError('pattern')">
              <span style="background:white;padding:5px">
              Please enter a valid {{labelEmployeeID}} .</span>
            </mat-error>
            <mat-error  role="none" class="error-message" *ngIf="registrationForm.get('employeeId').hasError('maxLength')">
              <span style="background:white;padding:5px">
              {{labelEmployeeID}} cannot be greater than 256 characters.</span>
            </mat-error>
          </mat-form-field>
        </li>

        <div *ngIf="!requireClaimNumber && !requireEmpId" fxLayout="row" fxLayoutAlign="end center">
          <button type="button" class="whiteButton" mat-raised-button (click)="onCancel()">
            <mat-icon class="reg-icon" aria-hidden="true">block</mat-icon>CANCEL
          </button>

         <!---- <span role="button" class="sr-only"
            aria-label="Next"
            aria-hidden="true"
            tabindex="registrationForm.valid ? '-1' : '0'"
            [attr.title]="registrationForm.valid ? '' : 'disabled until mandatory fields are missing'">
          </span>-->
          <button type="submit" [disabled]="!registrationForm.valid" (click)="moveToUserNameSelection()" class="button"
            mat-raised-button>
            <mat-icon class="reg-icon" aria-hidden="false">forward</mat-icon>NEXT
          </button>
        </div>

        <p>
          <toast *ngIf="personalInfoErrorMessage != ''" [message]="personalInfoErrorMessage" isToastFocus="true"
            [type]="toastEnumOptions.Error"></toast>
        </p>


        <li role="none" *ngIf="requireClaimNumber ">
          <label for="claimNumberForReg">Claim Number *</label>
          <mat-form-field [@.disabled]="animationsDisabled">
            <input class="form-control" matInput formControlName="claimNumberForReg" name="claimNumberForReg"
              aria-label="Claim Number" required id="claimNumberForReg" autocomplete="off" maxlength="50" />
            <mat-error  role="none" class="error-message" *ngIf="registrationForm.get('claimNumberForReg').hasError('required')">
              <span style="background:white;padding:5px">
              Claim Number is required.</span>
            </mat-error>
          </mat-form-field>
        </li>

        <li role="none" *ngIf="requireEmpId">
          <label for="employeeId">{{labelEmployeeID}} *</label>
          <mat-form-field [@.disabled]="animationsDisabled">
            <input class="form-control" matInput formControlName="employeeId" name="employeeId" id="employeeId"
              autocomplete="off" required [attr.aria-label]="labelEmployeeID" maxlength="256" />
            <mat-error  role="none" class="error-message" *ngIf="registrationForm.get('employeeId').hasError('required')">
              <span style="background:white;padding:5px">
              {{labelEmployeeID}} is required.</span>
            </mat-error>
            <mat-error  role="none" class="error-message" *ngIf="registrationForm.get('employeeId').hasError('pattern')">
              <span style="background:white;padding:5px">
              Please enter a valid {{labelEmployeeID}}.</span>
            </mat-error>
            <mat-error  role="none" class="error-message" *ngIf="registrationForm.get('employeeId').hasError('maxLength')">
              <span style="background:white;padding:5px">
              {{labelEmployeeID}} cannot be greater than 256 characters.</span>
            </mat-error>
          </mat-form-field>
        </li>

        <!--<li role="none" *ngIf="isLiabilityClaim == true">
          <label for="zipCode">Zip Code *</label>
          <mat-form-field [@.disabled]="animationsDisabled">
            <input class="form-control" matInput formControlName="zipCode" name="zipCode" id="zipCode"
              autocomplete="off" required />
            <mat-error class="error-message" *ngIf="registrationForm.get('ZipCode').hasError('required')">
              ZipCode is required.
            </mat-error>
            <mat-error class="error-message" *ngIf="registrationForm.get('employeeId').hasError('pattern')">
              Please enter a valid employee Id.
            </mat-error>
            <mat-error class="error-message" *ngIf="registrationForm.get('employeeId').hasError('maxLength')">
              Employee Id cannot be greater than 256 characters.
            </mat-error>
          </mat-form-field>
        </li>-->
      </ul>

      <div *ngIf="requireClaimNumber || requireEmpId" fxLayout="row" fxLayoutAlign="end center">
        <button type="button" class="whiteButton" mat-raised-button (click)="onCancel()">
          <mat-icon class="reg-icon" aria-hidden="true">block</mat-icon>CANCEL
        </button>

        <!--<span role="button" class="sr-only"
            aria-label="Next"
            aria-hidden="true"
            tabindex="registrationForm.valid ? '-1' : '0'"
            [attr.title]="registrationForm.valid ? '' : 'disabled until mandatory fields are missing'">
          </span>-->
        <button type="submit" [disabled]="!registrationForm.valid" (click)="moveToUserNameSelection()" class="button"
          mat-raised-button>
          <mat-icon class="reg-icon" aria-hidden="false">forward</mat-icon>NEXT
        </button>
      </div>

    </form>
  </div>
</div>
<!-- Registration END -->
<!-- Choose User name begin -->
<div *ngIf="onSelectUsername" id="chooseUsername">
  <div fxLayout="column" fxFlexAlign="center" id="registrationContent" class="registrationContent registration-container">
    <h1 role="heading">Choose Username/Password</h1>
    <app-pwinstructions></app-pwinstructions>
    <div fxLayout="row" fxLayoutAlign="end center" class="top10">
      <strong>*Required</strong>
    </div>
  </div>
  <!-- form begin -->
  <div class="registration-container" [hidden]="!onSelectUsername">
    <form role="none" [formGroup]="registrationUserNameForm" autocomplete="off">
      <ul role="none" class="flex-outer">
        <li role="none">
          <label for="userName">Username: *</label>
          <mat-form-field [@.disabled]="animationsDisabled">
            <input class="form-control" matInput formControlName="userName" required name="userName" id="userName"
                   autocomplete="off" aria-label="User name" />
            <mat-error role="none" class="error-message" *ngIf="(registrationUserNameForm.get('userName').invalid && registrationUserNameForm.get('userName').errors &&
              (registrationUserNameForm.get('userName').dirty || registrationUserNameForm.get('userName').touched)) && (registrationUserNameForm.get('userName').hasError('required'))">
              <span style="background:white;padding:5px">
                User Name is required.
              </span>
            </mat-error>
            <mat-error role="none" class="error-message" *ngIf="(registrationUserNameForm.get('userName').invalid && registrationUserNameForm.get('userName').errors &&
              (registrationUserNameForm.get('userName').dirty || registrationUserNameForm.get('userName').touched)) && registrationUserNameForm.get('userName').hasError('minlength')">
              <span style="background:white;padding:5px">
                Please enter at least {{registrationUserNameForm.get('userName').errors.minlength.requiredLength}}
                characters.
              </span>
            </mat-error>
            <mat-error role="none" class="error-message" *ngIf="(registrationUserNameForm.get('userName').invalid && registrationUserNameForm.get('userName').errors &&
              (registrationUserNameForm.get('userName').dirty || registrationUserNameForm.get('userName').touched)) && registrationUserNameForm.get('userName').hasError('maxlength')">
              <span style="background:white;padding:5px">
                Please enter no more than {{registrationUserNameForm.get('userName').errors.maxlength.requiredLength}}
                characters.
              </span>
            </mat-error>
            <mat-error role="none" class="error-message" *ngIf="(registrationUserNameForm.get('userName').invalid && registrationUserNameForm.get('userName').errors &&
              (registrationUserNameForm.get('userName').dirty || registrationUserNameForm.get('userName').touched)) && registrationUserNameForm.get('userName').hasError('pattern')">
              <span style="background:white;padding:5px">
                Please a valid User Name.
              </span>
            </mat-error>

          </mat-form-field>
        </li>

        <app-password [showPassword]="!hidePw"
                      [parentForm]="registrationUserNameForm"
                      [parentFormGroupName]="'passwordGroup'"
                      [showEyeIcon]="'true'"
                      formGroupName="passwordGroup">
        </app-password>
        <mat-divider class="mat-divider"></mat-divider>
        <div class="mb-20 mt-20">
          <label>Please provide your personal information for your user profile.</label>
        </div>

        <!-- <li role="none">
    <label for="firstName">First Name: *</label>
    <mat-form-field [@.disabled]="animationsDisabled">
      <input matInput class="input" formControlName="firstName" name="firstName" required id="firstName"
        autocomplete="off" aria-label="First name" />
        <mat-error  role="none" class="error-message" *ngIf="(registrationUserNameForm.get('firstName').invalid &&
        registrationUserNameForm.get('firstName').errors &&
        (registrationUserNameForm.get('firstName').dirty || registrationUserNameForm.get('firstName').touched)) && registrationUserNameForm.get('firstName').hasError('required')">
          <span style="background:white;padding:5px">
          First Name is required.</span>
        </mat-error>
        <mat-error  role="none" class="error-message" *ngIf="(registrationUserNameForm.get('firstName').invalid &&
        registrationUserNameForm.get('firstName').errors &&
        (registrationUserNameForm.get('firstName').dirty || registrationUserNameForm.get('firstName').touched)) && registrationUserNameForm.get('firstName').hasError('pattern')">
          <span style="background:white;padding:5px">
          Please enter a valid First name.</span>
        </mat-error>
        <mat-error  role="none" class="error-message" *ngIf="(registrationUserNameForm.get('firstName').invalid &&
        registrationUserNameForm.get('firstName').errors &&
        (registrationUserNameForm.get('firstName').dirty || registrationUserNameForm.get('firstName').touched)) && registrationUserNameForm.get('firstName').hasError('minlength')">
          <span style="background:white;padding:5px">
          Please enter at least {{registrationUserNameForm.get('firstName').errors.minlength.requiredLength}}
          characters.</span>
        </mat-error>
        <mat-error  role="none" class="error-message" *ngIf="(registrationUserNameForm.get('firstName').invalid &&
        registrationUserNameForm.get('firstName').errors &&
        (registrationUserNameForm.get('firstName').dirty || registrationUserNameForm.get('firstName').touched)) && registrationUserNameForm.get('firstName').hasError('maxlength')">
          <span style="background:white;padding:5px">
          Please enter no more than {{registrationUserNameForm.get('firstName').errors.maxlength.requiredLength}}
          characters.</span>
        </mat-error>

    </mat-form-field>
  </li>
  <li role="none">
    <label for="lastName">Last Name: *</label>
    <mat-form-field [@.disabled]="animationsDisabled">
      <input matInput class="input" formControlName="lastName" name="lastName" required id="lastName"
        autocomplete="off" aria-label="Last name" [readonly] = "showLastName"/>
        <mat-error  role="none" class="error-message" *ngIf="(registrationUserNameForm.get('lastName').invalid &&
        registrationUserNameForm.get('lastName').errors &&
        (registrationUserNameForm.get('lastName').dirty || registrationUserNameForm.get('lastName').touched)) && registrationUserNameForm.get('lastName').hasError('required')">
          <span style="background:white;padding:5px">
          Last Name is required.</span>
        </mat-error>
        <mat-error  role="none" class="error-message" *ngIf="(registrationUserNameForm.get('lastName').invalid &&
        registrationUserNameForm.get('lastName').errors &&
        (registrationUserNameForm.get('lastName').dirty || registrationUserNameForm.get('lastName').touched)) && registrationUserNameForm.get('lastName').hasError('pattern')">
          <span style="background:white;padding:5px">
          Please enter a valid Last name.</span>
        </mat-error>
        <mat-error  role="none" class="error-message" *ngIf="(registrationUserNameForm.get('lastName').invalid &&
        registrationUserNameForm.get('lastName').errors &&
        (registrationUserNameForm.get('lastName').dirty || registrationUserNameForm.get('lastName').touched)) && registrationUserNameForm.get('lastName').hasError('minlength')">
          <span style="background:white;padding:5px">
          Please enter at least {{registrationUserNameForm.get('lastName').errors.minlength.requiredLength}}
          characters.</span>
        </mat-error>
        <mat-error  role="none" class="error-message" *ngIf="(registrationUserNameForm.get('lastName').invalid &&
        registrationUserNameForm.get('lastName').errors &&
        (registrationUserNameForm.get('lastName').dirty || registrationUserNameForm.get('lastName').touched)) && registrationUserNameForm.get('lastName').hasError('maxlength')">
          <span style="background:white;padding:5px">
          Please enter no more than {{registrationUserNameForm.get('lastName').errors.maxlength.requiredLength}}
          characters.</span>
        </mat-error>

    </mat-form-field>
  </li>-->

        <li role="none" *ngIf="isLiabilityClaim == false">
          <label for="personalEmail">Email: *</label>
          <mat-form-field [@.disabled]="animationsDisabled">
            <input matInput class="input" type="email" formControlName="personalEmail" name="personalEmail" required
                   id="personalEmail" autocomplete="off" aria-label="Personal email" />
            <mat-error role="none" class="error-message"
                       *ngIf="(registrationUserNameForm.get('personalEmail').invalid &&
           registrationUserNameForm.get('personalEmail').errors &&
           (registrationUserNameForm.get('personalEmail').dirty || registrationUserNameForm.get('personalEmail').touched)) && registrationUserNameForm.get('personalEmail').hasError('required')">
              <span style="background:white;padding:5px">
                Personal Email is required.
              </span>
            </mat-error>
            <mat-error role="none" class="error-message"
                       *ngIf="(registrationUserNameForm.get('personalEmail').invalid &&
           registrationUserNameForm.get('personalEmail').errors &&
           (registrationUserNameForm.get('personalEmail').dirty || registrationUserNameForm.get('personalEmail').touched)) && registrationUserNameForm.get('personalEmail').hasError('pattern')">
              <span style="background:white;padding:5px">
                Please enter a valid Email.
              </span>
            </mat-error>

          </mat-form-field>
        </li>

        <li role="none" *ngIf="isLiabilityClaim == false">
          <label for="mobileNumber">Cell Phone Number:</label>
          <app-phone [required]="false"
                     [phoneLabel]="'Cell Phone'"
                     [parentForm]="registrationUserNameForm"
                     [parentFormGroupName]="'mobileNumber'"
                     [@.disabled]="animationsDisabled" formControlName="mobileNumber" name="mobileNumber"></app-phone>
        </li>
        <mat-divider class="mat-divider"></mat-divider>


        <app-registration-securityquestions (onChangeShowHide)="showHideAnswers($event)"
                                            [regSecurityQuesionsForm]="registrationUserNameForm">
        </app-registration-securityquestions>

        <div fxLayout="row" fxLayoutAlign="end center">
          <button type="button" class="whiteButton" mat-raised-button (click)="onCancel()">
            <mat-icon class="reg-icon" aria-hidden="true">block</mat-icon>CANCEL
          </button>

          <!---- <span role="button" class="sr-only"
       aria-label="Submit"
       tabindex="registrationUserNameForm.valid ? '-1' : '0'"
       [attr.title]="registrationUserNameForm.valid ? '' : 'disabled until mandatory fields are missing'">
     </span>-->
          <button type="submit" [disabled]="!registrationUserNameForm.valid" class="button" (click)="onSubmit()"
                  mat-raised-button aria-label="Submit">
            <mat-icon class="reg-icon" aria-hidden="false">check</mat-icon>SUBMIT
          </button>
        </div>

      </ul>
      <p>
        <toast *ngIf="personalInfoErrorMessage != ''" [message]="personalInfoErrorMessage" [type]="toastEnumOptions.Error"></toast>
      </p>

    </form>

  </div>
</div>
<p fxLayout="column" fxFlexAlign="center" class="registrationContent">
  <toast *ngIf="registrationSuccess != ''" [message]="registrationSuccess" [type]="toastEnumOptions.Success"></toast>
</p>
<!-- form end -->
<!-- Choose User name END -->
