import { Injectable } from "@angular/core";
import { AbstractService } from "./abstract.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { ValidateClaimResult } from "../../shared/models/ValidateClaimResult";
import { Registration, RegistrationExport } from '../../shared/models/registration';
import { SsoRegistration } from "src/app/shared/models/ssorequest";

/**
 * Service with HTTP methods
 */
@Injectable({ providedIn: "root" })
export class RegistrationService extends AbstractService {
    constructor(protected http: HttpClient) {
        super(http);
    }

    /**
     * Validate user claim number
     * @param claimNumber
     */
    ValidateClaimNumber(claimNumber: string): Observable<ValidateClaimResult> {
        //Should make this pass in a ValidateClaimNumberRequest Object to match Web API.
        let data = {claimNumber: claimNumber};
        return super.postData<ValidateClaimResult>("/Registration/ValidateClaimNumber", data);
    }

    ProcessRegistration(registrationRequest: RegistrationExport): Observable<RegistrationExport> {
        return super.postData<RegistrationExport>("/Registration/ProcessRegistration", registrationRequest);
    }

    Register(registrationRequest: RegistrationExport): Observable<RegistrationExport> {
        return super.postData<RegistrationExport>("/Registration/Register",registrationRequest);
    }

    SearchForRegistrationClaim(registrationRequest: RegistrationExport): Observable<RegistrationExport> {
        return super.postData<RegistrationExport>("/Registration/SearchForRegistrationClaim", registrationRequest);
    }

    RegisterSamlUser(request: RegistrationExport): Observable<SsoRegistration>{
      return super.postData<SsoRegistration>("/Registration/RegisterSamlUser", request);
    }

}
