import { Injectable } from "@angular/core";
import { AbstractService } from "./abstract.service";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { LoggingRequest } from '../../shared/models/log';
import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";

@Injectable({
  providedIn: 'root'
})
@Injectable({ providedIn: "root" })
export class LoggingService {
  constructor(protected http: HttpClient) {

  }

  log(request: LoggingRequest): Observable<any>
  {
    request.message = this.htmlDecode(request.message);
    return this.postData<LoggingRequest>("/Logging/Log", request);
  }

  protected postData<T>(url:string,data:any, token:any = null):Observable<T>{
    if (token == null) {
      return this.http
      .post<T>(`api${url}`,data)
    .pipe(catchError(this.handleError));
    }
    else {
      return this.http.post<T>(`api${url}`, data,
      {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        //responseType: 'text'
      }).pipe(catchError(this.handleError));
    }
  }

  protected handleError = (error: HttpErrorResponse) => {
    if (error == undefined || error.error == undefined)
      return throwError("Cannot Get Accuracy");
    return throwError(error.error.message);
  };

  private htmlDecode (str) {
    var div = document.createElement("div");
    div.innerHTML = str;
    return div.textContent || div.innerText;
}
}
