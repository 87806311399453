import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment, Route, UrlMatchResult, UrlSegmentGroup } from '@angular/router';
import { LoginPageComponent } from './login/pages/login-page/login-page.component';
import { MainLayoutComponent } from './core/pages/main-layout/main-layout.component';
import { ForgotPasswordComponent } from './login/pages/forgot-password/forgot-password.component';
import { MultiFactorAuthenticationComponent } from './login/pages/multi-factor-authentication/multi-factor-authentication.component';
import { RegistrationComponent } from './component/registration/registration.component';
import { ChangePasswordComponent } from './component/change-password/change-password.component';
import { ForgotUsernameComponent } from './login/pages/forgot-username/forgot-username.component';
import { TechnicalDifficultiesComponent } from './login/pages/technical-difficulties/technical-difficulties.component';
import { UnlockAccountComponent } from './component/unlock-account/unlock-account.component';
import { DashLayoutComponent } from './core/pages/dash-layout/dash-layout.component';
import { SsoComponent } from './login/pages/sso/sso.component';

const routes: Routes = [
  { path: '', component: LoginPageComponent  },
  { path: 'login', component: LoginPageComponent  },
  { path: 'register',
    component: DashLayoutComponent,
    children: [
      {path: '', component: RegistrationComponent}
    ]
  },
  {
    path: 'forgotPassword',
    component: DashLayoutComponent,
    children: [
      { path: '', component: ForgotPasswordComponent }
    ]
  },
  {
    path: 'forgotusername',
    component: DashLayoutComponent,
    children: [
      { path: '', component: ForgotUsernameComponent }
    ]
  },
  {
    path: 'technicaldifficulties',
    component: DashLayoutComponent,
    children: [
      { path: '', component: TechnicalDifficultiesComponent }
    ]
  },
  {
    path: 'multiFactorAuthentication',
    component: DashLayoutComponent,
    children: [
      { path: '', component: MultiFactorAuthenticationComponent }
    ]
  },
  { path: 'changePassword',
    component: DashLayoutComponent,
    children: [
      { path: '', component: ChangePasswordComponent }
    ]
  },
  {
    path: 'changepwd',
    component: DashLayoutComponent,
    children: [
      { path: '', component: ChangePasswordComponent }
    ]
  },
  { path: 'unlockAccount',
  component: DashLayoutComponent,
    children: [
      { path: '', component: UnlockAccountComponent }
    ]
  },
  {
    path: 'sso/:status',
    component: DashLayoutComponent,
      children: [
        { path: '', component: SsoComponent }
      ]
  },
  { path: ':vanitypath', component: LoginPageComponent  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

