<mat-card id="pwInstructionsToast"  class="registration-toast" fxLayout="column" fxFlexAlign="space-around">
  <span class="margin-5" tabindex="0"> Your new password must have the following characteristics:</span>
  <ul>

    <li fxFlexAlign="space-around" class="margin-5" fxLayout="row" tabindex="0">
      <mat-icon class="reg-icon">info</mat-icon>

      <span >8-16 characters long.</span>
    </li>
    <li fxFlexAlign="space-around" class="margin-5" fxLayout="row" tabindex="0">
      <mat-icon class="reg-icon">info</mat-icon> Contain atleast one letter and
      one number.
    </li>
    <li fxFlexAlign="space-around" class="margin-5" fxLayout="row" tabindex="0">
      <mat-icon class="reg-icon">info</mat-icon> Minimum of one letter must be
      upper case.
    </li>
    <li fxFlexAlign="space-around" class="margin-5" fxLayout="row" tabindex="0">
      <mat-icon class="reg-icon">info</mat-icon> Have one of only the following
      special characters @#$%^&+=!
    </li>
    <li fxFlexAlign="space-around" class="margin-5" fxLayout="row" tabindex="0">
      <mat-icon class="reg-icon">info</mat-icon> Different than your username.
    </li>
    <li fxFlexAlign="space-around" class="margin-5" fxLayout="row" tabindex="0">
      <mat-icon class="reg-icon">info</mat-icon> Different than your current
      password and previous 12 passwords.
    </li>
    <li fxFlexAlign="space-around" class="margin-5" fxLayout="row" tabindex="0">
      <mat-icon class="reg-icon">info</mat-icon> Must not be the same as a password
      used in the last 90 days.
    </li>
    </ul>
  </mat-card>
