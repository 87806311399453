import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { FormValidatorsService } from '../../../shared/services/form-validators.service';
import { ErrorMessageLogicService } from '../../../shared/services/error-message-logic.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { ToastOptions } from '../../../shared/models/toast-options.enum';
import { ValidateVerificationCode, ValidateVerificationCodeRequest, VerificationCodeRequest } from 'src/app/shared/models/request';
import { UserService } from 'src/app/service/user.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { SsoRequest } from 'src/app/shared/models/ssorequest';
import { ToastPopUpComponents } from 'src/app/shared/services/toast-popup.service';
import { LoginStatus } from 'src/app/shared/models/loginrequest';
import { UserContext } from 'src/app/shared/models/UserContext';

@Component({
  selector: 'app-multi-factor-authentication',
  templateUrl: './multi-factor-authentication.component.html',
  styleUrls: ['./multi-factor-authentication.component.scss']
})

export class MultiFactorAuthenticationComponent implements OnInit {
  pageTitle: string;
  showTwoFactorForm: boolean;
  verificationMethodForm: UntypedFormGroup;
  enterVerificationCodeForm: UntypedFormGroup;
  emailGroupHasErrors: boolean;
  textGroupHasErrors: boolean;
  chooseVerificationErrorMessage: string;
  chooseVerificationError: boolean;
  verificationDestination: string;
  hiddenCodeDestination: string;
  enterVerificationToast: string;
  verificationCodeSent: boolean;
  redirectUserToSite: boolean;
  verificationTestMessage: string;
  emailPreference: string;
  phonePreference: string;
  emailDestination: string;
  phoneDestination: string;
  emailPreferenceGroup: UntypedFormGroup;
  chooseVerificationSubmitClicked: boolean;
  componentId: string = "Mfa";
  hiddenEmail: string;
  initialMfaToast: string;
  toastEnumOptions = ToastOptions;
  toastPopUpOptions = ToastPopUpComponents;
  oneTimeCodeToast: string = "Your authentication code is a one-time code. You do not have to remember the code.";
  username: string;
  expiredMessage: string = 'Verification code has expired. Press Resend Code to send another code to your registered email address.';
  lockoutMessage: string= `You have been locked out of your mySedgwick account. Please contact Sedgwick Support Services at (866) 647-7610 or answer <span tabindex='0' class='spanLink' data-function=${this.toastPopUpOptions.unlockAccountPage} >your security questions</span> to unlock your account.`;
  invalidMessage: string = 'The authentication code you entered is not valid. If you feel you have received this message in error re-enter your code or select the Resend Code button to receive a new code.';
  ssoTfaErrorMessage: string = `You have no valid authentication delivery methods on file.  Please contact your HR Department`;
  showSsoErrorMessage: boolean;
  newCodeToast: string;
  verificationCodeReSent: boolean;
  codeStatus: string;
  showErrorToast: boolean;
  errorToastMessage: string;
  accountLocked: boolean;
  showRememberMeBox: boolean;
  userContext: UserContext;
  ssoRegistration: boolean;

  authCodeTitle: string = this.oneTimeCodeToast.replace('-', ' ');
  @ViewChild('authCodefocus') authCodefocus : ElementRef;

  constructor(private router: Router,
              private fb: UntypedFormBuilder,
              private userService: UserService,
              private formValidator: FormValidatorsService,
              private showErrorLogic: ErrorMessageLogicService,
              private redirectService: RedirectService,
              public utility: UtilityService) { }

  get deliverPreference() {
    return this.verificationMethodForm.get('deliverPreference') as UntypedFormControl;
  }

  get rememberDeviceCheck() {
    return this.enterVerificationCodeForm.get('rememberDeviceCheck') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.username = this.userService.username;
    this.pageTitle = 'Authentication Required';
    this.userContext = this.userService.UserContext;
    this.ssoRegistration = this.userService.ssoRegistration;
    //Username should only be null if someone times out on this page or refresh. If this happens
    //I believe the best course of action is to just redirect them to the login page.
    if(this.utility.isNullOrEmpty(this.username))
      this.onCancel();

    this.showTwoFactorForm = true;
    this.redirectUserToSite = false;
    this.loadAuthorizationPreferences();
    //If we end up initializing the rememberDeviceCheck to true we need to add
    //check to make sure its hardcoded to false for Liability lob
    this.enterVerificationCodeForm = this.fb.group({
      authCode: ['', {validators: [Validators.required]}],
      rememberDeviceCheck: [false]
    });

    this.setInitialMFAToast();
    this.verificationMethodForm = this.buildChooseVerificationPreferenceForm();

    this.showSsoErrorMessage = this.ssoRegistration && this.utility.isNullOrEmpty(this.emailPreference) &&
                               this.utility.isNullOrEmpty(this.phonePreference);

    this.deliverPreference.valueChanges.subscribe(value => {
      if (value == 'Email'){
        this.verificationMethodForm.get('emailGroup').updateValueAndValidity();
        this.verificationMethodForm.get('emailGroup.email').updateValueAndValidity();
        this.verificationMethodForm.get('emailGroup.confirmEmail')?.updateValueAndValidity();
      }
      else {
        this.verificationMethodForm.get('phoneGroup').get('areaCode')?.updateValueAndValidity();
      }
    });

    this.showRememberMeBox = this.userService.loginSubStatus != LoginStatus.PRGLAULogin;
  }

  onCancel(): void {
    this.router.navigate(['/']);
  }

  verificationMethodNext(): void{
    this.codeStatus = 'NEW';
    let isEmail = this.deliverPreference.value == "Email";
    let isText = this.deliverPreference.value == "Text";
    this.emailDestination = isEmail ? this.verificationMethodForm.get('emailGroup')?.get('email').value : null;
    this.phoneDestination= isText
        ? this.getPhoneNumberPreference()
        : null;

    this.chooseVerificationSubmitClicked = true;

    this.emailGroupHasErrors = this.checkEmailGroupError();
    this.textGroupHasErrors = this.checkTextGroupHasErrors();

    if (!this.emailGroupHasErrors && !this.textGroupHasErrors && (isEmail || isText)) {
      this.sendCodeToDevice(this.emailDestination, this.phoneDestination);
    }

    else {
      this.chooseVerificationError = true;
      if (!isEmail && !isText) {
        this.chooseVerificationErrorMessage = 'Please select a delivery method to receive your Verification code.';
      }
      if (this.emailGroupHasErrors) {
        this.showEmailGroupErrors();
      }
    }
  }

  resendVerificationCode(): void {
    this.verificationCodeReSent = true;
    this.sendCodeToDevice(this.emailDestination, this.phoneDestination);
    this.codeStatus = 'NEW';
    this.enterVerificationToast = this.newCodeToast;
    this.showErrorToast = false;
    this.enterVerificationCodeForm.get('authCode').setValue('');
    this.enterVerificationCodeForm.get('authCode').setErrors(null);
    this.authCodefocus.nativeElement.focus();
  }

  getPhoneNumberPreference(): any {
    this.verificationMethodForm.get('phoneGroup').updateValueAndValidity();
    return this.verificationMethodForm.get('phoneGroup')?.value;
  }

  enterVerificationCodeNext(): void {
    let verificationCode = this.enterVerificationCodeForm.get('authCode').value;
    let rememberDevice = this.enterVerificationCodeForm.get('rememberDeviceCheck').value;
    if (this.enterVerificationCodeForm.valid) {
      // this.verificationTestMessage = `Verification code entered. Code: ${verificationCode}, Remember Device: ${rememberDevice}`;
      // this.redirectUserToSite = true;
      let verifyRequest = this.buildValidateVerificationCode();
      this.apiValidateVerificationCode(verifyRequest);
    }
    else {
      this.enterVerificationCodeForm.get('authCode').setErrors({'required': true});
      this.enterVerificationCodeForm.get('authCode').markAsTouched();
      this.authCodefocus.nativeElement.focus();
    }
  }

  apiValidateVerificationCode(validateRequest: ValidateVerificationCodeRequest): void {
    this.userService.validateVerificationCode(validateRequest).subscribe(
      (result) => {
        if(result.status == "VALIDATED"){
          //if (this.rememberDeviceCheck.value)
          //this.verificationTestMessage = `Verification code entered. Code: ${verificationCode}, Remember Device: ${rememberDevice}`;
          this.redirectUserToSite = true;
          this.redirectService.redirectToMySedgwick(new SsoRequest(
          {
            SessionId: this.userService.sessionid,
            UserName: this.username,
            Token: this.userService.redirectToken,
            MySedgwickUrl: this.userService.mySedgwickUrl,
            ClientType: this.userContext.clientType,
            SourceUrl: this.userService.sourceUrl
          }));
        }
        else {
          this.codeStatus = result.status;
          this.accountLocked = this.showAccountLockedScreen(this.codeStatus);
          this.userService.username = validateRequest.userName;
        }
      },

      (e) => {
        let response = JSON.parse(e.response);
       console.log(response);
      }
    )
  }

  showResendButton(): boolean {
    // return this.codeStatus == "PENDING" || this.codeStatus == "EXPIRED"
    //         || this.codeStatus == "INIT" || this.codeStatus == "BLANK"
    return this.codeStatus != "ATTEMPTS_EXCEEDED";
  }

  showVerifyCodeButton(): boolean {
    return this.codeStatus != "ATTEMPTS_EXCEEDED" && this.codeStatus != "EXPIRED";
  }

  showAccountLockedScreen(codeStatus: string): boolean {
    return codeStatus == "ATTEMPTS_EXCEEDED"
  }

  setDeviceCookie(): void {

  }

  loadAuthorizationPreferences(): void {
    this.emailPreference = this.userService.emailAddress ?? '';
    this.phonePreference = this.userService.phoneNumber == "0000000000"
                            ? ''  : this.userService.phoneNumber ?? '';
  }

  setInitialMFAToast(): void {
    this.initialMfaToast = 'We need to confirm your identity before you can proceed.';
  }

  buildChooseVerificationPreferenceForm() : UntypedFormGroup{
    this.emailPreferenceGroup = this.buildEmailControlGroup();
    return this.fb.group({
      deliverPreference: [this.setInitialDeliverPreference()],
      emailGroup: this.emailPreferenceGroup,
      phoneGroup: []
    });
  }

  setInitialDeliverPreference(): string{
    return !this.utility.isNullOrEmpty(this.emailPreference)
      ? 'Email'
      : 'Text';
  }

  buildEmailControlGroup(): UntypedFormGroup {
    if (!this.emailPreference) {
     return this.fb.group({
        email: ['', {validators: [Validators.email], updateOn: "blur"}],
        confirmEmail: ['', {validators: [Validators.email], updateOn: "blur"}],
      }, { validator: this.formValidator.matchingFields('email', 'confirmEmail')});
    }

    else {
      this.hiddenEmail = this.hideEmail(this.emailPreference);
      return this.fb.group({
        email: [{value: this.emailPreference, disabled: true}]});
    }
  }

  showEmailGroupErrors(): void {
    let emailControl = this.verificationMethodForm.get('emailGroup.email');
    let confirmEmailControl = this.verificationMethodForm.get('emailGroup.confirmEmail');

    if (!emailControl.value){
      emailControl.updateValueAndValidity();
      emailControl.setErrors({'email': true});
      emailControl.markAsDirty();
    }
    if (!confirmEmailControl.value){
      confirmEmailControl.updateValueAndValidity();
      confirmEmailControl.setErrors({'email': true});
      confirmEmailControl.markAsDirty();
    }
  }

  buildSendVerificationRequest() : VerificationCodeRequest {
    return new VerificationCodeRequest({
      UserContext: this.userContext
    });
  }

  buildValidateVerificationCode(): ValidateVerificationCodeRequest {
    let validateCodeRequestObject: any = {
      userName: this.username,
      verificationCode: this.enterVerificationCodeForm.get('authCode').value,
      setTFADevice: this.enterVerificationCodeForm.get('rememberDeviceCheck').value,
      twoFactorDeviceId: "",
      UserContext: this.userContext
    };
    return validateCodeRequestObject as ValidateVerificationCodeRequest;
  }

  sendCodeToDevice(email: string, phone: any): void {
    if (!this.utility.isNullOrEmpty(email))
      this.sendCodeToEmail();
    else
      this.sendCodeToPhone();

    this.buildVerificationCodeToast(email, phone);
    this.verificationCodeSent = true;
    this.pageTitle = "Enter Authentication Code";
  }

  sendCodeToPhone(): void {
    //TO-DO: Fill with call to API for sending to moile device
    this.userService.sendTwoFactorMessage(this.buildSendVerificationRequest()).subscribe(
      (result) => {
        this.authCodefocus.nativeElement.focus();
      },

      (e) => {
        let response = JSON.parse(e.response);
       console.log(response);
      }
    )
  }

  sendCodeToEmail(): void {
    this.userService.sendVerificationCode(this.buildSendVerificationRequest()).subscribe(
      (result) => {
        this.authCodefocus.nativeElement.focus();
      },

      (e) => {
        let response = JSON.parse(e.response);
       console.log(response);
      }
    )
  }

  buildVerificationCodeToast(email: string, phone: any): void {
    let maskedPhone = this.utility.isNullOrEmpty(phone?.fourDigits) ? '' : `***-***-${phone?.fourDigits}`;
    let maskedEmail = this.utility.isNullOrEmpty(email) ? '' : this.hideEmail(email);
    this.verificationDestination = (email && phone)
      ? `Your verification code has been sent to: ${maskedEmail} and ${maskedPhone}.`
      : `Your verification code has been sent to: ${email ? maskedEmail : maskedPhone}.`;

    // let verificationToast = `Please check your email or text messages.
    //   The verification code is only valid for 10 minutes.
    //   If you do not receive your verification code within the 10 minute time period,
    //   please check your junk/spam folder.
    //   If you still have not received the email contact Sedgwick Support Services.`;

    this.enterVerificationToast = `${this.verificationDestination}`; // ${verificationToast}`;

    this.newCodeToast = `${this.verificationDestination.replace('Your verification', 'Your new verification')}`; // ${verificationToast}`;
  }



  showErrorMessageForField(formGroup: UntypedFormGroup, controlName: string): boolean {
    return this.showErrorLogic.showErrorMessage(formGroup, controlName)
  }

  checkEmailGroupError(): boolean{
    const emailDelivery = this.deliverPreference.value == 'Email';
    const emailControl = this.verificationMethodForm.get('emailGroup.email');
    const confirmEmailControl = this.verificationMethodForm.get('emailGroup.confirmEmail');


    if (emailDelivery) {
      if (this.emailPreference) {
        return false;
      }
      if (emailControl.value && emailControl.valid &&
          confirmEmailControl.value && confirmEmailControl.valid &&
          this.verificationMethodForm.get('emailGroup').valid) {
           return false;
      }
      else {
        return true;
      }
    }

    else {
      if (emailDelivery) {
        this.verificationMethodForm.get('emailGroup').setErrors([]);
        this.verificationMethodForm.get('emailGroup.email').setErrors([]);
        this.verificationMethodForm.get('emailGroup.confirmEmail')?.setErrors([]);
      }
      return false;
    }

  }

  checkTextGroupHasErrors(): boolean {
    const textNumberControl = this.verificationMethodForm.get('phoneGroup');

    if (this.deliverPreference.value == 'Text') {
      return !textNumberControl.value || textNumberControl.invalid;
    }
    return false;
  }

  hideEmail(email: string): string {
    for (let i  = 1; i < (email.indexOf('@') - 1); i++) {
      email = email.replace(email.substring(i, i +1), "*");
    }
    return email;
  }
}
