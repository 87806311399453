import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, ValidatorFn, FormArray } from '@angular/forms';
import { CaptchaComponent } from 'angular-captcha';
import { Title } from '@angular/platform-browser';
import { GlobalService } from '../../../shared/settings/global.service';
import { UserService } from 'src/app/service/user.service';
import { ValidateEmailRequest, UserStatus } from '../../../shared/models/ValidatedUser';
import { ToastOptions } from '../../../shared/models/toast-options.enum';
import { MatDialog } from '@angular/material/dialog';
import { SupportDialogComponent } from 'src/app/core/components/support-dialog/support-dialog.component';
import { ToastPopUpComponents } from 'src/app/shared/services/toast-popup.service';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  moduleId: module.id,
  selector: 'app-forgot-username',
  templateUrl: './forgot-username.component.html',
  styleUrls: ['./forgot-username.component.scss']
})
export class ForgotUsernameComponent implements OnInit {

  @ViewChild(CaptchaComponent, { static: true }) captchaComponent: CaptchaComponent;
  pageTitle: string;
  forgotUserNameForm: UntypedFormGroup;
  emailAddressQuestionText: string;
  submittedRequest: boolean=false;
  showSubmitUsername: boolean=true;
  showSupportModal: boolean=false;
  confirmationMessage: string;
  forgotUsernameRequest: ValidateEmailRequest;
  toastEnumOptions :any;
  captchaError:string;
  toastPopupOptions = ToastPopUpComponents;
  animationsDisabled = true;

  constructor(private router: Router, private fb: UntypedFormBuilder
    , private titleService: Title
    , private userService: UserService
    , private utility: UtilityService
    , public dialog: MatDialog) { }

  ngOnInit(): void {
    this.submittedRequest = false;
    this.showSubmitUsername=true;
    this.showSupportModal =false;
    this.captchaError = "Captcha Required";
    this.captchaComponent.captchaEndpoint = this.utility.determineCaptchaEndpoint();
    this.titleService.setTitle('mySedgwick - Forgot Username');
    this.pageTitle = "Forgot Username";
    this.emailAddressQuestionText = "Please provide the email address associated with your account";


    this.forgotUserNameForm = this.fb.group({
      email: ['', [Validators.required]],
      captcha: ['', [Validators.required]]
    });
  }
  onCancel(): void {
    this.router.navigate(['/']);
  }
  openSupportDialog(): void {
    const dialogRef = this.dialog.open(SupportDialogComponent);
  }
  onforgotUserNameSubmit() : void{


    if (this.forgotUserNameForm.invalid) return;

    // get the user-entered captcha code value to be validated at the backend side
    let userEnteredCaptchaCode = this.captchaComponent.userEnteredCaptchaCode;

    // get the id of a captcha instance that the user tried to solve
    let captchaId = this.captchaComponent.captchaId;

   this.forgotUsernameRequest = new ValidateEmailRequest({
     captchaId: captchaId,
     userEnteredCaptchaCode: userEnteredCaptchaCode,
     useremail: this.forgotUserNameForm.value.email,
     sendEmail: 'Y'
 });

   this.userService.validateEmail(this.forgotUsernameRequest).subscribe(
     (result) => {
       //Always return success irrespective of user status

      switch(result.userStatus) {
        case UserStatus.Valid:
        case UserStatus.NotFound:
        case UserStatus.Locked:
        case UserStatus.Disabled:
        case UserStatus.DeadClient:
        case UserStatus.ClientNotSetupYet:
            this.submittedRequest = true;
            this.showSubmitUsername=false;
            this.showSupportModal =true;
            this.confirmationMessage =`Thank You.</br>If a username is found, a reset link will be sent to you by email.</br>Please check your Inbox, Junk and Spam folders for an email prior to contacting <span role='link' tabindex='0' aria-label='contact support popup' data-function=${this.toastPopupOptions.openSupportDialog} class='spanLink'>Sedgwick Support</span>.</br>You may close this browser window.`
            this.toastEnumOptions = ToastOptions.Info
           break;

        case UserStatus.BadCaptCha:
             this.forgotUserNameForm.controls['captcha'].setErrors({ incorrect: true, message: 'Please enter valid value'});
             this.forgotUserNameForm.controls['captcha'].markAsDirty();
             this.captchaComponent.reloadImage();
             this.captchaError = "Invalid Captcha value provided";
           break;
       }
     },
     (e) => {
       //If captcha is invalid we return a badRequest, which will force this exception. Should we move badcaptcha logic here?
       this.submittedRequest = true;
       this.showSubmitUsername=false;
       this.confirmationMessage =`Thank You.</br>If a username is found, a reset link will be sent to you by email.</br>Please check your Inbox, Junk and Spam folders for an email prior to contacting <a href='javascript:void()' aria-label='contact support popup' data-function=${this.toastPopupOptions.openSupportDialog} role='link' >Sedgwick Support.</a></br>You may close this browser window.`
       this.toastEnumOptions = ToastOptions.Info

     }
   );
 }
  }

