import { Component, OnInit, ElementRef, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, ControlContainer } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserRegistrationService } from 'src/app/service/userresgistration.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { RegistrationExport, RegistrationStatus, RegistrationMethod, RegistrationOptions, SsoRegistrationRequest, RegistrationSource } from '../../shared/models/registration';
import { ToastOptions } from 'src/app/shared/models/toast-options.enum';
import { RegistrationSecurityquestionsComponent } from '../registration-securityquestions/registration-securityquestions.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastPopUpComponents } from 'src/app/shared/services/toast-popup.service';
import { DatePipe, formatDate } from '@angular/common';
import { UserService } from 'src/app/service/user.service';
import { UtilityService } from './../../shared/services/utility.service';
import { VoeService } from './../../shared/services/voe.service';
import { SsoRegistration } from 'src/app/shared/models/ssorequest';
import { LoginResponse } from 'src/app/shared/models/loginrequest';
import { Console } from 'console';
import { MatDatepicker, matDatepickerAnimations, MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';

declare var $:any;

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  animations: []
})


export class RegistrationComponent implements OnInit {
  toastPopupOptions = ToastPopUpComponents;
  public loading = false;
  animationsDisabled = true;
  haveClaimNumberModel: string;
  onHaveClaimNumberStep = false;
  onStartRegistration = false;
  onSelectUsername = false;
  isVanityRegistration = false;
  showEmployeeId = false;
  showSSN = true;
  showDOB = true;
  showZipCode = true;
  showLastName = false;
  maxZipcodeLength: number = 5;
  isAlphanumericZipCode: boolean = false;
  personalInfoErrorMessage: string = '';
  registrationProcessErrorMessage: string = '';
  registrationSuccess: string = '';
  toastEnumOptions = ToastOptions;
  registrationRequest: RegistrationExport;
  currentRegistrationExport: RegistrationExport;
  maxDate = new Date();
  regClaimNumberForm: UntypedFormGroup;
  registrationForm: UntypedFormGroup;
  registrationUserNameForm: UntypedFormGroup;
  labelEmployeeID: string;
  labelZipCode: string = "Home Postal Code";
  errorZipCode: string = "Home Postal Code is required.";
  requireClaimNumber: boolean = false;
  requireEmpId: boolean = false;
  hidePw: boolean = true;
  datePipe: DatePipe = new DatePipe('en-US');;
  registrationMethod: string = '';
  isLiabilityClaim = false;
  zipCode6CharMessage: string;
  zipCode5CharMessage: string;
  rdYes: boolean = false;
  rdNo: boolean = false;
  datePickerOpen = false;
  datePickerAriaChanged = false;
  interval_login: any;
  ssoRegistrationInfo: SsoRegistrationRequest;
  dayNamesList : string[] = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  loginToken: string;
  redirectUrl: string;
  sourceUrl: string;
  @ViewChild(RegistrationSecurityquestionsComponent) regSecQuestions: RegistrationSecurityquestionsComponent;
  @ViewChild('dob',{static:false}) dobCalender : MatDatepicker<any>;

  constructor(private router: Router,
    private formBuilder: UntypedFormBuilder,
    private titleService: Title,
    private snackBar: MatSnackBar,
    private registrationService: UserRegistrationService,
    public dialog: MatDialog,
    private userService: UserService,
    public utility: UtilityService,
    public voe: VoeService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  /* Page Load Event: Display either Vanity URL Registration or Generic Registration based of Clientcode/Vanity  */
  ngOnInit(): void {
    this.titleService.setTitle('mySedgwick - Registration');
    let clientData = JSON.parse(sessionStorage.getItem('clientData'));
    this.ssoRegistrationInfo = this.userService.ssoRegistrationRequest;
    this.sourceUrl = this.userService.mySedgwickUrl;

    if(this.ssoRegistrationInfo?.DoSsoRegistration)
      this.setSsoRegistrationInfo(clientData, this.ssoRegistrationInfo);

    this.zipCode5CharMessage = "Please do not enter dashes or spaces for postal code.";
    this.zipCode6CharMessage = "Please enter 5 characters for Home Zip Code or 6 characters for Home Postal Code. Do not enter dashes or \nspaces.";

    if (!this.utility.isNullOrEmpty(clientData.vanityPath) || !this.utility.isNullOrEmpty(clientData.clientCode)) {
      /* Vanity/Client specific */
      this.registrationRequest = new RegistrationExport();
      this.registrationRequest.clientCode = clientData.clientCode;
      this.registrationRequest.vanityPath = clientData.vanityPath == null ? "" : clientData.vanityPath;
      this.registrationRequest.SourceURL = this.sourceUrl;

      this.registrationService.ProcessRegistration(this.registrationRequest).subscribe(dataForAPI => {
        this.currentRegistrationExport = dataForAPI;
        this.registrationProcessErrorMessage = "Success";

        this.labelEmployeeID = this.voe.getAliasedLabel(this.labelEmployeeID, dataForAPI.labelAliases.employeeIdSingular);

        if (dataForAPI.status.toString() === "NotFound") {
          this.router.navigateByUrl("/technicaldifficulties");
          return;
        }
        else if (dataForAPI.status.toString() === "DeadClient") {
          //Dead Client in Juris
          this.registrationProcessErrorMessage = "You do not have permission to access mySedgwick.";
          this.requireClaimNumber = false;
          this.requireEmpId = false;
        }
        else if (dataForAPI.status.toString() === "ClientNotSetupYet") {
          //mySedgwick is not setup for client
          this.registrationProcessErrorMessage = "You do not have permission to access mySedgwick.";
          this.requireClaimNumber = false;
          this.requireEmpId = false;
        }
        else {
          this.registrationMethod = dataForAPI.method.toString();
          this.setRegistrationOptionForClient(this.registrationMethod, dataForAPI.registrationOptions.toString());
        }
      });
      this.onHaveClaimNumberStep = false;
      this.onStartRegistration = true;
      this.onSelectUsername = false;
    }
    else {
      /* Generic URL with Claim #*/
      this.currentRegistrationExport = new RegistrationExport();
      this.onHaveClaimNumberStep = true;
      this.onStartRegistration = false;
      this.onSelectUsername = false;
      this.registrationProcessErrorMessage = "Success";
      this.maxZipcodeLength = 6;
    }

    this.regClaimNumberForm = this.formBuilder.group({
      haveClaimNumber: ['', [Validators.required]],
      claimNumber: ['']
    });

    this.registrationForm = this.formBuilder.group({
      dateofBirth: ['',
        [
          Validators.required,
        ]],
      last4SSN: ['',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(4),
          Validators.pattern("^[0-9]*$")
        ]],
      employeeId: ['',
        [
          Validators.maxLength(256),
          Validators.pattern("^[0-9]*$")
        ]],
      zipCode: ['',
        [
          Validators.required,
          Validators.minLength(5),
          this.validateZipcode
        ]],
      claimNumberForReg: ['',
        [
          Validators.required
        ]],
      lastName: ['',
        [
          Validators.required
        ]],
      claimCity: ['',
        [
          Validators.required
        ]],
      claimState: ['',
        [
          Validators.required
        ]],
    });
    this.setValidators();

    this.registrationUserNameForm = this.formBuilder.group({
      userName: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        Validators.pattern('(?=.*[a-zA-Z])[a-zA-Z0-9._@-]*')
      ]],
      passwordGroup: [],
      // firstName: ['', [
      //   Validators.required,
      //   Validators.minLength(2),
      //   Validators.maxLength(50),
      //   Validators.pattern('(?=.*[a-zA-Z])[a-zA-Z0-9-.,_@\' ]*')
      // ]],
      lastName: ['', [
        //Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50),
        Validators.pattern('(?=.*[a-zA-Z])[a-zA-Z0-9-.,_@\' ]*')
      ]],
      personalEmail: ['', [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")
      ]],
      mobileNumber: ['', []]

    });
    this.setValidators();

    this.setClass();

  }

  setClass() {
    return (date: any) => {
      if (date.getDate() == 1) this.monthChange(
        {
          month: date.getMonth() + 1,
          year: date.getFullYear()
        });
    };
  }

  setValidators() {
    {
      if (this.showEmployeeId == false && this.requireEmpId == false) { //This if condition need to be changed once we remove duplicate Employee ID in html.
        this.registrationForm.get('employeeId').clearValidators();
        this.registrationForm.get('employeeId').updateValueAndValidity();
      } else {
        this.registrationForm.get('employeeId').setValidators(Validators.required);
        this.registrationForm.get('employeeId').updateValueAndValidity();
      }

      if (this.showZipCode == false) {
        this.registrationForm.get('zipCode').clearValidators();
      }

      if (this.showSSN == false) {
        this.registrationForm.get('last4SSN').clearValidators();
      }

      if (!this.requireClaimNumber) {
        this.registrationForm.get('claimNumberForReg').clearValidators();
      } else {
        this.registrationForm.get('claimNumberForReg').setValidators(Validators.required);
      }

      if (this.isLiabilityClaim == true) {
        this.registrationForm.get('lastName').setValidators(Validators.required);
        this.registrationForm.get('claimCity').setValidators(Validators.required);
        this.registrationForm.get('claimState').setValidators(Validators.required);
        if (this.showZipCode)
          this.registrationForm.get('zipCode').setValidators(Validators.required);

        this.registrationForm.get('lastName').updateValueAndValidity();
        this.registrationForm.get('claimCity').updateValueAndValidity();
        this.registrationForm.get('claimState').updateValueAndValidity();

        this.registrationForm.get('dateofBirth').clearValidators();
        this.registrationForm.get('dateofBirth').updateValueAndValidity();

        if (this.registrationUserNameForm)
          this.registrationUserNameForm.get('personalEmail').clearValidators();

      }
      else {
        this.registrationForm.get('claimCity').clearValidators();
        this.registrationForm.get('claimState').clearValidators();

        if (this.registrationUserNameForm)
          this.registrationUserNameForm.get('personalEmail').updateValueAndValidity();
      }

      if (this.registrationMethod != 'Claim') {
        this.registrationForm.get('lastName').clearValidators();
      }
      else {
        this.registrationForm.get('lastName').setValidators(Validators.required);
      }

      this.registrationForm.get('employeeId').updateValueAndValidity();
      this.registrationForm.get('zipCode').updateValueAndValidity();
      this.registrationForm.get('last4SSN').updateValueAndValidity();
      this.registrationForm.get('claimNumberForReg').updateValueAndValidity();
    }
  }

  handleChange(selectedVal: string) {
    if (selectedVal == 'NO') {
      this.regClaimNumberForm.get('claimNumber').clearValidators();
      this.regClaimNumberForm.get('claimNumber').updateValueAndValidity();
      this.rdNo = true;
      this.rdYes = false;
    } else {
      this.regClaimNumberForm.get('claimNumber').setValidators(Validators.required);
      this.regClaimNumberForm.get('claimNumber').updateValueAndValidity();
      this.rdYes = true;
      this.rdNo = false;
    }
  }

  setRegistrationOptionForClient(registrationMethod: string, registrationOption: string) {
    if (this.ssoRegistrationInfo?.DoSsoRegistration) {
      if (registrationMethod == "None") {
        registrationMethod = "Hr";
        registrationOption = "Default";
      }
    }

    switch (registrationMethod) {
      case "Hr":
        this.showLastName = false;
        this.isLiabilityClaim = false;
        switch (registrationOption) {
          case "Default": //0 DOB + Last 4 SSN + EmployeeID
            this.showZipCode = false;
            this.showEmployeeId = true;
            this.showSSN = true;
            this.showDOB = true;
            break;
          case "Ssn4Zip": //1
            this.showZipCode = true;
            this.showEmployeeId = false;
            this.showSSN = true;
            this.showDOB = true;
            break;
          case "EmpIdZip": //2
            this.showSSN = false;
            this.showEmployeeId = true;
            this.showDOB = true;
            this.showZipCode = true;
            this.maxZipcodeLength = 6;
            this.isAlphanumericZipCode = true;
            break;
          case "EmpIdZip6"://3
            this.showSSN = false;
            this.showEmployeeId = true;
            this.showDOB = true;
            this.showZipCode = true;
            this.maxZipcodeLength = 6;
            this.isAlphanumericZipCode = true;
            //showClaimNumberInfo(false);
            break;
          case "SsnHomeZip"://4
            this.showSSN = true;
            this.showEmployeeId = false;
            this.showDOB = true;
            this.showZipCode = true;
            break;
          case "Ssn4EmpIdZip"://5
            this.showSSN = true;
            this.showEmployeeId = true;
            this.showDOB = true;
            this.showZipCode = true;
            break;
          /*case "PRClaim":
            break;
          case "GLClaim":
            break;
          case "AUClaim":
            break;*/
          /*case "LiabilityClaim":
            this.showSSN = false;
            this.showEmployeeId = false;
            this.showDOB = false;
            this.showZipCode = false;
            this.requireClaimNumber = true;
            this.isLiabilityClaim = true;
            break;*/
        }
        break;
      case "Claim": //CLAIM Registration RegistrationMethod.Claim
        this.showLastName = true;
        this.requireClaimNumber = true;

        switch (registrationOption) {
          case "LiabilityClaim":
            this.showSSN = false;
            this.showEmployeeId = false;
            this.showDOB = false;
            this.showZipCode = true;
            this.maxZipcodeLength = 5;
            this.isLiabilityClaim = true;
            this.labelZipCode = "Postal Code";
            this.errorZipCode = "Postal Code is required.";
            break;
          case "LiabilityCityState":
            this.showSSN = false;
            this.showEmployeeId = false;
            this.showDOB = false;
            this.showZipCode = false;
            this.isLiabilityClaim = true;
            break;
        }
      default:
        break;

    }

    this.setValidators();
  }

  /* From Generic URL */
  moveToRegistration() {
    if (!this.regClaimNumberForm.invalid) {
      if (this.regClaimNumberForm.get('haveClaimNumber').value == "YES") {
        sessionStorage.setItem('claimnumber', JSON.stringify(this.regClaimNumberForm.value.claimNumber));
        this.currentRegistrationExport.claimNumber = this.regClaimNumberForm.value.claimNumber;
        this.currentRegistrationExport.Screen = "HaveClaimNum";
        this.registrationService.SearchForRegistrationClaim(this.currentRegistrationExport).subscribe(data => {
          this.currentRegistrationExport = data;
          this.registrationForm.setValue({
            claimNumberForReg: this.regClaimNumberForm.value.claimNumber,
            dateofBirth: "",
            last4SSN: "",
            zipCode: "",
            employeeId: "",
            lastName: "",
            claimCity: "",
            claimState: ""
          });
          if (data.validClaimFound == true) {
            sessionStorage.setItem('user', JSON.stringify(data));
            this.onHaveClaimNumberStep = false;
            this.onStartRegistration = true;
            this.onSelectUsername = false;
            this.personalInfoErrorMessage = "";
            this.labelEmployeeID = this.voe.getAliasedLabel(this.labelEmployeeID, data.labelAliases.employeeIdSingular);
            this.setRegistrationOptionForClient(data.method.toString(), data.registrationOptions.toString());
            const clientData = JSON.parse(sessionStorage.getItem('clientData'));
            clientData.loggedinImage = data.loggedinImage;
          }
          else {
            this.handleRegistrationStatus(data.status.toString(), data)
            sessionStorage.setItem('user', JSON.stringify(data));
            this.onHaveClaimNumberStep = true;
            this.onStartRegistration = false;
            this.onSelectUsername = false;
          }
        }, error => {
          this.handleErr(error);
        });
      }
      else {
        this.personalInfoErrorMessage = "";
        this.onHaveClaimNumberStep = false;
        this.onStartRegistration = true;
        this.onSelectUsername = false;
      }

    }
  }

  setSsoRegistrationInfo(clientData: any, ssoRequest: SsoRegistrationRequest) {
    clientData.clientCode = ssoRequest.ClientCode;
    this.loginToken = this.userService.redirectToken;
    this.redirectUrl = this.userService.mySedgwickUrl;
  }

  processSsoRegistration(request: RegistrationExport) {
    this.registrationService.RegisterSamlUser(request).subscribe(registrationData => {

      //If success insert logic for redirect to TFA?
      // Handle error statuses
      this.handleSsoRegistrationStatus(registrationData.registration.status.toString(), registrationData);
    })
  }

  /* Valid User found - Display Username screen */
  moveToUserNameSelection() {
    if (!this.registrationForm.invalid) {
      this.getRegistrationInformation();
      this.personalInfoErrorMessage = "";
      this.currentRegistrationExport.Screen = "Registration";
      this.currentRegistrationExport.claimNumber = this.registrationForm.value.claimNumberForReg.trim();
      this.currentRegistrationExport.labelAliases = null;
      if (this.ssoRegistrationInfo?.DoSsoRegistration)
        this.currentRegistrationExport.registrationSource = RegistrationSource.ViaoneView;

      this.registrationService.ProcessRegistration(this.currentRegistrationExport).subscribe(dataForAPI => {
        if (dataForAPI.labelAliases != null)
          this.labelEmployeeID = this.voe.getAliasedLabel(this.labelEmployeeID, dataForAPI.labelAliases.employeeIdSingular);
        this.registrationUserNameForm.setValue({
          lastName: this.registrationForm.value.lastName,
          //firstName: "",
          userName: "",
          passwordGroup: "",
          personalEmail: "",
          mobileNumber: "",
        });

        if (this.ssoRegistrationInfo?.DoSsoRegistration && dataForAPI.status.toString() == "Valid") {
          this.currentRegistrationExport = dataForAPI;
          this.currentRegistrationExport.username = this.ssoRegistrationInfo?.Username;
          this.currentRegistrationExport.sessionId = this.ssoRegistrationInfo.SessionId;
          this.processSsoRegistration(this.currentRegistrationExport);
        }
        else {
          this.handleRegistrationStatus(dataForAPI.status.toString(), dataForAPI);
          this.setValidators();
        }
      });

    }
  }

  /* UserName/Password accepted - Register User*/
  onSubmit() {
    if (!this.registrationUserNameForm.invalid && !this.regSecQuestions.regSecurityQuesionsForm.invalid) {
      this.doBuildRegistrationModel();
      this.currentRegistrationExport.Screen = "CreateUser";
      this.currentRegistrationExport.labelAliases = null;
      this.registrationService.Register(this.currentRegistrationExport).subscribe(dataForAPI => {

        switch (dataForAPI.status.toString()) {
          case "Success":
            this.personalInfoErrorMessage = '';
            this.onSelectUsername = false;
            this.registrationSuccess = `Your registration was successful.<br aria-hidden='true'/>
              You will receive a confirmation email at the address you provided during the registration process. You can now login to <a href='/' class='spanLink'>mySedgwick</a>.`;
            break;
          default:
            this.handleRegistrationFailStatus(dataForAPI.status.toString());
            break;
        }
      });
    }
  }

  onCancel(): void {
    this.router.navigate(['/']);
  }

  getRegistrationInformation() {
    this.currentRegistrationExport.claimCity = this.registrationForm.value.claimCity.trim();
    this.currentRegistrationExport.claimState = this.registrationForm.value.claimState.trim();
    this.currentRegistrationExport.lastName = this.registrationForm.value.lastName.trim();
    this.currentRegistrationExport.zipCode = this.registrationForm.value.zipCode.trim();
    this.currentRegistrationExport.employeeId = this.registrationForm.value.employeeId.trim();
    this.currentRegistrationExport.ssn = this.registrationForm.value.last4SSN.trim();

    const dob = this.utility.isNullOrEmpty(this.registrationForm.value.dateofBirth) ?
      this.registrationForm.value.dateofBirth :
      this.datePipe.transform(this.registrationForm.value.dateofBirth.toDateString(), 'M/d/yyyy');
    this.currentRegistrationExport.dateOfBirth = dob;
    //this.currentRegistrationExport.email = this.registrationForm.value.email;

    if (this.currentRegistrationExport.registrationOptions === 6 ||
      this.currentRegistrationExport.registrationOptions === 7 ||
      /*
            [KP] As per old code we can additional registration options but
            I cannot see those in Old enum so, I am commenting following
            conditions till figure out remaining requirements.

      this.currentRegistrationExport.registrationOptions === 19 ||
      this.currentRegistrationExport.registrationOptions === 20 ||*/
      this.currentRegistrationExport.registrationOptions === 8) {
      this.currentRegistrationExport.claimNumber = this.registrationForm.value.claimNumberForReg.trim();
      this.currentRegistrationExport.claimCity = this.registrationForm.value.claimCity.trim();
      this.currentRegistrationExport.claimState = this.registrationForm.value.claimState.trim();
      this.currentRegistrationExport.zipCode = this.registrationForm.value.zipCode.trim();
    }

    if (!this.utility.isNullOrEmpty(this.currentRegistrationExport.clientCode)) {

      if (!this.utility.isNullOrEmpty(this.registrationForm.value.employeeId)) {
        this.currentRegistrationExport.employeeId = this.registrationForm.value.employeeId.trim();
      }
      if (this.currentRegistrationExport.method.toString() === "Claim") {
        this.currentRegistrationExport.claimNumber = this.registrationForm.value.claimNumberForReg.trim();
        this.currentRegistrationExport.zipCode = this.registrationForm.value.zipCode.trim();
        this.currentRegistrationExport.lastName = this.registrationForm.value.lastName.trim();
      }
      if (this.currentRegistrationExport.registrationOptions === 6
        /*
            [KP] As per old code we can additional registration options but
            I cannot see those in Old enum so, I am commenting following conditions
            till figure out remaining requirements.

        || this.currentRegistrationExport.registrationOptions === 9 ||
        this.currentRegistrationExport.registrationOptions === 19 ||
        this.currentRegistrationExport.registrationOptions === 20*/
      ) {
        this.currentRegistrationExport.claimNumber = this.registrationForm.value.claimNumberForReg.trim();
        this.currentRegistrationExport.claimCity = this.registrationForm.value.claimCity.trim();
        this.currentRegistrationExport.claimState = this.registrationForm.value.claimState.trim();
        this.currentRegistrationExport.zipCode = this.registrationForm.value.zipCode.trim();
      }
    }
    /*       this.currentRegistrationExport.sourceSystem = "";
          this.currentRegistrationExport.subSystem = ""; */
  }

  handleSsoRegistrationStatus(status: string, dataForApi: SsoRegistration) {
    switch (status) {
      case "Success":
        this.personalInfoErrorMessage = ""
        this.userService.createSsoTwoFactorRequest(new SsoRegistration({ loginResponse: dataForApi.loginResponse })).subscribe(result => {
          if (result) {
            this.navigateToSsoTfa(dataForApi.loginResponse);
          }
        });
        break;
      default:
        this.personalInfoErrorMessage = `We are unable to proceed with your registration using the information you provided. Please
              confirm your entry and try again. For additional assistance,
              <a tabindex='0' class='spanLink' data-function=${this.toastPopupOptions.openSupportDialog}> Contact Technical Support</a>
              or your HR department for more information.`;
    }
  }

  navigateToSsoTfa(results: LoginResponse): void {
    this.userService.emailAddress = results.emailAddress;
    this.userService.phoneNumber = results.phone;
    this.userService.username = results.username;
    this.userService.ssoRegistration = true;
    this.userService.sessionid = results.sessionId;
    this.userService.redirectToken = this.loginToken;
    this.userService.mySedgwickUrl = this.redirectUrl;
    this.router.navigate(['multiFactorAuthentication']);
  }

  handleRegistrationStatus(status: string, dataForAPI: RegistrationExport) {

    switch (status) {
      case "Valid":
        /* If its Valid - we need to reset currentRegistrationExport*/
        this.currentRegistrationExport = dataForAPI;
        this.onHaveClaimNumberStep = false;
        this.onStartRegistration = false;
        this.onSelectUsername = true;
        this.personalInfoErrorMessage = "";
        break;
      case "UserAlreadyExists":
        this.personalInfoErrorMessage = `You already have an existing account for mySedgwick. <br aria-hidden='true'/>
          Please use your
          <a tabindex='0' class='spanLink' aria-label='username/password Redirects to forgot password page' role='link'  data-function=${this.toastPopupOptions.forgotPassword}
          routerLink="/forgotPassword">username/password</a> to login.`;
        break;
      /* case "TerminatedEmployee":
         this.personalInfoErrorMessage = `Your account information has been located in our system,
           but is currently in an inactive state.<br  aria-hidden='true'/> Please contact your HR department if you are receiving this message in error.`;
         break;*/
      case "MultipleContract":
        this.currentRegistrationExport.status = dataForAPI.status;
        this.currentRegistrationExport.statusAsString = dataForAPI.statusAsString;
        if (!this.utility.isNullOrEmpty(dataForAPI.claimNumber))
          this.personalInfoErrorMessage = `We are unable to proceed with your registration using the information you provided. Please
              <a tabindex='0' role='link' aria-label='Redirects to Contact support' class='spanLink' data-function=${this.toastPopupOptions.openSupportDialog}> Contact Support</a> or your HR department for more information.`;
        else {
          if (dataForAPI.contractNumber > 0)
            this.personalInfoErrorMessage = `We are unable to proceed with your registration using the information you provided. Please
              <a tabindex='0' role='link' aria-label='Redirects to Contact support'  class='spanLink' data-function=${this.toastPopupOptions.openSupportDialog}> Contact Support</a> or your HR department for more information.`;
          else {
            this.requireClaimNumber = true;
            this.personalInfoErrorMessage = `We are unable to proceed with your registration using the information you provided,
              please provide the claim number. If you do not have a claim number please
              <a tabindex='0' role='link' aria-label='Redirects to Contact support'  class='spanLink' data-function=${this.toastPopupOptions.openSupportDialog}> Contact Support</a> or your HR department for more information.`;
          }

        }

        break;
      case "NotFound":
        if (!this.utility.isNullOrEmpty(dataForAPI.claimNumber))
          this.personalInfoErrorMessage = `We are unable to proceed with your registration using the information you provided. Please
              <a tabindex='0' role='link' aria-label='Redirects to Contact support'  class='spanLink' data-function=${this.toastPopupOptions.openSupportDialog}> Contact Support</a> or your HR department for more information.`;
        else
          this.personalInfoErrorMessage = `We are unable to proceed with your registration using the information you provided,
            please provide the claim number. If you do not have a claim number please
            <a tabindex='0' role='link' aria-label='Redirects to Contact support'  class='spanLink' data-function=${this.toastPopupOptions.openSupportDialog}> Contact Support</a> or your HR department for more information.`;
        break;
      /* case "Success":
        this.personalInfoErrorMessage = ``;
        break;
      case "Fail":
        this.personalInfoErrorMessage = ``;
        break; */
      case "Denied":
        this.personalInfoErrorMessage = ``;
        //personalInfoErrorMessage(model().GenericEmployeeLandingMessage.Text);
        break;
      /*             case "PasswordInvalid":
                    this.personalInfoErrorMessage = ``;
                    break; */
      case "HrClientNoMatch":
      case "HrClientNoMatchTerminated":
        this.personalInfoErrorMessage = `We are unable to proceed with your registration using the information you provided.<br aria-hidden='true'/> Please confirm your entry and try again. For additional assistance,
        <a tabindex='0' role='link' aria-label='Redirects to Contact support'  class='spanLink' data-function=${this.toastPopupOptions.openSupportDialog}> Contact Support</a>
        or your HR department for more assistance.`;
        break;
      case "DeadClient":
      case "ClientNotSetupYet":
      case "ClientNotLive":
        this.personalInfoErrorMessage = `You do not have permission to access mySedgwick.`;
        break;
      case "EmailInValid":
        this.personalInfoErrorMessage = `We are unable to complete your registration. Please contact your examiner to verify your information or to provide your email address and phone number.`;
        break;
      /*case "Ineligible":
      this.personalInfoErrorMessage = `Your claim cannot be registered at this time.<br  aria-hidden='true'/> Contact <a tabindex='0' class='spanLink' data-function=${this.toastPopupOptions.openSupportDialog}> Contact Support</a> for further assistance.`;
      break;
    case "BlockRegistration":
      this.personalInfoErrorMessage = `NO registration`;
      break;*/
      case "NoRegistration"://gets hit when no data found with IO claims for Liability lines
        this.personalInfoErrorMessage = `We are unable to proceed with your registration using the information you provided. Please
        <a tabindex='0' role='link' aria-label='Redirects to Contact support' class='spanLink' data-function=${this.toastPopupOptions.openSupportDialog}> Contact Support</a> or your HR department for more information.`;
        /* this.personalInfoErrorMessage = `We are unable to proceed with your registration using the information you provided.<br aria-hidden='true'/>
         Please confirm your entry and try again. For additional assistance, <a tabindex='0' class='spanLink' data-function=${this.toastPopupOptions.openSupportDialog}> Contact Support</a> or your HR department for more assistance.`;*/
        //this.router.navigate(['']);
        //this.router.navigate([''], { queryParams: { clientid: dataForAPI.clientCode } });
        break;
      case "ProvideEmployeeId":
        this.requireEmpId = true;
        this.personalInfoErrorMessage = `Additional information required for registration`;
        //this.setValidators();
        break;
      case "ProvideClaimNumber":
        this.requireClaimNumber = true;
        this.personalInfoErrorMessage = `Additional information required for registration.`;
        break;
      case "OfficeRestricted":
        this.personalInfoErrorMessage = `The claim number you have entered is restricted and we are not able proceed with the registration process using this claim number. For additional details on the restricted claim number you entered, please contact your HR department.`;
        break;

      /*
         case "LVClaimType":
             this.personalInfoErrorMessage = ``;
             break;
         case "PRClaimType":
           this.personalInfoErrorMessage = ``;
           break;
         case "GLClaimType":
           this.personalInfoErrorMessage = ``;
           break;
         case "WCClaimType":
           this.personalInfoErrorMessage = ``;
           break;
         case "MultipleClaims":
           this.personalInfoErrorMessage = ``;
           break;
         case "DSClaimType":
           this.personalInfoErrorMessage = ``;
           break;

         case "AUClaimType":
           this.personalInfoErrorMessage = ``;
           break; */
      case "ClaimClosed":
        this.personalInfoErrorMessage = `Additional information required for registration`;
        break;
      case "PhoneInValid":
      case "NotIndividual":
      case "LOBNotSupportedByClient":
      case "ContactInValid":
        this.personalInfoErrorMessage = `We are unable to complete your registration. Please contact your examiner to verify your information or to provide your email address and phone number.`;
        break;
      case "ClaimNotFound":
      case "MultipleClaims":
        this.personalInfoErrorMessage = `We are unable to proceed with your registration using the information you provided. You may retry or
              <a tabindex='0' role='link' aria-label='Redirects to Contact support'  class='spanLink' data-function=${this.toastPopupOptions.openSupportDialog}> Contact Support</a> or your HR department for more information.`;
        break;
      default:
        this.personalInfoErrorMessage = `Error`;
        break;
    }
  }

  validateZipcode(fc: UntypedFormControl): Validators {
    if (fc.value && fc.value.length > 5)
      return fc.value.match("^[A-Za-z]\\d[A-Za-z][ -]?\\d[A-Za-z]\\d$") ? null : { inValidZopcodeCanada: true };
    else if (fc.value && fc.value.length == 5)
      return fc.value.match("^[0-9]{5}(-[0-9]{4})?$") ? null : { inValidZopcodeUS: true };
  }

  showHideAnswers(showHideAnswers: boolean) {
    this.hidePw = showHideAnswers;
  }

  doBuildRegistrationModel() {

    this.currentRegistrationExport.username = this.registrationUserNameForm.value.userName.trim();
    this.currentRegistrationExport.password = this.registrationUserNameForm.value.passwordGroup.get("newPassword").value.trim();

    this.currentRegistrationExport.securityQuestion = this.regSecQuestions.regSecurityQuesionsForm.value.securityQuestion1;
    this.currentRegistrationExport.securityQuestion2 = this.regSecQuestions.regSecurityQuesionsForm.value.securityQuestion2;
    this.currentRegistrationExport.securityQuestion3 = this.regSecQuestions.regSecurityQuesionsForm.value.securityQuestion3;
    this.currentRegistrationExport.securityQuestion4 = this.regSecQuestions.regSecurityQuesionsForm.value.securityQuestion4;
    this.currentRegistrationExport.securityQuestion5 = this.regSecQuestions.regSecurityQuesionsForm.value.securityQuestion5;
    this.currentRegistrationExport.securityAnswer = this.regSecQuestions.regSecurityQuesionsForm.value.securityAnswer1.toLowerCase().trim();
    this.currentRegistrationExport.securityAnswer2 = this.regSecQuestions.regSecurityQuesionsForm.value.securityAnswer2.toLowerCase().trim();
    this.currentRegistrationExport.securityAnswer3 = this.regSecQuestions.regSecurityQuesionsForm.value.securityAnswer3.toLowerCase().trim();
    this.currentRegistrationExport.securityAnswer4 = this.regSecQuestions.regSecurityQuesionsForm.value.securityAnswer4.toLowerCase().trim();
    this.currentRegistrationExport.securityAnswer5 = this.regSecQuestions.regSecurityQuesionsForm.value.securityAnswer5.toLowerCase().trim();

    // this.currentRegistrationExport.firstName = this.registrationUserNameForm.value.firstName.trim();
    // this.currentRegistrationExport.lastName = this.registrationUserNameForm.value.lastName.trim();

    this.currentRegistrationExport.email = this.registrationUserNameForm.value.personalEmail.trim();
    this.currentRegistrationExport.twoFactorPhone = this.regSecQuestions.regSecurityQuesionsForm.value.mobileNumber.phoneNumber ?? "";
  }

  handleRegistrationFailStatus(status: string): void {
    switch (status) {
      case "FailedToRegister":
        this.personalInfoErrorMessage = `An unknown error occurred please click
        <a tabindex='0' role='link' aria-label='Redirects to Contact support'  class='spanLink' data-function=${this.toastPopupOptions.openSupportDialog}> here</a> to contact support.`;
        break;
      case "UserAlreadyExists":
        this.personalInfoErrorMessage = `This username is already taken please try another one.`
        break;

    }
  }

  //WCAG accessibility
  toggleDatepicker(): void {
    setTimeout(() => {

      //$("div.cdk-visually-hidden").removeAttr("tabindex");
      var captionElement = document.createElement("caption");
      captionElement.style.setProperty("display", "block");
      captionElement.style.setProperty("font-size", "0px");
      captionElement.style.setProperty("height", "0px");
      captionElement.tabIndex = -1;
      //document.getElementsByClassName("cdk-focus-trap-anchor")[0].setAttribute("tabindex","-1");
      // captiionElement.setAttribute("aria-label","Table to display dates");
      captionElement.appendChild(document.createTextNode("Table to display dates"));

      var dateTable = document.getElementsByClassName("mat-calendar-table")[0];
      dateTable.prepend(captionElement);
      dateTable.setAttribute("role", "grid");

      var dateModal = document.getElementsByClassName("mat-calendar")[0];
      dateModal.setAttribute("aria-label", "Datepicker Modal");
      //dateModal.setAttribute("role", "");
      // if (document.getElementsByClassName("mat-button")) {
      //   const firstElDiv: HTMLDivElement = document.getElementsByClassName("mat-button")[0] as HTMLDivElement;
      //   firstElDiv.focus();
      // }


      var datepickerModelDiv = document.getElementsByClassName("mat-datepicker-popup");
      if (datepickerModelDiv != undefined && datepickerModelDiv != null && datepickerModelDiv.length > 0) {
        for (var i = 0; i < datepickerModelDiv.length; i++) {
          if (datepickerModelDiv[i].hasAttribute("aria-label") == false) {
            datepickerModelDiv[i].setAttribute("aria-label", "date picker modal");
            datepickerModelDiv[i].setAttribute("tabindex", "0");
          }
        }

      }


      this.matDatePickerHeaderModification();

    }, 200);

  }

  monthChange(seldate): void {
    setTimeout(() => {
      this.matDatePickerHeaderModification();
    }, 1000);
  }

  matDatePickerDateAriaLabel(): void {

    var datePickerInnerRow = $(".mat-calendar-table tbody>tr>td");

    if (datePickerInnerRow != undefined && datePickerInnerRow != null && datePickerInnerRow.length > 0) {
      for (var tdIndex = 0; tdIndex < datePickerInnerRow.length; tdIndex++) {
        var ariaLableOfTD: string = $(datePickerInnerRow[tdIndex]).attr("aria-label");
        var dayIndexOfTD = $(datePickerInnerRow[tdIndex]).attr("data-mat-col");
        if (ariaLableOfTD != undefined && ariaLableOfTD != null && ariaLableOfTD != '') {
          var existingDayName = this.dayNamesList.some(days => ariaLableOfTD.includes(days));

          if (existingDayName == false) {

            var focusDate = new Date(ariaLableOfTD);
            var newAriaLabel = this.dayNamesList[Number(focusDate.getDay())] + " " + ariaLableOfTD;
            $(datePickerInnerRow[tdIndex]).attr("aria-label", newAriaLabel);
          }
        }
      }
    }

  }

  matDatePickerHeaderModification(): void {

    /*var datePickerHeaderRow = $(".mat-calendar-table .mat-calendar-table-header>tr");

    if(datePickerHeaderRow != undefined && datePickerHeaderRow != null && datePickerHeaderRow.length > 1)
    {
       $(datePickerHeaderRow[1]).remove();
    }*/

    var allTableHeader = $(".mat-calendar-table .mat-calendar-table-header>tr>th");

    if (allTableHeader != undefined && allTableHeader != null && allTableHeader.length > 0) {
      for (var j = 0; j < allTableHeader.length; j++) {
        $(allTableHeader[j]).removeAttr('aria-label');

        $(allTableHeader[j]).text('');
        var spanDisplay = document.createElement("span");
        var spanHidden = document.createElement("span");
        document.getElementsByClassName("cdk-visually-hidden")[0].removeAttribute("tabindex");
        spanDisplay.setAttribute("aria-hidden", "true");
        spanHidden.setAttribute("style", "height: 1px;width: 1px;position: absolute;overflow: hidden; top: -10px;");
        var dayName: string = '';
        var dayNameAlias: string = '';
        switch (j) {
          case 0:
            dayName = "Sunday";
            dayNameAlias = "S";
            break;
          case 1:
            dayName = "Monday";
            dayNameAlias = "M";
            break;
          case 2:
            dayName = "Tuesday";
            dayNameAlias = "T";
            break;
          case 3:
            dayName = "Wednesday";
            dayNameAlias = "W";
            break;
          case 4:
            dayName = "Thursday";
            dayNameAlias = "T";
            break;
          case 5:
            dayName = "Friday";
            dayNameAlias = "F";
            break;
          case 6:
            dayName = "Saturday";
            dayNameAlias = "S";
            break;

        }

        if (dayName.length > 2) {

          spanDisplay.setAttribute("title", dayName);
          //spanDisplay.setAttribute("aria-label",dayName);
          spanDisplay.innerText = dayNameAlias;
          //spanHidden.setAttribute("title",dayName);
          //spanHidden.setAttribute("aria-label",dayName);
          //spanHidden.setAttribute("title",dayName);
          //spanHidden.setAttribute("aria-label",dayName);
          spanHidden.innerText = dayName;
          spanHidden.removeAttribute("aria-label");
          $(allTableHeader[j]).append(spanDisplay);
          $(allTableHeader[j]).append(spanHidden);
        }
      }
    }
    try {
      this.matDatePickerDateAriaLabel();
    } catch (e) {

    }
  }

  changeMatToggleButtonAria(): void {
    const matTogglerChild = document.querySelector(
      'mat-datepicker-toggle'
    )?.firstElementChild;
    if (matTogglerChild) {
      matTogglerChild?.setAttribute("aria-haspopup", "false");
      this.datePickerAriaChanged == true;
    }
  }

  ngAfterViewInit(): void {
    this.interval_login = setInterval(() => {
      if (this.datePickerAriaChanged == false) {
        this.changeMatToggleButtonAria();
      }
    }, 500);
  }

  ngOnDestroy() {
    clearInterval(this.interval_login);
  }

  handleErr = (errMsg) => {
    if (errMsg === 'invalid') {
      this.openSnackBar("Unable to locate claimnumber");
    }
  };

  //Open snace bar
  openSnackBar(message: string, action = 'Close') {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }
}

