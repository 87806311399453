import { Component, OnInit } from '@angular/core';
import { CONST } from './shared/settings';
import { ContentService } from './service/content.service';
import { EncryptService } from './shared/services/encrypt.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private contentService: ContentService,
    private encryptService: EncryptService)
    {

    }
  ngOnInit() {
    // GlobalService.API_URL = environment.backendUrl;
    // GlobalService.API_BASE_URL = environment.backendBaseUrl;
    this.contentService.GetAppVersion().subscribe(data => {
      this.contentService.buildInfo = data.build;

    }, error => {

    });

    this.encryptService.getHashInfo();
  }

  title = CONST.TITLE_APP;
}
