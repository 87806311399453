import { UserContext } from './UserContext';

export class  LoginRequest {
    UserName: string;
    Password : string;
    TwoFactorDeviceId: string;
    SetTFADevice: boolean;
    ClientID: string;
    InternalAuditorContract: number;
    SourceURL: string;
    Resolution: string;

    constructor(values: LoginRequest) {
        Object.assign(this, values);
      }
  }

export interface LoginResponse {
  status: LoginStatus;
  loginSubStatus: LoginStatus;
  errorMessage: string;
  redirectUrl: string;
  emailAddress: string;
  phone: string;
  sessionId: string;
  authenticationDays: number;
  token: string;
  mySedgwickUrl: string;
  username: string;
  userContext: UserContext;
  sourceUrl: string;
}

export enum LoginStatus {
  Invalid = "Invalid",
  Success = "Success",
  PasswordExpired = "PasswordExpired",
  AccountLocked = "AccountLocked",
  TfaRequired = "TfaRequired",
  SecQuestionsRequired = "SecQuestionsRequired",
  Disabled = "Disabled",
  DeadClient = "DeadClient",
  ClientNotSetupYet = "ClientNotSetupYet",
  Standard = "Standard",
  UpdateSecurity = "UpdateSecurity",
  PRGLAULogin = "PRGLAULogin",
  TwoFactorPreferences = "TwoFactorPreferences",
  InvalidContactInfo = "InvalidContactInfo",
  NotActiveClient = "NotActiveClient"
}
