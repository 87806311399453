import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const head = document.getElementsByTagName('head')[0];

if (environment.OnetrustCookieConsentKey == null || environment.OnetrustCookieConsentKey == "") {
  environment.OnetrustCookieConsentKey = "cd77d875-c162-48c6-86ed-8bbae7dda63d";
}

const scriptAutoblock = document.createElement('script');
scriptAutoblock.type = "text/javascript";
scriptAutoblock.src = "../src/oneTrust_production/consent/" + environment.OnetrustCookieConsentKey + "/otSDKStub.js";
scriptAutoblock.setAttribute("data-domain-script", environment.OnetrustCookieConsentKey);
head.appendChild(scriptAutoblock);

const scriptOneTrustCookie = document.createElement('script');
scriptOneTrustCookie.type = "text/javascript";
scriptOneTrustCookie.src = "../src/oneTrust_production/consent/" + environment.OnetrustCookieConsentKey + "/OtAutoBlock.js";
scriptOneTrustCookie.setAttribute("data-domain-script", environment.OnetrustCookieConsentKey);
scriptOneTrustCookie.setAttribute("charset", "UTF-8");
head.appendChild(scriptOneTrustCookie);

const scriptOneTrustFunc = document.createElement('script');
scriptOneTrustFunc.type = "text/javascript";
scriptOneTrustFunc.src = '../src/oneTrust_production/optanon.js';
head.appendChild(scriptOneTrustFunc);

const liveChat = document.createElement('script');
liveChat.type = "text/javascript";
liveChat.src = '../src/liveChat/liveChat.js';
head.appendChild(liveChat);






platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
