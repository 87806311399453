<ng-container [formGroup]="passwordGroup" role="none">
  <ul role="none" class="flex-outer">
    <li role="none">
      <label for="newPassword">{{ newPasswordText }} *</label>
      <mat-form-field [@.disabled]="animationsDisabled">
        <input
          matInput
          required
          autocomplete="new-password"
          id="newPassword"
          [type]="showPassword ? 'text' : 'password'"
          formControlName="newPassword"
          [attr.aria-label]="newPasswordAriaLabel"
          [ngClass]="{
            'is-invalid': showErrorField('newPassword')
          }"
        />
        
        <mat-error *ngIf="showErrorField('newPassword')">
          <mat-error
            class="error-message"
            *ngIf="newPassword.hasError('required')"
          >
          <span style="background:white;padding:5px">
            {{newPasswordAriaLabel}} is required.</span>
          </mat-error>
          <mat-error
            class="error-message"
            *ngIf="
              newPassword.hasError('minlength') ||
              newPassword.hasError('pattern')
            "
          >
          <span style="background:white;padding:5px">
            The password doesn't meet the requriements.</span>
          </mat-error>
          <mat-error
            class="error-message"
            *ngIf="newPassword.hasError('maxlength')"
          >
          <span style="background:white;padding:5px">
            The maximum length for this field is
            {{ newPassword.errors.maxlength.requiredLength }}
            characters.</span>
          </mat-error>
        </mat-error>
      </mat-form-field>
     
        <button
          *ngIf="showEyeIcon"
          (click)="showPasswordIcon()"
          mat-icon-button
          matSuffix
          style="margin-left: -6%;"
          [attr.aria-label]="'Hide Answer'"
          [attr.aria-pressed]="showPassword"
        >
          <mat-icon style="font-size: 14px!important;">{{
            showPassword ? "visibility_off" : "visibility"
          }}</mat-icon>
        </button>
      
    </li>
    <li role="none">
      <label for="confirmPassword">{{ retypePasswordText }} *</label>
      <mat-form-field [@.disabled]="animationsDisabled">
        <input
          type="text"
          matInput
          required
          autocomplete="new-password"
          id="confirmPassword"
          [type]="showPassword ? 'text' : 'password'"
          formControlName="confirmPassword"
          [attr.aria-label]="retypePasswordAriaLabel"
          [ngClass]="{
            'is-invalid': showErrorField('confirmPassword')
          }"
        />
        
        <mat-error *ngIf="showErrorField('confirmPassword')">
          <mat-error
            class="error-message"
            *ngIf="confirmPassword.hasError('required')"
          >
          <span style="background:white;padding:5px">{{retypePasswordAriaLabel}} is required.</span>
          </mat-error>
        </mat-error>
      </mat-form-field>
      <button
          *ngIf="showEyeIcon"
          (click)="showPasswordIcon()"
          mat-icon-button
          matSuffix
          style="margin-left:-6%"
          [attr.aria-label]="'Hide Answer'"
          [attr.aria-pressed]="showPassword"
        >
          <mat-icon  style="font-size: 14px!important;">{{
            showPassword ? "visibility_off" : "visibility"
          }}</mat-icon>
        </button>
    </li>
    <li role="none">
      <span
        class="error-message"
        fxFlex
        fxLayoutAlign="end start"
        *ngIf="passwordGroup.errors?.match"
      >
        The passwords do not match.
      </span>
    </li>
  </ul>
</ng-container>