import { Component, OnInit } from '@angular/core';
import { ContentService } from 'src/app/service/content.service';

@Component({
  selector: 'app-dash-layout',
  templateUrl: './dash-layout.component.html',
  styleUrls: ['./dash-layout.component.scss']
})
export class DashLayoutComponent implements OnInit {
  clientData: any;
  vanitypath: string;
  loginImage: any;
  sessionStorageSet: boolean;

  constructor(private contentService: ContentService) { }

  ngOnInit(): void {
    this.vanitypath = this.vanitypath == null ? '' : this.vanitypath;
    this.clientData = JSON.parse(sessionStorage.getItem('clientData'));

    if (this.clientData == null) {
      this.contentService.GetVanityDetails(this.vanitypath).subscribe(data => {
        sessionStorage.setItem('clientData', JSON.stringify(data));
        this.clientData = JSON.parse(sessionStorage.getItem('clientData'));
        this.loginImage = this.clientData.loginImage;
        this.sessionStorageSet = true;
      }, error => {

      });
    }
    else{
      this.sessionStorageSet = true;
    }
  }

}
