//import { stringify } from '@angular/compiler/src/util';   
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { ChangePasswordRequest, ValidateChangePasswordLinkResponse, ValidateChangePasswordRequest } from 'src/app/shared/models/ChangePasswordRequest';
import { ToastOptions } from 'src/app/shared/models/toast-options.enum';
import { ErrorMessageLogicService } from 'src/app/shared/services/error-message-logic.service';
import { FormValidatorsService } from 'src/app/shared/services/form-validators.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { PasswordComponent } from 'src/app/core/components/password/password.component';
import { RegistrationSecurityquestionsComponent } from '../registration-securityquestions/registration-securityquestions.component';
import { result } from 'lodash';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  @Input() linkId?: string;

  passwordExpiredToast: string =
    'Your password has expired, please create a new one.';
  toastEnumOptions = ToastOptions;
  passwordGroup: UntypedFormGroup;
  changePasswordGroup: UntypedFormGroup;
  username: string;
  submitPressed: boolean;
  passwordLengthError: string =
    'Your password must be between 8 - 16 characters.';
  passwordCharcterError: string = 'Allowed characters are a-zA-Z0-9@#$%^&+=!';
  passwordDifferentFromUsername: string =
    'Your password cannot be the same as your username.';
  passwordRequirementsNotMet: string =
    "The password doesn't meet the requirements.";
  passwordMatch: string = 'Password must match.';
  passwordApiError: string = 'Your Password could not be changed. Please confirm it meets the above requirements and try again.';
  apiError: boolean;
  passwordExpired: boolean;
  encryptedData: any;
  validateLinkResult: ValidateChangePasswordLinkResponse;
  successToast: string = "Your Password has been succesfully changed.<br> You can now login to <a tabindex='0' href='/' class='spanLink'>mySedgwick</a>.";
  showSuccessToast: boolean;
  pageReady: boolean;
  passwordText = 'New Password';
  confirmPasswordText = 'Retype Password';
  token: string;
  showHideSecurityQuestionLabel: string = 'Show Answers';
  showSecurityQuestions: boolean;
  @ViewChild(RegistrationSecurityquestionsComponent) regSecQuestions: RegistrationSecurityquestionsComponent;
  @ViewChild(PasswordComponent) passwordQuestions: PasswordComponent;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private formValidator: FormValidatorsService,
    private errorService: ErrorMessageLogicService,
    private userService: UserService,
    private route: ActivatedRoute,
    public utility: UtilityService
  ) {}

  get showPasswordCheck() {
    return this.changePasswordGroup.get('showPasswordCheck');
  }

  // get newPassword() {
  //   return this.passwordGroup.get('newPassword');
  // }

  // get confirmPassword() {
  //   return this.passwordGroup.get('confirmPassword');
  // }

  ngOnInit(): void {
    this.changePasswordGroup = this.buildPasswordForm();
    this.username = this.userService.username;
    this.passwordExpired = this.userService.passwordExpired;
    this.encryptedData = this.route.snapshot.queryParams.data;
    this.showSecurityQuestions = !this.isSecurityUpdated();

    if(!this.utility.isNullOrEmpty(this.encryptedData)){
      //Accessed via Email Link verification
      let validateLinkRequest = new ValidateChangePasswordRequest({
        encryptedData: this.encryptedData
      });
      this.userService.validateChangePasswordLink(validateLinkRequest).subscribe(
        (result) => {
          this.validateLinkResult = result;
          this.linkValidation(this.validateLinkResult);
          this.username = result.userName;
          this.linkId = result.linkId;
          this.pageReady = true;
          this.token = result.token;
        }
      )
    }
    else{
      //Accessed via Security Answer
      this.token = this.userService.forgotPasswordToken;
      this.pageReady = true;
    }
  }

  buildPasswordForm(): UntypedFormGroup {
    return this.fb.group(
      {
        passwordGroup: [],
        showPasswordCheck: [false],
        //securityQuestions: []
      },
    );
  }

  buildDto(): any {
    // It looks like in the old code oldPassword is set with the oldPassword that gets passed in our params,
    // when we use this component in different scenarios we need to study more about how oldPasswword is set and passed
    // into the API

    return {
      //oldPassword: this.passwordGroup.get('newPassword').value,
      oldPassword: '',
      newPassword: this.changePasswordGroup.value.passwordGroup.get("newPassword").value, //this.changePasswordGroup.get('passwordGroup.confirmPassword').value,
      linkId: this.linkId ?? "",
      username: this.username,
      token: this.token
    };
  }

  buildDtoSecQuestionPassword(): any {
    return {
      oldPassword: '',
      newPassword: this.changePasswordGroup.value.passwordGroup.get("newPassword").value, //this.changePasswordGroup.get('passwordGroup.confirmPassword').value,
      linkId: this.linkId ?? "",
      username: this.username.trim(),
      token: this.token,
      securityQuestion: this.regSecQuestions.regSecurityQuesionsForm.value.securityQuestion1,
      securityQuestion2: this.regSecQuestions.regSecurityQuesionsForm.value.securityQuestion2,
      securityQuestion3: this.regSecQuestions.regSecurityQuesionsForm.value.securityQuestion3,
      securityQuestion4: this.regSecQuestions.regSecurityQuesionsForm.value.securityQuestion4,
      securityQuestion5: this.regSecQuestions.regSecurityQuesionsForm.value.securityQuestion5,
      securityAnswer: this.regSecQuestions.regSecurityQuesionsForm.value.securityAnswer1.toLowerCase().trim(),
      securityAnswer2: this.regSecQuestions.regSecurityQuesionsForm.value.securityAnswer2.toLowerCase().trim(),
      securityAnswer3:this.regSecQuestions.regSecurityQuesionsForm.value.securityAnswer3.toLowerCase().trim(),
      securityAnswer4: this.regSecQuestions.regSecurityQuesionsForm.value.securityAnswer4.toLowerCase().trim(),
      securityAnswer5: this.regSecQuestions.regSecurityQuesionsForm.value.securityAnswer5.toLowerCase().trim(),
      salt: null
    };
  }



  submitPasswordChange(): void {
    this.submitPressed = true;

    let passwordControl = this.passwordQuestions;
    //let confirmPasswordControl = this.passwordGroup.get('confirmPassword');

    if (this.changePasswordGroup.invalid) {
      this.passwordQuestions.passwordGroup.markAllAsTouched();
      // Currently with how the password formGeroup component was designed showing error messages
      // Is not as easy as it should be. Not displaying any error message currently if they
      // click submit with out touching the passwords. Might need to be reexamined
      if (this.showSecurityQuestions && this.regSecQuestions.regSecurityQuesionsForm.invalid)
        this.regSecQuestions.regSecurityQuesionsForm.markAllAsTouched();
      return;
    }

    if(this.showSecurityQuestions) {
      //if(!this.regSecQuestions.regSecurityQuesionsForm.invalid)
      this.changePasswordQuestions();

    }
    else{
      this.changePassword()
    }

  }

  changePassword(): void {
    this.userService.changePassword(this.buildDto() as ChangePasswordRequest).subscribe(
      (result) => {
        if (!result)
          this.apiError = true;
        else {
          this.apiError = false;
          if (this.passwordExpired) {
            this.userService.passwordChanged = true;
            this.router.navigate(['/']);
          }
          else {
            this.showSuccessToast = true;
          }
        }
      }
    )
  }

  changePasswordQuestions(): void {
    this.userService.changePasswordQuestions(this.buildDtoSecQuestionPassword() as ChangePasswordRequest).subscribe(
      (result) => {
        if (!result)
          this.apiError = true;
        else {
          this.apiError = false;
          this.userService.passwordChanged = true;
          this.router.navigate(['/']);
        }
      }
    )
  }

  onCancel(): void {
    this.router.navigate(['/']);
  }

  showErrorField(formControl: string): boolean {
    return this.errorService.showErrorMessage(this.passwordGroup, formControl);
  }

  isSecurityUpdated(): boolean{
    let userContext = this.userService.UserContext;
    if(this.utility.isNullOrEmpty(userContext))
      return true;
    return userContext.securityUpdated;
  }

  /* Keeping this for reference but this code doesnt seem to be used at all
  showPasswordNotMatchError(): void {
    let passwordControl = this.passwordGroup.get('newPassword');
    let confirmPasswordControl = this.passwordGroup.get('confirmPassword');

    if (!passwordControl.value) {
      passwordControl.updateValueAndValidity();
      passwordControl.setErrors({ password: true });
      passwordControl.markAsDirty();
    }
    if (!confirmPasswordControl.value) {
      confirmPasswordControl.updateValueAndValidity();
      confirmPasswordControl.setErrors({ password: true });
      confirmPasswordControl.markAsDirty();
    }
  } */

  // addRequiredValidators(): void {
  //   //this.newPassword.setValidators([Validators.required]);
  //   this.newPassword.updateValueAndValidity();
  //   //this.confirmPassword.setValidators([Validators.required])
  //   this.confirmPassword.updateValueAndValidity();
  // }

  linkValidation(response: ValidateChangePasswordLinkResponse): void {
    if (!response.isValidLink || response.isLinkExpired)
      this.router.navigate(['/forgotPassword']);
  }
}
